import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CONFIGURACION_FICHA_REPROGRAMAR_DOMICILIOS,
  CONFIGURACION_FORMULARIO_REPROGRAMAR_DOMICILIOS,
  CONFIGURACION_TABLA_REPROGRAMAR_DOMICILIOS,
} from './utilidades/reprogramar-domicilios.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import { DomiciliosService } from '../../utilidades/domicilios.service';
import { SourceTablaDomicilios } from '../tabla-domicilios/utilidades/source-tabla-domicilios.class';
import { EstadoDomicilio } from '../../utilidades/domicilios.models';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { Subject } from 'rxjs';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { ReprogramarDomicilioComponent } from '../reprogramar-domicilio/reprogramar-domicilio.component';

@Component({
  templateUrl: './reprogramar-domicilios.component.html',
  styleUrls: ['./reprogramar-domicilios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReprogramarDomiciliosComponent implements OnInit {
  /* Configuración de la ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_REPROGRAMAR_DOMICILIOS);

  /* Formulario */
  protected configFormulario: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_REPROGRAMAR_DOMICILIOS,
  );
  protected valorFormulario: ValoresFormulario | null = null;
  protected validezFormulario: boolean = false;

  /* Tabla de domicilios */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_REPROGRAMAR_DOMICILIOS);
  protected dataSource: SourceTablaDomicilios;
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  /* Domicilios re-programados */
  protected reprogramados: number[] = [];

  constructor(
    private readonly _domiciliosService: DomiciliosService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaDomicilios(_domiciliosService);
  }

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    campoOrg.valorOut = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.nombre,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].nombre);
          campoOrg.readonly = true;
        }
      });
  }

  /**
   * Realiza la búsqueda de domicilios.
   * @autor Juan Corral
   */
  protected buscarDomicilios(): void {
    if (!this.validezFormulario) {
      this._alertasService.alertarError('Formulario inválido');
      return;
    }

    this.tabla.valoresFiltros = {
      organizacion: this.valorFormulario!['organizacion'],
      desde: this.valorFormulario!['fecha'],
      hasta: this.valorFormulario!['fecha'],
      estado: EstadoDomicilio.FALLIDO,
    };

    const orden = this.tabla.sort.active
      ? (this.tabla.sort.direction == 'asc' ? '' : '-') + this.tabla.sort.active
      : '';

    this.dataSource.obtenerDatos(
      orden,
      this.tabla.paginator.pageIndex * this.tabla.paginator.pageSize,
      this.tabla.paginator.pageSize,
      this.tabla.valoresFiltros,
    );
  }

  /**
   * Abre el dialogo de re-programar domicilio.
   * @param {number} id - El id del domicilio a aprobar.
   * @autor Juan Corral
   */
  protected async reprogramarDomicilio$(id: number): Promise<void> {
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: ReprogramarDomicilioComponent,
      inputs: { idDomicilio: id },
      output: 'reprogramado',
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    const reprogramado = await this._dialogosService.abrirVistaPrevia$(config);
    if (reprogramado !== undefined && reprogramado !== null) {
      this.reprogramados.push(id);
      this.buscarDomicilios();
    }
  }
}
