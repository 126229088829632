<mat-nav-list>
  <ng-container *ngFor="let elemento of NAVEGACION">
    <ng-container *roles="elemento.roles">
      <a
        mat-list-item
        *permisos="elemento.permisos; objeto: elemento.objeto"
        [routerLink]="elemento.url"
      >
        <mat-icon matListItemIcon>{{ elemento.icono }}</mat-icon>
        <span matListItemTitle>{{ elemento.nombre }}</span>
      </a>
    </ng-container>
  </ng-container>
</mat-nav-list>
