<compartido-ficha
  [configuracion]="configFicha"
  [cargando]="configuracion === null"
  class="recurso"
  (clickBoton)="$event !== undefined && activarModoEdicion()"
>
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    dynamicHeight
    *ngIf="configuracion !== null"
  >
    <mat-tab
      tabindex="-1"
      *ngFor="let seccion of configuracion.secciones"
      [label]="seccion.nombre"
    >
      <div class="sub-seccion" *ngFor="let subseccion of seccion.subsecciones">
        <h3 *ngIf="subseccion.nombre">{{ subseccion.nombre }}</h3>
        <compartido-formulario
          [configuracion]="subseccion.formulario"
          (valores)="cambioValores(seccion, subseccion, $event)"
        ></compartido-formulario>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="modoEdicion" class="bottom">
    <button mat-button color="warn" class="boton" (click)="cancelarEdicion()">
      Cancelar
    </button>
    <button
      mat-flat-button
      color="primary"
      class="boton"
      (click)="guardarCambios()"
    >
      Guardar Cambios
    </button>
  </div>
</compartido-ficha>
