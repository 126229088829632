import { DomicilioDTO } from 'src/app/domicilios/utilidades/domicilios.models';

/* Estructura de texto Mensaje en el API */
export interface TextoMensajeDTO {
  id: number;
  texto: string;
  nombre: string;
}

/* Estructura de Mensaje SMS en el API */
export interface MensajeSMSDTO {
  id: number;
  domicilio: DomicilioDTO;
  estado: EstadoMensajeSMS;
  estado_display: string;
  numero_origen: string;
  numero_destino: string;
  texto: TextoMensajeDTO;
  texto_final?: string;
  fecha_envio?: string;
  id_externo?: number;
  errores?: string;
}

/* Estados de Mensajes SMS */
export enum EstadoMensajeSMS {
  COLA = 'cola',
  ERROR = 'error',
  ENVIADO = 'enviado',
}
