import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ConfiguracionFicha } from './utilidades/ficha.models';

@Component({
  selector: 'compartido-ficha',
  templateUrl: './ficha.component.html',
  styleUrls: ['./ficha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichaComponent {
  /* Configuración */
  @Input() public configuracion: ConfiguracionFicha | undefined;

  /* Cargando */
  @Input() public cargando: boolean = false;

  /* Output de botón presionado */
  @Output() protected clickBoton: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(public readonly cdr: ChangeDetectorRef) {}
}
