import {
  CampoFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionRecurso } from 'src/app/compartido/recurso/utilidades/recurso.models';
import { OPCIONES_ESTADO_DOMICILIO } from '../../../utilidades/domiclios.static';
import { EstadoDomicilio } from 'src/app/domicilios/utilidades/domicilios.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Icono y colores de los estados de los domicilios */
export const ICONO_ESTADO_DOMICILIOS: Record<
  EstadoDomicilio,
  { icono: string; color: string }
> = {
  [EstadoDomicilio.COLA]: { icono: 'cloud_upload', color: '#A9A9A9' },
  [EstadoDomicilio.ERROR]: { icono: 'warning', color: '#D9534F' },
  [EstadoDomicilio.PENDIENTE]: { icono: 'watch_later', color: '#F0AD4E' },
  [EstadoDomicilio.RUTA]: { icono: 'route', color: '#4169E1' },
  [EstadoDomicilio.DESPACHADO]: { icono: 'send', color: '#5BC0DE' },
  [EstadoDomicilio.FALLIDO]: { icono: 'wrong_location', color: '#E97451' },
  [EstadoDomicilio.COMPLETADO]: { icono: 'check_circle', color: '#5CB85C' },
};

/* Campos de la sección: Información */
const CAMPOS_INFORMACION_DOMICILIO: CampoFormulario[] = [
  {
    nombre: 'N° Referencia',
    slug: 'referencia',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Título',
    slug: 'titulo',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Estado',
    slug: 'estado',
    tipo: TipoCampoFormulario.OPCION_MULTIPLE,
    opciones: OPCIONES_ESTADO_DOMICILIO,
  },
  {
    nombre: 'Fecha de Creación',
    slug: 'fechaCreacion',
    tipo: TipoCampoFormulario.FECHA,
  },
  {
    nombre: 'Fecha Planeada',
    slug: 'fechaPlaneada',
    tipo: TipoCampoFormulario.FECHA,
  },
  {
    nombre: 'Id de Simpli',
    slug: 'idSimpli',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Vehiculo',
    slug: 'vehiculo',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Tracking Id',
    slug: 'trackingId',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Cliente',
    slug: 'cliente',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Notas',
    slug: 'notas',
    tipo: TipoCampoFormulario.TEXTO,
  },
];

/* Campos de la sección: Dirección */
const CAMPOS_DIRECCION_DOMICILIO: CampoFormulario[] = [
  // {
  //   nombre: 'ID',
  //   slug: 'id',
  //   tipo: TipoCampoFormulario.NUMERO,
  // },
  {
    nombre: 'Dirección',
    slug: 'direccion',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Formateada',
    slug: 'direccion_formateada',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Detalles',
    slug: 'detalles',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Barrio',
    slug: 'barrio',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Localidad',
    slug: 'localidad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Ciudad',
    slug: 'ciudad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Latitud',
    slug: 'latitud',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Longitud',
    slug: 'longitud',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Coordenadas',
    slug: 'coordenadas',
    tipo: TipoCampoFormulario.COORDENADAS,
  },
];

/* Configuración del recurso: Domicilio */
export const CONFIGURACION_RECURSO_DOMICILIO: ConfiguracionRecurso = {
  nombre: '', // Se obtiene del domicilio
  icono: Icono.DOMICILIOS,
  objeto: 'domicilio',
  secciones: [
    {
      nombre: 'Información',
      slug: 'informacion',
      editable: false,
      subsecciones: [
        {
          slug: 'informacion',
          formulario: { campos: CAMPOS_INFORMACION_DOMICILIO },
          readonly: ['fechaCreacion', 'vehiculo', 'cliente'],
        },
      ],
    },
    {
      nombre: 'Dirección',
      slug: 'direccion',
      editable: false,
      subsecciones: [
        {
          slug: 'direccion',
          formulario: { campos: CAMPOS_DIRECCION_DOMICILIO },
        },
      ],
    },
  ],
};
