import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';
import {
  ClienteDTO,
  DireccionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import {
  ListaDTO,
  Parametros,
} from 'src/app/compartido/utilidades/compartido.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import {
  DomicilioDTO,
  EstadoDomicilio,
} from 'src/app/domicilios/utilidades/domicilios.models';
import { BienvenidaComponent } from '../../adhoc/componentes/bienvenida/bienvenida.component';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class NotificacionesService {
  /* Notificaciones */
  public notificaciones$: Observable<Notificacion[]> = new BehaviorSubject([]);

  constructor(
    private readonly _http: HttpClient,
    private readonly _autenticacionService: AutenticacionService,
    private readonly _dialogosService: DialogosService,
  ) {}

  /**
   * Actualiza las notificaciones.
   * @author Juan Corral
   */
  public actualizarNotificaciones(): void {
    this._obtenerNotificaciones$().then((notificaciones) => {
      (this.notificaciones$ as BehaviorSubject<Notificacion[]>).next(
        notificaciones,
      );
    });
  }

  /**
   * Obtiene las notificaciones del servidor.
   * @returns {Promise<Notificacion[]>} - Las notificaciones del servidor (promise).
   * @author Juan Corral
   */
  private async _obtenerNotificaciones$(): Promise<Notificacion[]> {
    const notificaciones: Notificacion[] = [];
    const usuario = await this._autenticacionService.usuario$;

    // Domicilios con error
    if (usuario!.rol === RolUsuario.ELEVA) {
      const params: Parametros = { estado: EstadoDomicilio.ERROR };
      const errores = await firstValueFrom(
        this._http
          .get<ListaDTO<DomicilioDTO>>('domicilios/', { params })
          .pipe(map((res: ListaDTO<DomicilioDTO>) => res.count)),
      );
      if (errores > 0) {
        notificaciones.push({
          mensaje: `Error al subir ${errores} domicilios a SimpliRoute`,
          tipo: TipoNotificacion.ERROR,
          icono: Icono.ERROR_SINCRONIZACION,
          pagina: '/domicilios/errores-simpli',
        });
      }
    }

    // Cliente incompleto
    if (
      usuario!.rol === RolUsuario.CLIENTE &&
      this._clienteIncompleto(usuario!.cliente!)
    ) {
      // notificaciones.push({
      //   mensaje: 'Completa tu perfil para poder realizar pedidos',
      //   tipo: TipoNotificacion.ADVERTENCIA,
      //   icono: Icono.PERFIL,
      //   pagina: `/clientes/perfil`,
      // });

      const config: ConfiguracionDialogoVistaPrevia = {
        componente: BienvenidaComponent,
        output: 'completado',
      };
      this._dialogosService.abrirVistaPrevia$(config, { disableClose: true });
    }

    return notificaciones;
  }

  /**
   * Devuelve si el cliente está incompleto.
   * @param {ClienteDTO} cliente - El cliente a verificar.
   * @returns {boolean} - Si el cliente está incompleto o no.
   * @author Juan Corral
   */
  private _clienteIncompleto(cliente: ClienteDTO): boolean {
    const direccionIncompleta = (direccion: DireccionDTO) => {
      return (
        !direccion.detalles ||
        !direccion.barrio ||
        !direccion.localidad ||
        !direccion.ciudad
      );
    };
    const direcciones = cliente.direcciones.filter(
      (direccion) => direccion.creacion_manual,
    );
    return (
      !cliente ||
      !cliente.telefono ||
      !cliente.direcciones ||
      direcciones.length === 0 ||
      direcciones.some((direccion) => direccionIncompleta(direccion))
    );
  }
}

/* Interfaz para las notificaciones */
export interface Notificacion {
  mensaje: string;
  tipo: TipoNotificacion;
  icono: Icono;
  pagina?: string;
}

/* Tipos de notificación */
export enum TipoNotificacion {
  ERROR = 'error',
  ADVERTENCIA = 'advertencia',
  INFO = 'info',
}
