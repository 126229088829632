import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { DomicilioDTO } from 'src/app/domicilios/utilidades/domicilios.models';
import {
  TextoMensajeDTO,
  MensajeSMSDTO,
  EstadoMensajeSMS,
} from './comunicaciones.models';

/* Mensaje SMS */
export class MensajeSMS {
  constructor(
    public id: number,
    public domicilio: DomicilioDTO,
    public estado: EstadoMensajeSMS,
    public estadoDisplay: string,
    public numeroOrigen: string,
    public numeroDestino: string,
    public texto: TextoMensajeDTO,
    public textoFinal?: string,
    public fechaEnvio?: string,
    public idExterno?: number,
    public errores?: string,
  ) {}

  /**
   * Devuelve los datos del mensaje sms con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      organizacion: this.domicilio.cliente.organizacion.nombre,
      domicilio: this.domicilio.titulo,
      estado: this.estadoDisplay,
      cliente: this.domicilio.cliente.nombre,
      numero_destino: this.numeroDestino,
      texto: this.textoFinal,
      fecha_envio: this.fechaEnvio,
      id_externo: this.idExterno,
    };
  }

  /**
   * Crea un objeto Mensaje SMS a partir de los datos del API
   * @param {MensajeSMSDTO} sms - El mensaje sms como llega del API
   * @returns {MensajeSMS} - El objeto Mensaje SMS creado
   * @author Juan Corral
   */
  public static fromDTO(sms: MensajeSMSDTO): MensajeSMS {
    return new MensajeSMS(
      sms.id,
      sms.domicilio,
      sms.estado,
      sms.estado_display,
      sms.numero_origen,
      sms.numero_destino,
      sms.texto,
      sms.texto_final,
      sms.fecha_envio,
      sms.id_externo,
      sms.errores,
    );
  }
}
