import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { UsuarioDTO } from '../../nucleo/autenticacion/autenticacion.models';
import { ClienteDTO } from 'src/app/clientes/utilidades/clientes.models';
import { FormateableTabla } from 'src/app/compartido/tabla-asincrona/utilidades/tabla-asincrona.models';
import {
  EstadoVerificacionIdentidad,
  VerificacionIdentidadDTO,
} from './ingreso.models';

/* Verificación de identidad */
export class VerificacionIdentidad implements FormateableTabla {
  constructor(
    public id: number,
    public usuario: UsuarioDTO,
    public cliente: ClienteDTO,
    public estado: EstadoVerificacionIdentidad,
    public estadDisplay: string,
    public archivo: string | null | undefined,
  ) {}

  /**
   * Devuelve los datos del cliente con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      usuario: this.usuario.email,
      cliente: this.cliente.nombre + ' - ' + this.cliente.cedula,
      estado: this.estadDisplay,
      archivo: this.archivo ? 'Sí' : 'No',
    };
  }

  /**
   * Crea un objeto VerificacionIdentidad a partir de los datos del API
   * @param {VerificacionIdentidadDTO} verificacion - La verificación de identidad como llego desde el API
   * @returns {Cliente} - El objeto VerificacionIdentidad creado
   * @author Juan Corral
   */
  public static fromDTO(
    verificacion: VerificacionIdentidadDTO,
  ): VerificacionIdentidad {
    return new VerificacionIdentidad(
      verificacion.id,
      verificacion.usuario,
      verificacion.cliente,
      verificacion.estado,
      verificacion.estado_display,
      verificacion.archivo,
    );
  }
}
