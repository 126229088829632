import { CampoFormulario } from '../../formulario/utilidades/formulario.models';
import { Boton } from '../../utilidades/compartido.models';

/* Información de una fila de la tabla */
export type FilaTabla = Record<string, any>;

/* Información de la tabla */
export type DatosTabla = FilaTabla[];

/* Configuración de la tabla */
export interface ConfiguracionTabla {
  columnas: ColumnaTabla[];
  botones?: BotonTabla[];
  busqueda?: boolean;
  acciones?: AccionTabla[];
  filtros?: FiltroTabla[];
}

/* Columna de la tabla */
export interface ColumnaTabla {
  slug: string;
  nombre: string;
  ordenable?: boolean;
  tipo?: TipoColumnaTabla;
}

/* Tipo de columna */
export enum TipoColumnaTabla {
  TEXTO = 'texto',
  NUMERO = 'numero',
  FECHA = 'fecha',
  BOOLEANO = 'booleano',
  MONEDA = 'moneda',
}

/* Botones de la tabla */
export type BotonTabla = Boton;

/* Acciones para las filas de la tabla */
export type AccionTabla = Boton & {
  ocultar?: (fila: FilaTabla) => boolean;
};

/* Filtros de la tabla */
export type FiltroTabla = CampoFormulario;

/* Configuración de tabla asincrona */
export type ConfiguracionTablaAsincrona = ConfiguracionTabla & {
  filtros?: FiltroTablaAsincrona[];
  seleccion?: string; // slug de la columna que se usará para la selección
};

/* Configuración de tabla sincrona */
export type ConfiguracionTablaSincrona = ConfiguracionTabla & {
  filtros?: FiltroTablaSincrona[];
  seleccion?: boolean;
};

/* Configuración de filtros de tabla asincrona */
export type FiltroTablaAsincrona = FiltroTabla;

/* Configuración de filtros de tabla sincrona */
export type FiltroTablaSincrona = FiltroTablaAsincrona & {
  comparacion: ComparacionFiltro;
  parametro: string;
};

/* Comparación de filtros */
export enum ComparacionFiltro {
  EXACTA = 'exacta',
  PARCIAL = 'parcial',
  MAYOR = 'mayor',
  MENOR = 'menor',
}
