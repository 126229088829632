import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaAsincrona,
  TipoColumnaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { OPCIONES_ESTADO_DOMICILIO } from '../../../utilidades/domiclios.static';
import {
  Permiso,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import { VerDomicilioComponent } from '../../ver-domicilio/ver-domicilio.component';
import { MAX_WIDTH_FICHA_RECURSO } from 'src/app/compartido/recurso/utilidades/recurso.static';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Configuración de la tabla de domicilios */
export const CONFIGURACION_TABLA_DOMICILIOS: ConfiguracionTablaAsincrona = {
  columnas: [
    { slug: 'id', nombre: 'ID', ordenable: true },
    // { slug: 'referencia', nombre: 'N° Referencia' },
    { slug: 'titulo', nombre: 'Título' },
    { slug: 'estado', nombre: 'Estado' },
    // { slug: 'fecha_creacion', nombre: 'Fecha Creación' },
    {
      slug: 'fecha_planeada',
      nombre: 'Fecha Planeada',
      ordenable: true,
      tipo: TipoColumnaTabla.FECHA,
    },
    { slug: 'id_simpli', nombre: 'Id Simpli' },
    // { slug: 'tracking_id', nombre: 'Tracking Id' },
    { slug: 'vehiculo', nombre: 'Vehículo' },
    // { slug: 'direccion', nombre: 'Dirección' },
    // { slug: 'cliente', nombre: 'Cliente' },
    // { slug: 'cedula', nombre: 'Cédula' },
  ],
  busqueda: true,
  filtros: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // opciones se define dinámicamente en el componente
      roles: [RolUsuario.ELEVA],
    },
    {
      nombre: 'Estado',
      slug: 'estado',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [{ nombre: 'Todos', valor: '' }, ...OPCIONES_ESTADO_DOMICILIO],
    },
    {
      nombre: 'Vehículo',
      slug: 'vehiculo',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // opciones se define dinámicamente en el componente
      roles: [RolUsuario.ELEVA],
    },
    {
      nombre: 'Desde',
      slug: 'desde',
      tipo: TipoCampoFormulario.FECHA,
    },
    {
      nombre: 'Hasta',
      slug: 'hasta',
      tipo: TipoCampoFormulario.FECHA,
    },
  ],
  botones: [
    {
      nombre: 'Descargar',
      slug: 'descargar',
      icono: Icono.DESCARGAR,
      autorizacion: { objeto: 'domicilio', permisos: [Permiso.VER_MASIVO] },
    },
    {
      nombre: 'Generar Etiquetas',
      slug: 'etiquetas',
      icono: Icono.ETIQUETAS,
      autorizacion: { objeto: 'domicilio', permisos: [Permiso.VER_MASIVO] },
    },
  ],
  acciones: [
    {
      nombre: 'Ver',
      slug: 'ver',
      icono: Icono.VER,
      autorizacion: { objeto: 'domicilio', permisos: [Permiso.VER] },
    },
    {
      nombre: 'Generar Etiqueta',
      slug: 'etiqueta',
      icono: Icono.ETIQUETAS,
      autorizacion: { objeto: 'domicilio', permisos: [Permiso.VER] },
    },
  ],
};

/* Detalles de la acciones de la tabla de domicilios */
export const DETALLES_ACCIONES_TABLA_DOMICILIOS: Record<
  string,
  {
    componente: any;
    output?: string;
    segmentosUrl: string[];
    width?: number;
  }
> = {
  ver: {
    componente: VerDomicilioComponent,
    segmentosUrl: ['domicilios'],
    width: MAX_WIDTH_FICHA_RECURSO,
  },
};
