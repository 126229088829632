import { Modificar } from 'src/app/compartido/utilidades/compartido.models';
import {
  ClienteBase,
  ClienteDTO,
  DireccionBase,
  DireccionDTO,
} from '../../clientes/utilidades/clientes.models';

/* Estructura básica de Domicilio */
export interface DomicilioBase {
  referencia: string;
  fecha_planeada: string;
  direccion: DireccionBase;
  cliente: ClienteBase;
  notas?: string;
}

/* Estructura de Domicilio en el API */
export interface DomicilioDTO
  extends Modificar<
    DomicilioBase,
    {
      direccion: DireccionDTO;
      cliente: ClienteDTO;
    }
  > {
  id: number;
  titulo: string;
  estado: EstadoDomicilio;
  estado_display: string;
  fecha_creacion: string;
  ruta?: RutaDTO;
  id_simpli?: number;
  tracking_id?: string;
  errores?: string;
  orden?: number;
}

/* Estados de Domicilio */
export enum EstadoDomicilio {
  COLA = 'cola',
  ERROR = 'error',
  PENDIENTE = 'pendiente',
  RUTA = 'ruta',
  DESPACHADO = 'despachado',
  FALLIDO = 'fallido',
  COMPLETADO = 'completado',
}

/* Estructura de Ruta en el API */
export interface RutaDTO {
  id: number;
  id_simpli: number;
  plan: number;
  vehiculo: VehiculoDTO;
}

/* Estructura de Vehículo en el API */
export interface VehiculoDTO {
  id: number;
  id_simpli: number;
  nombre: string;
}
