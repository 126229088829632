import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AutorizacionService } from './autorizacion.service';
import { Permiso, RolUsuario } from './autorizacion.models';

@Directive({ selector: '[permisos]' })
export class PermisosDirective implements OnInit {
  @Input({ required: true }) permisos!: Permiso[] | null;
  @Input({ required: true }) permisosObjeto!: string | null;

  constructor(
    private autorizacionService: AutorizacionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    if (this.permisosObjeto === null || this.permisos === null) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.autorizacionService
      .tienePermisos$(this.permisosObjeto, this.permisos)
      .then((tienePermisos) => {
        this.viewContainer.clear();
        if (tienePermisos)
          this.viewContainer.createEmbeddedView(this.templateRef);
      });
  }
}

@Directive({ selector: '[roles]' })
export class RolesDirective implements OnInit {
  @Input({ required: true }) roles: RolUsuario[] | undefined;

  constructor(
    private autorizacionService: AutorizacionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.viewContainer.clear();
    if (!this.roles) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.autorizacionService.tieneRol$(this.roles).then((tieneRol) => {
      if (tieneRol) this.viewContainer.createEmbeddedView(this.templateRef);
    });
  }
}
