import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import {
  EstadoOrden,
  FormulaDTO,
  ItemOrdenDTO,
} from '../../utilidades/ordenes.models';
import { OrdenesService } from '../../utilidades/ordenes.service';
import {
  CAMPOS_ITEMS_ORDEN,
  CONFIGURACION_RECURSO_ORDEN,
  SECCION_APROBACION_ORDEN,
  SECCION_ITEMS_ORDEN,
  SECCION_FORMULAS_ORDEN,
  CAMPOS_FORMULAS_ORDEN,
} from './utilidades/ver-orden.static';
import {
  ConfiguracionRecurso,
  SubseccionRecurso,
} from 'src/app/compartido/recurso/utilidades/recurso.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Orden } from '../../utilidades/orden.class';
import { obtenerSeccion } from 'src/app/compartido/recurso/utilidades/recurso.utils';
import { ESTADOS_ORDEN_APROBADA } from '../../utilidades/ordenes.static';

@Component({
  selector: 'ordenes-ver-orden',
  templateUrl: './ver-orden.component.html',
  styleUrls: ['./ver-orden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerOrdenComponent implements OnInit {
  /* ID de la orden */
  @Input() public idOrden: number | undefined;

  /* Orden */
  private _orden: Orden | undefined;

  /* Configuración del recurso */
  protected configuracion: ConfiguracionRecurso | undefined;

  constructor(
    private readonly _ordenesService: OrdenesService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Crear la configuración del recurso
    this._crearConfiguracion$();
  }

  /**
   * Crea la configuración del recurso para mostrar en la vista
   * @author Juan Corral
   */
  private async _crearConfiguracion$(): Promise<void> {
    // Obtener la orden
    const id: number | undefined = await firstValueFrom<number>(
      this._route.params.pipe(map((params: Params) => params['id'])),
    );
    const idOrden = this.idOrden ?? id;
    this._orden = await this._ordenesService.obtenerOrden$(idOrden);
    if (this._orden === undefined)
      throw new ErrorFrontend('No se encontró la orden');

    // Crear la configuración
    const configuracion = deepCopy(CONFIGURACION_RECURSO_ORDEN);

    // Sección: Información
    const ordenPlano = this._orden as Record<string, any>;
    configuracion.nombre = this._orden.titulo;
    const seccionInformacion = obtenerSeccion(configuracion, 'informacion');
    for (const subseccion of seccionInformacion!.subsecciones) {
      for (const campo of subseccion.formulario.campos) {
        campo.valor = ordenPlano[campo.slug];
        if (campo.slug === 'cliente' && !!this._orden.cliente) {
          campo.valor = this._orden.cliente.nombre;
        } else if (campo.slug === 'domicilio') {
          campo.valor = this._orden.domicilio?.titulo;
        }
      }
    }

    // Sección: Dirección
    const seccionDireccion = obtenerSeccion(configuracion, 'direccion');
    for (const subseccion of seccionDireccion!.subsecciones) {
      for (const campo of subseccion.formulario.campos) {
        if (campo.slug !== 'coordenadas') {
          campo.valor = ordenPlano['direccion'][campo.slug];
        } else {
          const latitud = parseFloat(this._orden.direccion.latitud ?? '');
          const longitud = parseFloat(this._orden.direccion.longitud ?? '');
          if (!isNaN(latitud) && !isNaN(longitud))
            campo.valor = { latitud, longitud };
        }
      }
    }

    // Sección: Items
    if (this._orden.items && this._orden.items.length > 0) {
      const seccionItems = deepCopy(SECCION_ITEMS_ORDEN);
      seccionItems.subsecciones = this._orden.items.map(
        (item: ItemOrdenDTO, i: number) => {
          const itemPlano = item as Record<string, any>;
          const subseccion: SubseccionRecurso = {
            nombre: `Item #${i + 1}`,
            slug: item.id.toString(),
            formulario: {
              campos: deepCopy(CAMPOS_ITEMS_ORDEN).map((campo) => {
                campo.valor = itemPlano[campo.slug];
                if (campo.slug === 'producto')
                  campo.valor = item.producto.nombre;
                return campo;
              }),
            },
          };
          return subseccion;
        },
      );
      configuracion.secciones.push(seccionItems);
    }

    // Sección: Fórmulas
    if (this._orden.formulas && this._orden.formulas.length > 0) {
      const seccionFormulas = deepCopy(SECCION_FORMULAS_ORDEN);
      seccionFormulas.subsecciones = this._orden.formulas.map(
        (formula: FormulaDTO, i: number) => {
          const subseccion: SubseccionRecurso = {
            nombre: `Fórmula #${i + 1}`,
            slug: formula.id.toString(),
            formulario: {
              campos: deepCopy(CAMPOS_FORMULAS_ORDEN).map((campo) => {
                campo.valor = (formula as Record<string, any>)[campo.slug];
                return campo;
              }),
            },
          };
          return subseccion;
        },
      );
      configuracion.secciones.push(seccionFormulas);
    }

    // Sección: Aprobación
    if (
      (ESTADOS_ORDEN_APROBADA.includes(this._orden.estado) ||
        this._orden.estado === EstadoOrden.RECHAZADA) &&
      !!this._orden.fechaAprobacion
    ) {
      const seccionAprobacion = deepCopy(SECCION_APROBACION_ORDEN);
      if (this._orden.estado === EstadoOrden.RECHAZADA)
        seccionAprobacion.nombre = 'Rechazo';
      for (const subseccion of seccionAprobacion!.subsecciones) {
        for (const campo of subseccion.formulario.campos) {
          campo.valor = ordenPlano[campo.slug];
        }
      }
      configuracion.secciones.push(seccionAprobacion);
    }

    // Guardar la configuración
    this.configuracion = configuracion;
    this._cdr.markForCheck();
  }
}
