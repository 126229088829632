import { CampoFormulario, ConfiguracionFormulario } from './formulario.models';

/**
 * Obtiene un campo de un formulario a partir de su slug.
 * @param {ConfiguracionFormulario | CampoFormulario[]} fuente - Fuente de los campos.
 * @param {string} slugCampo - Slug del campo a obtener.
 * @returns {CampoFormulario | undefined} El campo del formulario o undefined si no se encuentra.
 * @author Juan Corral
 */
export function obtenerCampo(
  fuente: ConfiguracionFormulario | CampoFormulario[],
  slugCampo: string,
): CampoFormulario | undefined {
  if (Array.isArray(fuente))
    return fuente.find((campo) => campo.slug === slugCampo);
  return fuente.campos.find((campo) => campo.slug === slugCampo);
}
