import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { ClientesService } from '../../utilidades/clientes.service';
import {
  CONFIGURACION_FICHA_TABLA_CLIENTES,
  CONFIGURACION_TABLA_CLIENTES,
  DETALLES_ACCIONES_TABLA_CLIENTES,
} from './utilidades/tabla-clientes.static';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { SourceTablaClientes } from './utilidades/source-tabla-clientes.class';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerFiltro } from 'src/app/compartido/tabla/utilidades/tabla.utils.models';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';

@Component({
  selector: 'clientes-tabla-clientes',
  templateUrl: './tabla-clientes.component.html',
  styleUrls: ['./tabla-clientes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaClientesComponent implements OnInit {
  constructor(
    private readonly _clientesService: ClientesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _dialogosService: DialogosService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaClientes(this._clientesService);
  }

  /* Configuración de la ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_TABLA_CLIENTES);

  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_CLIENTES);

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaClientes;

  /* Referencia a la tabla */
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerFiltro(this.configTabla, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = [
          { nombre: 'Todos', valor: '' },
          ...organizaciones.map((org) => ({
            nombre: org.nombre,
            valor: org.nombre,
          })),
        ];
        this._cdr.markForCheck();
      });
  }

  /**
   * Ejecuta una acción en la fila.
   * @param {FilaTabla} fila - La fila seleccionada.
   * @param {string} accion - La acción a ejecutar.
   * @autor Juan Corral
   */
  protected clickAccion(fila: FilaTabla, accion: string): void {
    const detalles = DETALLES_ACCIONES_TABLA_CLIENTES[accion];
    if (detalles === undefined)
      throw new ErrorDesarrollo('Acción no soportada');
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: detalles.componente,
      inputs: { cedula: parseInt(fila['cedula']) },
      output: detalles.output,
      url: this._router.serializeUrl(
        this._router.createUrlTree([...detalles.segmentosUrl, fila['cedula']]),
      ),
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    this._dialogosService
      .abrirVistaPrevia$(config, {
        maxWidth: '90vw',
        width: detalles.width + 'px',
      })
      .then(() => this.tabla.cargarPagina());
  }
}
