import {
  Permiso,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionTablaAsincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { VerClienteComponent } from '../../ver-cliente/ver-cliente.component';
import { MAX_WIDTH_FICHA_RECURSO } from 'src/app/compartido/recurso/utilidades/recurso.static';

/* Configuración de la ficha de la tabla de clientes */
export const CONFIGURACION_FICHA_TABLA_CLIENTES: ConfiguracionFicha = {
  maxWidth: 700,
};

/* Configuración de la tabla de clientes */
export const CONFIGURACION_TABLA_CLIENTES: ConfiguracionTablaAsincrona = {
  columnas: [
    { slug: 'cedula', nombre: 'Cédula', ordenable: true },
    { slug: 'organizacion', nombre: 'Organización' },
    { slug: 'nombre', nombre: 'Nombre', ordenable: true },
    { slug: 'telefono', nombre: 'Teléfono' },
  ],
  busqueda: true,
  filtros: [
    {
      nombre: 'Cédula',
      slug: 'cedula',
      tipo: TipoCampoFormulario.NUMERO,
      minimo: 0,
    },
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // opciones se define dinámicamente en el componente
      roles: [RolUsuario.ELEVA],
    },
  ],
  acciones: [
    {
      nombre: 'Ver',
      slug: 'ver',
      icono: Icono.VER,
      autorizacion: { objeto: 'cliente', permisos: [Permiso.VER] },
    },
  ],
};

/* Detalles de la acciones de la tabla de clientes */
export const DETALLES_ACCIONES_TABLA_CLIENTES: Record<
  string,
  {
    componente: any;
    output?: string;
    segmentosUrl: string[];
    width?: number;
  }
> = {
  ver: {
    componente: VerClienteComponent,
    segmentosUrl: ['clientes'],
    width: MAX_WIDTH_FICHA_RECURSO,
  },
};
