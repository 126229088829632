import { ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';

export interface ConfiguracionFormulario {
  campos: CampoFormulario[];
  estilo?: EstiloFormulario;
}

export interface EstiloFormulario {
  orientacion?: OrientacionFormulario;
  display?: DisplayFormulario;
  posicionLabel?: PosicionLabelFormulario;
  widthLabel?: number;
  widthCampo?: number;
  esconderErrores?: boolean;
}

export enum OrientacionFormulario {
  HORIZONTAL = 'horizontal', // Por defecto
  VERTICAL = 'vertical',
}

export enum DisplayFormulario {
  FLEX = 'flex', // Por defecto
  GRID = 'grid',
}

export enum PosicionLabelFormulario {
  DENTRO = 'dentro', // Por defecto
  TOP = 'top',
  IZQUIERDA = 'izquierda',
}

interface BaseCampoFormulario<T> {
  nombre: string;
  tipo: TipoCampoFormulario;
  valor?: T;
  slug: string;
  requerido?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  validadores?: ValidadorFormulario[];
  oculto?: boolean;
  roles?: RolUsuario[];
  valorOut?: Subject<any>; // Para leer el valor
  valorIn?: Subject<any>; // Para cambiar el valor
}

export type CampoFormulario =
  | CampoTexto
  | CampoNumero
  | CampoFecha
  | CampoArchivo
  | CampoOpcionMultiple<string | number>
  | CampoAutocomplete<string | number>
  | CampoCheckbox
  | CampoCoordenadas;

export interface CampoTexto extends BaseCampoFormulario<string> {
  tipo: TipoCampoFormulario.TEXTO;
  contrasena?: boolean;
  correo?: boolean;
}

export interface CampoNumero extends BaseCampoFormulario<number> {
  tipo: TipoCampoFormulario.NUMERO;
  minimo?: number;
  maximo?: number;
  prefijo?: string;
  sufijo?: string;
}

export interface CampoFecha extends BaseCampoFormulario<string> {
  tipo: TipoCampoFormulario.FECHA;
  minimo?: string;
  maximo?: string;
}

export interface CampoArchivo extends BaseCampoFormulario<Blob> {
  tipo: TipoCampoFormulario.ARCHIVO;
  aceptados: ('.csv' | '.xlsx' | '.txt' | '.pdf' | '.png' | '.jpg')[];
  multiples?: boolean;
}

export interface CampoOpcionMultiple<T> extends BaseCampoFormulario<T> {
  tipo: TipoCampoFormulario.OPCION_MULTIPLE;
  opciones: OpcionCampo<T>[];
}

export interface CampoAutocomplete<T> extends BaseCampoFormulario<T> {
  tipo: TipoCampoFormulario.AUTOCOMPLETE;
  opciones: OpcionCampo<T>[];
  opcionesIn?: Subject<OpcionCampo<T>[]>; // Para cambiar las opciones
  opcionOut?: Subject<OpcionCampo<T>>; // Cuando se selecciona una opción
}

export interface CampoCheckbox extends BaseCampoFormulario<boolean> {
  tipo: TipoCampoFormulario.CHECKBOX;
}

export interface CampoCoordenadas
  extends BaseCampoFormulario<{ latitud: number; longitud: number }> {
  tipo: TipoCampoFormulario.COORDENADAS;
}

export interface OpcionCampo<T> {
  nombre: string;
  valor: T;
  extra?: any;
}

export enum TipoCampoFormulario {
  TEXTO = 'texto',
  NUMERO = 'numero',
  FECHA = 'fecha',
  ARCHIVO = 'archivo',
  OPCION_MULTIPLE = 'opción-multiple',
  AUTOCOMPLETE = 'autocomplete',
  CHECKBOX = 'checkbox',
  COORDENADAS = 'coordenadas',
}

/* Validador de un campo del formulario */
export type ValidadorFormulario = {
  mensajeError: string;
  validador: ValidatorFn;
};

/* Valores formulario */
export interface ValoresFormulario {
  [slug: string]: any;
}
