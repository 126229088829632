<div class="contenedor">
  <compartido-ficha
    class="formulario-paquete"
    [configuracion]="configFichaFormulario"
  >
    <compartido-formulario
      [configuracion]="configFormulario"
      (valores)="$event !== undefined && calcularPrecio($event)"
      (valido)="formularioValido = $event"
    ></compartido-formulario>
    <div class="resultado mat-headline-6">
      <span>Precio: </span>
      <span *ngIf="!!paquete?.precio">
        {{ paquete?.precio | currency: "COP" : "symbol-narrow" }}
      </span>
      <span *ngIf="!paquete?.precio">--</span>
    </div>
    <div class="footer">
      <button
        mat-flat-button
        color="primary"
        (click)="agregarPaquete()"
        [disabled]="!formularioValido"
      >
        Agregar Paquete <mat-icon>add</mat-icon>
      </button>
    </div>
  </compartido-ficha>

  <compartido-ficha
    class="tabla-paquetes {{ responsiveService.claseCSS$ | async }}"
    [configuracion]="configFichaTabla"
  >
    <compartido-tabla
      [configuracion]="configTabla"
      [datos]="filas"
      (clickAccion)="eliminarPaquete($event.fila)"
    ></compartido-tabla>
    <button
      mat-flat-button
      color="primary"
      [disabled]="filas.length === 0"
      (click)="subirPaquetes()"
    >
      Subir <mat-icon>done</mat-icon>
    </button>
  </compartido-ficha>
</div>
