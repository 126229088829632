import { capitalizar } from 'src/app/compartido/utilidades/valores.utils';
import { findBestMatch } from 'string-similarity';

/* Componente dentro de la dirección */
type ComponenteDireccion = { valor: string; elementos: number };

export class FormateadorDireccion {
  /* Dirección formateada */
  public direccionFormateada = '';

  /* Rating mínimo para string similarity */
  public ratingMinimo = 0.3;

  constructor(
    private direccionCruda: string,
    public detalles: string = '',
  ) {}

  /**
   * Formatea la dirección y la guarda en la variable direccionFormateada.
   * @returns {boolean} - Si la dirección fue formateada con éxito.
   * @author Juan Corral
   */
  public formatearDireccion(): boolean {
    // Volver minúsculas
    let direccionCruda = this.direccionCruda.toLowerCase();
    // Eliminar los símbolos
    direccionCruda = direccionCruda.replace(
      /[^a-zA-Z0-9\sáéíóúÁÉÍÓÚüÜñÑ]/g,
      ' ',
    );
    // Cambiar non-breaking spaces por espacios
    direccionCruda = direccionCruda.replace(/\u00A0/g, ' ');
    // Separar números de letras
    direccionCruda = direccionCruda.replace(/([0-9]+)/g, ' $1 ');
    // Separar palabras clave
    direccionCruda = this._separarPalabrasClave(direccionCruda);
    // Eliminar alternativas de #
    direccionCruda = direccionCruda.replace(' n ', ' ');
    direccionCruda = direccionCruda.replace(' no ', ' ');
    direccionCruda = direccionCruda.replace(' num ', '');
    direccionCruda = direccionCruda.replace('número', '');
    direccionCruda = direccionCruda.replace('numero', '');
    direccionCruda = direccionCruda.replace(' nro ', ' ');
    // Reemplazar alternativas de 'avenida'
    direccionCruda = direccionCruda.replace('av ', 'avenida ');
    direccionCruda = direccionCruda.replace('ave ', 'avenida ');
    // Reemplazar alternativas de 'avenida carrera'
    direccionCruda = direccionCruda.replace('ak ', 'avenida carrera ');
    // Reemplazar alternativas de 'avenida carrera'
    direccionCruda = direccionCruda.replace('ac ', 'avenida calle ');
    // Reemplazar alternativas de 'carrera'
    direccionCruda = direccionCruda.replace('cra ', 'carrera ');
    direccionCruda = direccionCruda.replace('cr ', 'carrera ');
    direccionCruda = direccionCruda.replace('crr ', 'carrera ');
    direccionCruda = direccionCruda.replace('kr ', 'carrera ');
    direccionCruda = direccionCruda.replace('krr ', 'carrera ');
    direccionCruda = direccionCruda.replace('ka ', 'carrera ');
    direccionCruda = direccionCruda.replace('kra ', 'carrera ');
    // Reemplazar alternativas de 'calle'
    direccionCruda = direccionCruda.replace('cll ', 'calle ');
    direccionCruda = direccionCruda.replace('cl ', 'calle ');
    // Reemplazar alternativas de 'transversal'
    direccionCruda = direccionCruda.replace('tv ', 'transversal ');
    direccionCruda = direccionCruda.replace('tr ', 'transversal ');
    direccionCruda = direccionCruda.replace('trv ', 'transversal ');
    // Reemplazar alternativas de 'diagonal'
    direccionCruda = direccionCruda.replace('dg ', 'diagonal ');
    direccionCruda = direccionCruda.replace('dig ', 'diagonal ');
    // Reemplazar alternativas de 'apartamento'
    direccionCruda = direccionCruda.replace(' apt ', ' apartamento ');
    direccionCruda = direccionCruda.replace(' apto ', ' apartamento ');
    // Limpiar espacios
    direccionCruda = direccionCruda.replace(/\s+/g, ' ').trim();
    // Remover palabras duplicadas consecutivas
    direccionCruda = direccionCruda
      .split(' ')
      .filter(
        (w, i) => w !== direccionCruda.split(' ')[i + 1] || regexNumero.test(w),
      )
      .join(' ');
    // Separar elementos
    const separada = direccionCruda.split(' ');

    let seccion = 0;
    let formateada = '';

    for (let i = 0; i < separada.length; i++) {
      const elemento = separada[i];

      if (seccion == 0) {
        // Tipo de vía
        const resultado = this.obtenerTipoDeVia(separada);
        if (resultado.elementos == 0) break;
        formateada += capitalizar(resultado.valor) + ' ';
        i += resultado.elementos - 1;
        seccion++;
      } else if (seccion == 1) {
        // Número o nombre de vía
        if (regexNumero.test(elemento)) {
          formateada += elemento + ' ';
        } else {
          const resultado = this.obtenerNombreDeVia(separada.slice(i));
          if (resultado.elementos == 0) break;
          formateada += capitalizar(resultado.valor) + ' ';
          i += resultado.elementos - 1;
        }
        seccion++;
      } else if (seccion == 2 || seccion == 4 || seccion == 6) {
        // Complementos de vía
        const resultado = this.obtenerComplementosDeVia(separada.slice(i));
        if (resultado.elementos > 0) {
          formateada += capitalizar(resultado.valor) + ' ';
        }
        i += resultado.elementos - 1;
        seccion++;
      } else if (seccion == 3) {
        // Número de vía secundaria
        if (regexNumero.test(elemento)) {
          formateada += '# ' + elemento + ' ';
          seccion++;
        } else {
          break;
        }
      } else if (seccion == 5) {
        // Número de edificación
        if (regexNumero.test(elemento)) {
          formateada += '- ' + elemento + ' ';
          seccion++;
        } else {
          break;
        }
      } else if (seccion == 7) {
        // Detalles
        const resultado = findBestMatch(elemento, detallesDireccion);
        let palabra = resultado.bestMatch.target;
        if (resultado.bestMatch.rating < this.ratingMinimo) palabra = elemento;
        formateada += capitalizar(palabra) + ' ';
      }
    }

    // Guardar dirección formateada
    formateada = formateada.trim();
    const correcta = regexDireccion.test(formateada);
    if (correcta) {
      this.direccionFormateada = formateada.match(regexDireccion)![0];
      if (this.detalles == '')
        this.detalles = formateada.replace(this.direccionFormateada, '');
    } else {
      this.direccionFormateada = formateada;
    }

    // Devolver si la dirección fue formateada correctamente
    return correcta;
  }

  /**
   * Separa las palabras clave de la dirección.
   * @param {string} direccionCruda - Dirección cruda.
   * @returns {string} - Dirección con las palabras clave separadas.
   * @author Juan Corral
   */
  private _separarPalabrasClave(direccionCruda: string): string {
    const palabrasClave = sufijosCardinales.concat(bis);
    palabrasClave.sort((a, b) => b.length - a.length);
    let ret = direccionCruda;
    for (const palabra of palabrasClave) {
      ret = ret.replace(new RegExp(palabra, 'g'), ` ${palabra} `);
    }
    return ret;
  }

  /**
   * Devuelve el tipo de la vía.
   * @param {string[]} elementos - Elementos de la dirección.
   * @returns {ComponenteDireccion} - Tipo de la vía y la cantidad de elementos que la componen.
   * @author Juan Corral
   */
  private obtenerTipoDeVia(elementos: string[]): ComponenteDireccion {
    let usados = 0;
    while (
      usados < elementos.length &&
      findBestMatch(elementos[usados], tiposDeVia).bestMatch.rating >
        this.ratingMinimo &&
      !regexNumero.test(elementos[usados])
    ) {
      usados++;
    }

    let str = '';
    for (let j = 0; j < usados; j++) {
      str += elementos[j] + ' ';
    }
    str = str.trim();

    const resultado = findBestMatch(str, tiposDeVia).bestMatch;
    let valor = resultado.target;
    if (resultado.rating < this.ratingMinimo) valor = str;
    return { valor, elementos: usados };
  }

  /**
   * Devuelve el nombre de la vía
   * @param {string[]} elementos - Elementos de la dirección.
   * @returns {ComponenteDireccion} - Nombre de la vía y cantidad de elementos que la componen.
   * @author Juan Corral
   */
  private obtenerNombreDeVia(elementos: string[]): ComponenteDireccion {
    let usados = 0;
    while (
      usados < elementos.length &&
      !this.esComplementoDeVia(elementos[usados]) &&
      !regexNumero.test(elementos[usados])
    ) {
      usados++;
    }

    let str = '';
    for (let j = 0; j < usados; j++) {
      str += elementos[j] + ' ';
    }
    str = str.trim();

    const resultado = findBestMatch(str, nombresAvenidas).bestMatch;
    let valor = resultado.target;
    if (resultado.rating < this.ratingMinimo) valor = str;
    return { valor, elementos: usados };
  }

  /**
   * Devuelve los complementos de la vía.
   * @param {string[]} elementos - Elementos de la dirección.
   * @returns {ComponenteDireccion} - Complementos de la vía y cantidad de elementos que lo componen.
   * @author Juan Corral
   */
  private obtenerComplementosDeVia(elementos: string[]): ComponenteDireccion {
    let usados = 0;
    while (
      usados < elementos.length &&
      this.esComplementoDeVia(elementos[usados])
    ) {
      usados++;
    }

    let valor = '';
    for (let j = 0; j < usados; j++) {
      valor += elementos[j] + ' ';
    }
    valor = valor.trim();

    return { valor, elementos: usados };
  }

  /**
   * Devuelve si la palabra es un complemento de vía o no.
   * @param {string} palabra - Palabra a evaluar.
   * @returns {boolean} - Si la palabra es un complemento de vía o no.
   * @author Juan Corral
   */
  private esComplementoDeVia(palabra: string): boolean {
    let ret = false;
    if (regexNumero.test(palabra)) {
      ret = false;
    } else if (palabra.length == 1) {
      ret = true;
    } else if (bis.includes(palabra)) {
      ret = true;
    } else if (sufijosCardinales.includes(palabra)) {
      ret = true;
    }
    return ret;
  }
}

/* Tipos de vía */
const tiposDeVia: string[] = [
  'autopista',
  'avenida',
  'carrera',
  'calle',
  'circunvalar',
  'diagonal',
  'kilómetro',
  'transversal',
  'avenida carrera',
  'avenida calle',
];

/* Nombres de avenidas en Bogotá */
const nombresAvenidas: string[] = [
  'norte',
  'agoberto mejía',
  'alsacia',
  'boyacá',
  'caracas',
  'chile',
  'circunvalar',
  'ciudad de cali',
  'ciudad de villavicencio',
  'el tintal',
  'eldorado',
  'ferrocarril de occidente',
  'fucha',
  'jiménez',
  'josé celestino mutis',
  'la Conejera',
  'la Esperanza',
  'las torres',
  'longitudinal de occidente',
  'manuel cepeda vargas',
  'nqs',
  'pepe sierra',
  'quiroga',
  'san bernardino',
  'bosa',
  'caracas',
  'séptima',
  'centenario',
  'las américas',
  'los comuneros',
  'primero de mayo',
  'suba',
];

/* Sufijos Cardinales */
const sufijosCardinales: string[] = [
  'norte',
  'sur',
  'este',
  'oeste',
  'occidente',
  'oriente',
];

const bis: string[] = ['bis'];

/* Palabras clave de los detalles de la dirección */
const detallesDireccion: string[] = [
  'apartamento',
  'barrio',
  'casa',
  'caserío',
  'corregimiento',
  'conjunto',
  'edificio',
  'entrada',
  'etapa',
  'local',
  'piso',
  'portería',
  'torre',
  'urbanización',
];

/* Expresión regular para validar direcciones */
const regexDireccion = new RegExp(
  '^(Autopista|Avenida|Avenida Calle|Avenida Carrera|Calle|Carrera|Circunvalar|Diagonal|Kilómetro|Transversal)\\s' +
    '([0-9]{1,3}|[a-zA-Z]{1,15})(\\s[A-Z])?(\\sBis(\\s[A-Z])?)?(\\s(Norte|Este|Oeste|Occidente|Oriente|Sur))?\\s' +
    '#\\s([0-9]{1,3})(\\s[A-Z])?(\\sBis(\\s[A-Z])?)?(\\s(Norte|Este|Oeste|Occidente|Oriente|Sur))?\\s' +
    '-\\s[0-9]{1,3}(\\s(Norte|Este|Oeste|Occidente|Oriente|Sur))?',
);

/* Expresión regular para validar números */
const regexNumero = new RegExp('^[0-9]{1,3}$');
