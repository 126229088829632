<div class="header mat-elevation-z2">
  <nucleo-header (clickBotonSidenav)="alternarSideNav(sidenav)"></nucleo-header>
</div>

<mat-sidenav-container class="contenedor-principal">
  <mat-sidenav #sidenav mode="side" class="sidenav">
    <nucleo-sidenav></nucleo-sidenav>
  </mat-sidenav>

  <mat-sidenav-content class="contenido mat-app-background">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
