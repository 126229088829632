import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { EsqueletoComponent } from './esqueleto/esqueleto.component';

import { NucleoRoutingModule } from './nucleo-routing.module';
import { FichaModule } from '../compartido/ficha/ficha.module';
import { FormularioModule } from '../compartido/formulario/formulario.module';
import { CalendarioComponent } from '../compartido/calendario/calendario.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [HeaderComponent, SidenavComponent, EsqueletoComponent],
  imports: [
    CommonModule,

    NucleoRoutingModule,
    FichaModule,
    FormularioModule,
    CalendarioComponent,

    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatBadgeModule,
    MatStepperModule,
  ],
})
export class NucleoModule {}
