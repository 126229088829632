import { ClienteDTO } from 'src/app/clientes/utilidades/clientes.models';
import { UsuarioDTO } from 'src/app/nucleo/autenticacion/autenticacion.models';

/* Estructura de Verificación de identidad en el API */
export interface VerificacionIdentidadDTO {
  id: number;
  usuario: UsuarioDTO;
  cliente: ClienteDTO;
  estado: EstadoVerificacionIdentidad;
  estado_display: string;
  archivo: string | null | undefined;
}

/* Estados de Verificación de identidad */
export enum EstadoVerificacionIdentidad {
  PENDIENTE = 'pendiente',
  APROBADA = 'aprobada',
  RECHAZADA = 'rechazada',
}
