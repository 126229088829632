import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { DomicilioDTO } from './domicilios.models';
import {
  ListaDTO,
  Parametros,
} from '../../compartido/utilidades/compartido.models';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Domicilio } from './domicilio.class';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class DomiciliosService {
  constructor(private readonly _http: HttpClient) {}

  /**
   * Obtiene los domicilios del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<DomicilioDTO>>} - Los domicilios obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener los domicilios.
   * @author Juan Corral
   */
  public obtenerDomicilios$(
    params: Parametros,
  ): Promise<ListaDTO<DomicilioDTO>> {
    return firstValueFrom(
      this._http.get<ListaDTO<DomicilioDTO>>('domicilios/', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Obtiene información del domicilio del servidor.
   * @param {number} id - El id del domicilio a obtener.
   * @returns {Promise<Domicilio | undefined> } - La respuesta del servidor (promise).
   * @author Juan Corral
   */
  public obtenerDomicilio$(id: number): Promise<Domicilio | undefined> {
    return firstValueFrom(
      this._http.get<DomicilioDTO>('domicilios/' + id).pipe(
        map((domicilio: DomicilioDTO) => Domicilio.fromDTO(domicilio)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Envía un archivo CSV con los domicilios al correo del usuario.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @throws {ErrorFrontend} - Si hubo un error al descargar los domicilios.
   * @author Juan Corral
   */
  public async descargarDomicilios$(filtros?: {
    [filtro: string]: string | number;
  }): Promise<void> {
    const params: Parametros = {};
    if (filtros) {
      for (const filtro of Object.keys(filtros)) {
        params[filtro] = filtros[filtro];
      }
    }
    await firstValueFrom(
      this._http
        .get<ListaDTO<DomicilioDTO>>('domicilios/descargar/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Envía un archivo PDF con las etiquetas de los domicilios al correo del usuario.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @throws {ErrorFrontend} - Si hubo un error al descargar las etiquetas.
   * @author Juan Corral
   */
  public async descargarEtiquetas$(filtros?: {
    [filtro: string]: string | number;
  }): Promise<void> {
    const params: Parametros = {};
    if (filtros) {
      for (const filtro of Object.keys(filtros)) {
        params[filtro] = filtros[filtro];
      }
    }
    await firstValueFrom(
      this._http
        .get<
          ListaDTO<DomicilioDTO>
        >('domicilios/descargar_etiquetas/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Despacha un domicilio.
   * @param {number} id - El id del domicilio a despachar.
   * @throws {ErrorFrontend} - Si hubo un error al despachar el domicilio.
   * @autor Juan Corral
   */
  public async despacharDomicilio$(id: number): Promise<void> {
    await firstValueFrom(
      this._http.patch<void>('domicilios/' + id + '/despachar/', {}).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Re-programar un domicilio fallido.
   * @param {number} id - El id del domicilio a re-programar.
   * @param {string} fecha - La nueva fecha planeada para el domicilio.
   * @throws {ErrorFrontend} - Si hubo un error al re-programar el domicilio.
   * @autor Juan Corral
   */
  public async reprogramarDomicilio$(id: number, fecha: string): Promise<void> {
    await firstValueFrom(
      this._http
        .patch<void>('domicilios/' + id + '/reprogramar/', { fecha })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }
}
