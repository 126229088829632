import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccionMasivaDTO,
  ListaDTO,
  Parametros,
} from '../../compartido/utilidades/compartido.models';
import { catchError, firstValueFrom } from 'rxjs';
import { TextoMensajeDTO, MensajeSMSDTO } from './comunicaciones.models';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class ComunicacionesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Obtiene los mensajes SMS del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<MensajeSMSDTO>>} - Los mensajes SMS obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener los mensajes SMS.
   * @author Juan Corral
   */
  public obtenerMensajesSMS$(
    params: Parametros,
  ): Promise<ListaDTO<MensajeSMSDTO>> {
    return firstValueFrom(
      this.http
        .get<ListaDTO<MensajeSMSDTO>>('comunicaciones/sms/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Obtiene una lista de mensajes de texto del servidor.
   * @param {number} organizacion - El id de la organización de los mensajes.
   * @returns {Promise<ListaDTO<TextoMensajeDTO>>} - La lista de mensajes del servidor (promise).
   * @author Juan Corral
   */
  public obtenerTextoMensajes$(
    organizacion: number,
  ): Promise<ListaDTO<TextoMensajeDTO>> {
    const params: Parametros = {};
    if (organizacion) params['organizacion'] = organizacion;
    return firstValueFrom(
      this.http.get<ListaDTO<TextoMensajeDTO>>('comunicaciones/textos/', {
        params,
      }),
    );
  }

  /**
   * Crea mensajes SMS en el servidor a partir de información de domicilios.
   * @param {number} organizacion - El id de la organización de los domicilios.
   * @param {string} fecha - La fecha planeada de los domicilios.
   * @param {number} mensaje - El id del mensaje.
   * @returns {Promise<AccionMasivaDTO<number, string>>} - La respuesta del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al crear los mensajes SMS.
   * @author Juan Corral
   */
  public async crearMensajesSMS$(
    organizacion: number,
    fecha: string,
    texto: number,
  ): Promise<AccionMasivaDTO<number, string>> {
    return firstValueFrom(
      this.http
        .post<
          AccionMasivaDTO<number, string>
        >('comunicaciones/sms/creacion_masiva/', { organizacion, fecha, texto })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Envía los mensajes con estado 'En Cola' a los destinatarios.
   * @returns {Promise<{ task_id: string }>} - La respuesta del servidor (promise).
   * @author Juan Corral
   */
  public async enviarMensajesSMS$(): Promise<{ task_id: string }> {
    return firstValueFrom(
      this.http.post<{ task_id: string }>(
        'comunicaciones/sms/envio_masivo/',
        {},
      ),
    );
  }
}
