import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { VerificacionIdentidad } from 'src/app/ingreso/utilidades/verificacion-identidad.class';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { CONFIGURACION_FICHA_VERIFICACION_IDENTIDAD } from './utilidades/verificacion-identidad.static';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { IngresoService } from 'src/app/ingreso/utilidades/ingreso.service';

@Component({
  selector: 'clientes-verificacion-identidad',
  templateUrl: './verificacion-identidad.component.html',
  styleUrls: ['./verificacion-identidad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificacionIdentidadComponent implements OnInit {
  /* Verificación de identidad */
  protected verificacion: VerificacionIdentidad | undefined;

  /* Configuración ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_VERIFICACION_IDENTIDAD);

  constructor(
    private readonly _ingresoService: IngresoService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _alertasService: AlertasService,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Obtener la verificación de identidad
    firstValueFrom<string>(
      this._route.params.pipe(map((params: Params) => params['id'])),
    )
      .then((id) =>
        this._ingresoService
          .obtenerVerificacionIdentidad$(parseInt(id))
          .then((verificacion) => {
            this.verificacion = VerificacionIdentidad.fromDTO(verificacion);
            this._cdr.markForCheck();
          }),
      )
      .catch((error) => {
        throw new ErrorDesarrollo('Verificación de identidad no encontrada');
      });
  }

  /**
   * Aprueba o rechaza la verificación de identidad
   * @param {boolean} aprobar - Si se aprueba o no la verificación de identidad
   * @author Juan Corral
   */
  protected aprobarVerificacion(aprobar: boolean): void {
    if (!this.verificacion) return;
    this._ingresoService
      .aprobarVerificacionIdentidad$(this.verificacion.id, aprobar)
      .then(() => {
        this._alertasService.alertarExito(
          'Verificación de identidad actualizada exitosamente',
        );
        this._router.navigate(['clientes', 'verificaciones']);
      })
      .catch((error: ErrorFrontend) => {
        this._alertasService.alertarError('Error: ' + error.message);
      });
  }
}
