<compartido-ficha>
  <compartido-tabla-asincrona
    #tabla
    class="tabla-domicilios {{ responsiveService.claseCSS$ | async }}"
    [dataSource]="dataSource"
    [configuracion]="configTabla"
    (clickAccion)="clickAccion($event.fila, $event.accion)"
    (clickBoton)="clickBoton($event)"
  ></compartido-tabla-asincrona>
</compartido-ficha>
