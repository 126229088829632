import { EstadoItemOrden, EstadoOrden } from './ordenes.models';

/* Máximo número de órdenes a descargar */
export const MAX_ORDENES_DESCARGA = 5000;

/* Opciones del estado de la orden */
export const OPCIONES_ESTADO_ORDEN: { nombre: string; valor: EstadoOrden }[] = [
  { nombre: 'Pendiente', valor: EstadoOrden.PENDIENTE },
  { nombre: 'Aprobada', valor: EstadoOrden.APROBADA },
  { nombre: 'Rechazada', valor: EstadoOrden.RECHAZADA },
  { nombre: 'Confirmada', valor: EstadoOrden.CONFIRMADA },
  { nombre: 'Preparada', valor: EstadoOrden.PREPARADA },
  { nombre: 'Preparada Parcialmente', valor: EstadoOrden.PREPARADA_PARCIAL },
  { nombre: 'No Preparada', valor: EstadoOrden.NO_PREPARADA },
  { nombre: 'Cancelada', valor: EstadoOrden.CANCELADA },
];

/* Estados de orden donde ya fue preparada */
export const ESTADOS_ORDEN_PREPARADA = [
  EstadoOrden.PREPARADA,
  EstadoOrden.PREPARADA_PARCIAL,
  EstadoOrden.NO_PREPARADA,
];

/* Estados de orden donde ya fue confirmada */
export const ESTADOS_ORDEN_CONFIRMADA = [
  EstadoOrden.CONFIRMADA,
  ...ESTADOS_ORDEN_PREPARADA,
];

/* Estados de orden donde ya fue aprobada */
export const ESTADOS_ORDEN_APROBADA = [
  ...ESTADOS_ORDEN_CONFIRMADA,
  EstadoOrden.APROBADA,
];

/* Opciones del estado de item la orden */
export const OPCIONES_ESTADO_ITEM_ORDEN: {
  nombre: string;
  valor: EstadoItemOrden;
}[] = [
  { nombre: 'Pendiente', valor: EstadoItemOrden.PENDIENTE },
  { nombre: 'Preparado', valor: EstadoItemOrden.PREPARADO },
  {
    nombre: 'Preparado Parcialmente',
    valor: EstadoItemOrden.PREPARADO_PARCIAL,
  },
  { nombre: 'No Preparada', valor: EstadoItemOrden.NO_PREPARADO },
];
