import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';
import { TokenService } from './token.service';
import { environment } from 'src/environments/environment';

/**
 * Intercepta los HTTP requests para dirigirlos al API y agregar las credenciales
 * @author Juan Corral
 */
@Injectable()
export class InterceptorAPI implements HttpInterceptor {
  constructor(private _tokenService: TokenService) {}

  private readonly BASE_URL: string = environment.apiUrl;

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Si el request es para una URL externa, mandar el request sin token
    if (request.url.indexOf('http') > -1) return next.handle(request);

    // Dirigir el request al URL del API
    let apiReq = request.clone({ url: `${this.BASE_URL}/${request.url}` });

    // Agregar slash final si no lo tiene
    if (!apiReq.url.endsWith('/'))
      apiReq = apiReq.clone({ url: apiReq.url + '/' });

    // Si el request es para un endpoint de autenticación, mandar el request sin token
    if (
      request.url.indexOf('autenticacion') > -1 &&
      request.url.indexOf('logout') == -1 &&
      request.url.indexOf('usuario') == -1 &&
      request.url.indexOf('verificaciones') == -1
    )
      return next.handle(apiReq);

    // Revisar si hay una sesión activa (si el refresh token no ha expirado)
    return from(this._tokenService.refreshTokenExpirado$()).pipe(
      mergeMap((expirado: boolean) => {
        // Si el usuario no tiene una sesión activa, redirigir al login
        if (expirado) return next.handle(apiReq);

        // Revisar si el access token está expirado
        return from(this._tokenService.accessTokenExpirado$()).pipe(
          mergeMap((expirado: boolean) => {
            // Si el access token no está expirado, agregarlo al request
            if (!expirado) {
              const access = this._tokenService.accessToken;
              apiReq = apiReq.clone({
                headers: apiReq.headers.set(
                  'Authorization',
                  `Bearer ${access}`,
                ),
              });
              return next.handle(apiReq);
            }

            // Si el access token está expirado, refrescarlo
            return from(this._tokenService.refrescarToken$()).pipe(
              mergeMap((success) => {
                // Si no se pudo refrescar el token, redirigir al login
                if (!success) return next.handle(apiReq);

                // Si se refrescó el token, agregarlo al request
                const access = this._tokenService.accessToken;
                apiReq = apiReq.clone({
                  headers: apiReq.headers.set(
                    'Authorization',
                    `Bearer ${access}`,
                  ),
                });
                return next.handle(apiReq);
              }),
            );
          }),
        );
      }),
    );
  }
}
