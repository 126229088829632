<compartido-ficha [configuracion]="configFicha">
  <div
    class="verificacion-identidad {{ responsiveService.claseCSS$ | async }}"
    *ngIf="verificacion"
  >
    <div class="cliente mat-elevation-z2 borde-primario">
      <div class="top fondo-primario color-primario-contraste">
        <mat-icon color="primary" class="fondo-primario-contraste">
          person
        </mat-icon>
        <h2>{{ verificacion!.cliente.nombre }}</h2>
      </div>
      <div class="resto mat-app-background">
        <mat-list>
          <mat-list-item>
            <mat-icon matListItemIcon>assignment_ind</mat-icon>
            <h3 matListItemTitle>Cédula</h3>
            <p matListItemLine>{{ verificacion!.cliente.cedula }}</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>business</mat-icon>
            <h3 matListItemTitle>Organización</h3>
            <p matListItemLine>
              {{ verificacion!.cliente.organizacion.nombre }}
            </p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <mat-icon class="flecha">arrow_forward_ios</mat-icon>

    <img
      [src]="verificacion!.archivo"
      alt="Documento del Cliente"
      class="mat-elevation-z2"
    />
  </div>

  <div class="botones" *ngIf="verificacion">
    <button
      mat-fab
      color="warn"
      (click)="aprobarVerificacion(false)"
      class="mat-elevation-z2"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <button
      mat-fab
      color="primary"
      (click)="aprobarVerificacion(true)"
      class="mat-elevation-z2"
    >
      <mat-icon>done</mat-icon>
    </button>
  </div>
</compartido-ficha>
