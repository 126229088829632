/* Configuración del mapa para crear dirección */
export const CONFIGURACION_MAPA_FORMULARIO: google.maps.MapOptions = {
  zoom: 18,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  scaleControl: false,
  rotateControl: false,
  clickableIcons: false,
  draggableCursor: 'pointer',
};

/* Centro de Bogotá */
export const CENTRO_BOGOTA = { lat: 4.60971, lng: -74.08175 };
