import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionTablaAsincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';

/* Configuración de la ficha de re-programar domicilios */
export const CONFIGURACION_FICHA_REPROGRAMAR_DOMICILIOS: ConfiguracionFicha = {
  header: {
    titulo: 'Re-programar Domicilios Fallidos',
    icono: Icono.REPROGRAMAR,
  },
  maxWidth: 800,
};

/* Configuración del formulario de re-programar domicilios */
export const CONFIGURACION_FORMULARIO_REPROGRAMAR_DOMICILIOS: ConfiguracionFormulario =
  {
    campos: [
      {
        nombre: 'Organización',
        slug: 'organizacion',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        requerido: true,
        opciones: [], // opciones se define dinámicamente en el componente
      },
      {
        nombre: 'Fecha planeada',
        slug: 'fecha',
        tipo: TipoCampoFormulario.FECHA,
        requerido: true,
        minimo: formatearFecha(Date.now()),
      },
    ],
    estilo: {
      display: DisplayFormulario.GRID,
      posicionLabel: PosicionLabelFormulario.TOP,
      widthCampo: 250,
      widthLabel: 250,
      esconderErrores: true,
    },
  };

/* Configuración de la tabla de re-programar domicilios */
export const CONFIGURACION_TABLA_REPROGRAMAR_DOMICILIOS: ConfiguracionTablaAsincrona =
  {
    columnas: [
      { slug: 'id', nombre: 'ID', ordenable: true },
      { slug: 'orden', nombre: 'Orden' },
      { slug: 'referencia', nombre: 'N° Referencia' },
      { slug: 'cliente', nombre: 'Cliente' },
    ],
    filtros: undefined,
    botones: undefined,
    acciones: [
      {
        nombre: 'Re-programar',
        slug: 'reprogramar',
        icono: Icono.REPROGRAMAR,
      },
    ],
  };
