import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfiguracionDialogoAccion } from './utilidades/dialogo-accion.static';

@Component({
  selector: 'dialogos-accion',
  templateUrl: './dialogo-accion.component.html',
  styleUrls: ['./dialogo-accion.component.scss'],
})
export class DialogoAccionComponent {
  constructor(
    protected readonly dialogRef: MatDialogRef<DialogoAccionComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected readonly configuracion: ConfiguracionDialogoAccion,
  ) {}
}
