import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { OrdenesService } from '../../utilidades/ordenes.service';
import {
  CONFIGURACION_TABLA_ORDENES,
  DETALLES_ACCIONES_TABLA_ORDENES,
} from './utilidades/tabla-ordenes.static';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { SourceTablaOrdenes } from './utilidades/source-tabla-ordenes.class';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { obtenerFiltro } from 'src/app/compartido/tabla/utilidades/tabla.utils.models';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import { MAX_ORDENES_DESCARGA } from '../../utilidades/ordenes.static';
import { VerOrdenComponent } from '../ver-orden/ver-orden.component';
import { AprobarOrdenComponent } from '../aprobar-orden/aprobar-orden.component';
import { PrepararOrdenComponent } from '../preparar-orden/preparar-orden.component';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';

@Component({
  templateUrl: './tabla-ordenes.component.html',
  styleUrls: ['./tabla-ordenes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaOrdenesComponent implements OnInit {
  constructor(
    private readonly _ordenesService: OrdenesService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    private readonly _organizacionService: OrganizacionService,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaOrdenes(_ordenesService);
  }

  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_ORDENES);

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaOrdenes;

  /* Referencia a la tabla */
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerFiltro(this.configTabla, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = [
          { nombre: 'Todos', valor: '' },
          ...organizaciones.map((org) => ({
            nombre: org.nombre,
            valor: org.nombre,
          })),
        ];
        this._cdr.markForCheck();
      });
  }

  /**
   * Ejecuta una acción en la fila.
   * @param {FilaTabla} fila - La fila seleccionada.
   * @param {string} accion - La acción a ejecutar.
   * @autor Juan Corral
   */
  protected clickAccion(fila: FilaTabla, accion: string): void {
    const detalles = DETALLES_ACCIONES_TABLA_ORDENES[accion];
    if (detalles === undefined)
      throw new ErrorDesarrollo('Acción no soportada');
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: detalles.componente,
      inputs: { idOrden: fila['id'] },
      output: detalles.output,
      url: this._router.serializeUrl(
        this._router.createUrlTree([...detalles.segmentosUrl, fila['id']]),
      ),
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    this._dialogosService
      .abrirVistaPrevia$(config, {
        maxWidth: '90vw',
        width: detalles.width + 'px',
      })
      .then(() => this.tabla.cargarPagina());
  }

  /**
   * Realiza la acción de un botón.
   * @param {string} boton - El botón seleccionado.
   * @autor Juan Corral
   */
  protected clickBoton(boton: string): void {
    if (boton === 'descargar') this._descargarOrdenes();
    else if (boton === 'importar')
      this._router.navigate(['ordenes', 'importar']);
    else if (boton === 'crear') this._router.navigate(['ordenes', 'crear']);
    else throw new ErrorDesarrollo('Botón no soportado');
  }

  /**
   * Descarga las órdenes en un archivo CSV.
   * @author Juan Corral
   */
  private _descargarOrdenes(): void {
    const cantidad = this.dataSource.total;
    const s = cantidad > 1 ? 's' : '';
    const ordenes = cantidad > 1 ? 'órdenes' : 'orden';

    if (cantidad === 0) {
      this._alertasService.alertarError('No hay órdenes para descargar');
      return;
    } else if (cantidad > MAX_ORDENES_DESCARGA) {
      this._alertasService.alertarError(
        `No puedes descargar más de ${MAX_ORDENES_DESCARGA} órdenes a la vez`,
      );
      return;
    }

    this._dialogosService
      .abrirDialogo$(
        `¿Descargar ${cantidad} ${ordenes}?`,
        `Enviaremos un archivo CSV con la${s} ${ordenes} al correo registrado en tu perfil.`,
        'Confirmar',
        'Cancelar',
      )
      .then((confirmado: boolean) => {
        if (!confirmado) return;

        this._alertasService.alertar(
          `Enviando la${s} ${ordenes}, esto puede tardar unos segundos...`,
        );
        this._ordenesService
          .descargarOrdenes$(this.tabla.valoresFiltros)
          .then(() =>
            this._alertasService.alertarExito(
              'El archivo ha sido enviado a tu correo',
            ),
          )
          .catch((error: ErrorFrontend) =>
            this._alertasService.alertarError('Error: ' + error.message),
          );
      });
  }
}
