import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NAVEGACION } from '../utilidades/nucleo.static';

@Component({
  selector: 'nucleo-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  /* Navegación */
  protected readonly NAVEGACION = NAVEGACION;

  constructor(private router: Router) {}
}
