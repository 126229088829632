import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, firstValueFrom, Subject, Subscription } from 'rxjs';
import {
  CampoFormulario,
  OpcionCampo,
  TipoCampoFormulario,
} from '../../compartido/formulario/utilidades/formulario.models';
import { obtenerCampo } from '../../compartido/formulario/utilidades/formulario.utils';
import { ErrorDesarrollo } from '../../compartido/utilidades/error.utils';
import { FormateadorDireccion } from '../../compartido/utilidades/formateador-direccion.class';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class PlacesService {
  /* API Key */
  private readonly API_KEY = 'AIzaSyCKhtQNMW0qP-CPly_PXjdLEvRnpZ2fo4U';

  /* Geocoder */
  private _geocoderObj: google.maps.Geocoder | undefined;
  private get _geocoder(): google.maps.Geocoder {
    if (!this._geocoderObj) this._geocoderObj = new google.maps.Geocoder();
    return this._geocoderObj;
  }

  /* Places */
  private _placesObj: google.maps.places.PlacesService | undefined;
  private async _places(): Promise<google.maps.places.PlacesService> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._placesObj)
      this._placesObj = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
    return this._placesObj;
  }

  /* Autocomplete */
  private _autocompleteObj: google.maps.places.AutocompleteService | undefined;
  private async _autocomplete(): Promise<google.maps.places.AutocompleteService> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._autocompleteObj)
      this._autocompleteObj = new google.maps.places.AutocompleteService();
    return this._autocompleteObj;
  }

  /* Autocomplete Token */
  private _autocompleteTokenObj:
    | google.maps.places.AutocompleteSessionToken
    | undefined;
  private async _autocompleteToken(): Promise<google.maps.places.AutocompleteSessionToken> {
    if (!google.maps.places) await google.maps.importLibrary('places');
    if (!this._autocompleteTokenObj)
      this._autocompleteTokenObj =
        new google.maps.places.AutocompleteSessionToken();
    return this._autocompleteTokenObj;
  }

  /* Address Validation */
  private readonly ADDRESS_VALIDATION_API_URL =
    'https://addressvalidation.googleapis.com/v1:validateAddress?key=' +
    this.API_KEY;

  constructor(private readonly _http: HttpClient) {}

  /**
   * Busca una dirección en la API de Google Places
   * @param {string} input - La dirección a buscar
   * @returns {Promise<google.maps.places.PlaceResult[]>} - Los resultados de la búsqueda
   * @author Juan Corral
   */
  public async buscarDireccion$(
    input: string,
  ): Promise<google.maps.places.PlaceResult[]> {
    const places = await this._places();
    return new Promise((resolve) => {
      places.findPlaceFromQuery(
        {
          query: input,
          fields: ['formatted_address', 'geometry.location'],
        },
        (results: google.maps.places.PlaceResult[] | null) => {
          resolve(results ?? []);
        },
      );
    });
  }

  /**
   * Auto-completa una dirección
   * @param {string} input - La dirección a auto-completar
   * @returns {Promise<google.maps.places.AutocompletePrediction[]>} - Las predicciones de la dirección
   * @author Juan Corral
   */
  public async autocompletarDireccion$(
    input: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const autocomplete = await this._autocomplete();
    const sessionToken = await this._autocompleteToken();
    return new Promise((resolve) => {
      autocomplete.getPlacePredictions(
        {
          input,
          sessionToken,
          types: ['address'],
          componentRestrictions: { country: 'CO' },
        },
        (results: google.maps.places.AutocompletePrediction[] | null) => {
          resolve(results ?? []);
        },
      );
    });
  }

  public async obtenerLugar$(
    placeId: string,
  ): Promise<google.maps.places.PlaceResult | null> {
    const places = await this._places();
    return new Promise((resolve) => {
      places.getDetails(
        {
          placeId,
          fields: ['geometry.location', 'address_components'], // 'formatted_address'],
        },
        (place: google.maps.places.PlaceResult | null) => {
          resolve(place);
        },
      );
    });
  }

  /**
   * Busca las coordenadas de una dirección
   * @param {string} direccion - La dirección a buscar
   * @returns {Promise<google.maps.LatLng | null>} - Las coordenadas de la dirección
   * @author Juan Corral
   */
  public async buscarCoordenadas$(
    direccion: string,
  ): Promise<google.maps.LatLng | null> {
    return new Promise((resolve) => {
      this._geocoder.geocode({ address: direccion }, (results) => {
        if (results && results.length > 0) {
          resolve(results[0].geometry.location);
        }
        resolve(null);
      });
    });
  }

  /**
   * Valida una dirección
   * @param {string} direccion - La dirección a validar
   * @returns {Promise<boolean>} - Si la dirección es válida o no
   * @author Juan Corral
   */
  public async validarDireccion$(direccion: string): Promise<boolean> {
    const body = {
      address: {
        addressLines: [direccion],
        regionCode: 'CO',
      },
    };
    const resultado = await firstValueFrom(
      this._http.post<any>(this.ADDRESS_VALIDATION_API_URL, body),
    );
    return resultado;
  }

  /**
   * Configura los campos de un formulario para auto-completar una dirección.
   * @param {CampoFormulario[]} campos - Los campos a auto-completar
   * @returns {Promise<void>} - Nada
   * @author Juan Corral
   */
  public async configurarAutocompleteDireccion(
    campos: CampoFormulario[],
  ): Promise<Subscription> {
    const suscripcion = new Subscription();

    // Autocompletar la dirección
    const campoDireccion = obtenerCampo(campos, 'direccion')!;
    campoDireccion.valorOut = new Subject<string | number>();
    if (campoDireccion.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
      throw new ErrorDesarrollo(
        'El campo de dirección debe ser de tipo AUTOCOMPLETE',
      );
    campoDireccion.opcionesIn = new Subject<OpcionCampo<string | number>[]>();
    campoDireccion.opcionOut = new Subject<OpcionCampo<string | number>>();
    const escucharDireccion = campoDireccion.valorOut
      .pipe(debounceTime(700))
      .subscribe(async (direccion: string) => {
        const lugares: google.maps.places.AutocompletePrediction[] =
          await this.autocompletarDireccion$(direccion);
        const opcionesDireccion = lugares.map(
          (s: google.maps.places.AutocompletePrediction) =>
            ({
              nombre: s.description,
              valor: s.structured_formatting.main_text,
              extra: s.place_id,
            }) as OpcionCampo<string>,
        );
        campoDireccion.opcionesIn!.next(opcionesDireccion);
      });
    suscripcion.add(escucharDireccion);

    // Agregar observable al campo de direccion formateada
    const campoDirFormateada = obtenerCampo(campos, 'direccion_formateada');
    if (campoDirFormateada) campoDirFormateada.valorIn = new Subject<string>();

    // Agregar observable al campo de ciudad
    const campoCiudad = obtenerCampo(campos, 'ciudad');
    if (campoCiudad) campoCiudad.valorIn = new Subject<string>();

    // Agregar observable para el campo de localidad
    const campoLocalidad = obtenerCampo(campos, 'localidad');
    if (campoLocalidad) campoLocalidad.valorIn = new Subject<string>();

    // Agregar observable al campo de barrio
    const campoBarrio = obtenerCampo(campos, 'barrio');
    if (campoBarrio) campoBarrio.valorIn = new Subject<string>();

    // Agregar observable al campo de latitud
    const campoLatitud = obtenerCampo(campos, 'latitud');
    if (campoLatitud) {
      campoLatitud.valorIn = new Subject<string>();
      campoLatitud.valorOut = new Subject<string>();
    }

    // Agregar observable al campo de longitud
    const campoLongitud = obtenerCampo(campos, 'longitud');
    if (campoLongitud) {
      campoLongitud.valorIn = new Subject<string>();
      campoLongitud.valorOut = new Subject<string>();
    }

    // Agregar observable al campo de coordenadas
    const campoCoordenadas = obtenerCampo(campos, 'coordenadas');
    if (campoCoordenadas) {
      campoCoordenadas.valorIn = new Subject<{
        latitud: string;
        longitud: string;
      }>();
      campoCoordenadas.valorOut = new Subject<{
        latitud: string;
        longitud: string;
      }>();
    }

    // Crear conexión entre direccion y otros campos
    const seleccionarDireccion = campoDireccion.opcionOut!.subscribe(
      async (opcion: OpcionCampo<string | number>) => {
        const lugar = await this.obtenerLugar$(opcion.extra);
        if (!lugar) return;

        // Actualizar la dirección formateada
        if (campoDirFormateada) {
          const formateador = new FormateadorDireccion(opcion.valor as string);
          const formateada = formateador.formatearDireccion();
          if (formateada)
            campoDirFormateada.valorIn!.next(formateador.direccionFormateada);
          // campoDireccionFormateada.valorIn!.next(lugar.formatted_address);
        }

        // Actualizar la ciudad
        if (campoCiudad) {
          const ciudad = lugar.address_components?.find((c) =>
            c.types.includes('locality'),
          );
          if (ciudad) campoCiudad.valorIn!.next(ciudad.long_name);
        }

        // Actualizar la localidad
        if (campoLocalidad) {
          const localidad = lugar.address_components?.find((c) =>
            c.types.includes('sublocality'),
          );
          if (localidad) campoLocalidad.valorIn!.next(localidad.long_name);
        }

        // Actualizar el barrio
        if (campoBarrio) {
          const barrio = lugar.address_components?.find((c) =>
            c.types.includes('neighborhood'),
          );
          if (barrio) campoBarrio.valorIn!.next(barrio.long_name);
        }

        // Actualizar la latitud
        if (campoLatitud) {
          const latitud = lugar.geometry?.location?.lat();
          if (latitud) campoLatitud.valorIn!.next(latitud.toString());
        }

        // Actualizar la longitud
        if (campoLongitud) {
          const longitud = lugar.geometry?.location?.lng();
          if (longitud) campoLongitud.valorIn!.next(longitud.toString());
        }

        // Actualizar las coordenadas
        if (campoCoordenadas) {
          const coordenadas = lugar.geometry?.location;
          if (coordenadas) {
            campoCoordenadas.valorIn!.next({
              latitud: coordenadas.lat(),
              longitud: coordenadas.lng(),
            });
          }
        }
      },
    );
    suscripcion.add(seleccionarDireccion);

    // Crear conexión entre latitud, longitud y coordenadas
    if (campoLatitud && campoLongitud && campoCoordenadas) {
      const actualizarCoordenadas = campoCoordenadas.valorOut!.subscribe(
        (coordenadas) => {
          campoLatitud.valorIn!.next(coordenadas.latitud);
          campoLongitud.valorIn!.next(coordenadas.longitud);
        },
      );
      suscripcion.add(actualizarCoordenadas);
    }

    return suscripcion;
  }
}
