import { BotonFicha } from '../../ficha/utilidades/ficha.models';
import {
  DisplayFormulario,
  EstiloFormulario,
  PosicionLabelFormulario,
} from '../../formulario/utilidades/formulario.models';
import { Icono } from '../../utilidades/compartido.static';

/* Botón para activar el modo edición */
export const BOTON_MODO_EDICION: BotonFicha = {
  nombre: 'Editar',
  icono: Icono.EDITAR,
  slug: 'editar',
};

/* Estilo Formulario Recurso */
export const ESTILO_FORMULARIO_RECURSO: EstiloFormulario = {
  display: DisplayFormulario.GRID,
  posicionLabel: PosicionLabelFormulario.TOP,
  widthCampo: 350,
  widthLabel: 350,
  esconderErrores: true,
};

/* Máximo ancho de la ficha de recurso */
export const MAX_WIDTH_FICHA_RECURSO = 800;
