import { read, utils } from 'xlsx';
import { formatearUTF8, normalizarString } from './valores.utils';
import { ErrorDesarrollo } from './error.utils';

/**
 * Clase para crear y manejar spreadsheets.
 * @author Juan Corral
 */
export class Spreadsheet {
  constructor(
    public nombre: string,
    filas: DatosSpreadsheet,
  ) {
    this._filas = filas;
    this._extraerColumnas();
  }

  /* Columnas de la hoja de cálculo */
  private _columnas: string[] = [];

  /* Filas */
  private _filas: DatosSpreadsheet;
  public set filas(filas: DatosSpreadsheet) {
    this._filas = filas;
    this._extraerColumnas();
  }
  public get filas(): DatosSpreadsheet {
    return this._filas;
  }

  /**
   * Extrae las columnas de la primera fila y las guarda en la propiedad.
   * @author Juan Corral
   */
  private _extraerColumnas(): void {
    if (this._filas.length === 0) this._columnas = [];
    else this._columnas = Object.keys(this._filas[0]);
  }

  /**
   * Normaliza los nombres de las columnas.
   * @author Juan Corral
   */
  public normalizarColumnas(): void {
    for (const columna of this._columnas) {
      this.renombrarColumna(columna, normalizarString(columna));
    }
  }

  /**
   * Agrega una columna a la hoja de cálculo.
   * @param {string} columna - El nombre de la columna a agregar.
   * @param {string} valor - El valor de la columna a agregar.
   * @author Juan Corral
   */
  public agregarColumna(columna: string, valor: string): void {
    this._columnas.push(columna);
    for (const fila of this._filas) {
      fila[columna] = valor;
    }
  }

  /**
   * Devuelve si la hoja de cálculo tiene la columna especificada.
   * @param {string} columna - El nombre de la columna a buscar.
   * @param {boolean} contiene [Opcional] - Si la comparación de nombres debe ser 'contiene'.
   * @returns {boolean} - Si la hoja de cálculo tiene la columna especificada.
   * @author Juan Corral
   */
  public tieneColumna(columna: string, contiene: boolean = false): boolean {
    if (contiene) return this._columnas.some((col) => col.includes(columna));
    return this._columnas.includes(columna);
  }

  /**
   * Devuelve el índice de la columna especificada.
   * @param {string} columna - El nombre de la columna a buscar.
   * @param {boolean} contiene [Opcional] - Si la comparación de nombres debe ser 'contiene'.
   * @returns {number} - El índice de la columna especificada.
   * @author Juan Corral
   */
  public indiceColumna(columna: string, contiene: boolean = false): number {
    if (!this.tieneColumna(columna, contiene))
      throw new ErrorDesarrollo('La columna no existe');
    if (contiene)
      return this._columnas.findIndex((col) => col.includes(columna));
    return this._columnas.indexOf(columna);
  }

  /**
   * Cambia el nombre de una columna.
   * @param {string} columna - El nombre de la columna a cambiar.
   * @param {string} nuevoNombre - El nuevo nombre de la columna.
   * @param {boolean} contiene [Opcional] - Si la comparación de nombres debe ser 'contiene'.
   * @author Juan Corral
   */
  public renombrarColumna(
    columna: string,
    nuevoNombre: string,
    contiene: boolean = false,
  ): void {
    const indice = this.indiceColumna(columna, contiene);
    const nombre = this._columnas[indice];
    this._columnas[indice] = nuevoNombre;
    for (const fila of this._filas) {
      const valor = fila[nombre];
      delete fila[nombre];
      fila[nuevoNombre] = valor;
    }
  }

  /**
   * Devuelve la fila especificada.
   * @param {number} indice - El índice de la fila a obtener.
   * @returns {FilaSpreadsheet} - La fila especificada.
   * @author Juan Corral
   */
  public obtenerFila(indice: number): FilaSpreadsheet {
    if (indice < 0 || indice >= this._filas.length)
      throw new ErrorDesarrollo('El índice de la fila no es válido');
    return this._filas[indice];
  }

  /**
   * Descarga la hoja de cálculo como archivo CSV.
   * @author Juan Corral
   */
  public descargarCSV(): void {
    // Formatear datos
    const csv = this._filas.map((fila) =>
      Object.values(fila)
        .map((texto) => `"${texto}"`)
        .join(','),
    );
    const encabezado = this._columnas.join(',');
    csv.unshift(encabezado);

    // Crear archivo
    var blob = new Blob(['\uFEFF' + csv.join('\r\n')], {
      type: 'text/csv;charset=utf-8;',
    });
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.nombre + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Crea un spreadsheet a partir de un archivo XLSX o CSV.
   * @param {File} archivo - El archivo a leer.
   * @param {string | null} nombre [Opcional] - El nombre del archivo.
   * @param {number} hoja [Opcional] - El índice de la hoja de cálculo donde están los datos.
   * @param {number} encabezado [Opcional] - El índice de la fila donde están los encabezados.
   * @returns {Promise<Spreadsheet>} - La hoja de cálculo creada (promise).
   * @author Juan Corral
   */
  public static async fromArchivo$(
    archivo: File,
    nombre: string | null = null,
    hoja: number = 0,
    encabezado: number = 0,
  ): Promise<Spreadsheet> {
    let datos: string = '';

    // Extraer datos
    if (archivo.type === TiposArchivoSpreadsheet.CSV) {
      datos = await this._leerCSV$(archivo);
    } else if (archivo.type === TiposArchivoSpreadsheet.EXCEL) {
      datos = await this._leerXLSX$(archivo, hoja);
    } else {
      throw new ErrorDesarrollo('El archivo debe estar en formato CSV o XLSX');
    }

    // Formatear cells con ""
    datos = datos.replace(/"[^"]*(?:""[^"]*)*"/g, (m) => m.replace(/\n/g, '')); // Remover \n
    datos = datos.replace(/"[^"]*(?:""[^"]*)*"/g, (m) => m.replace(/,/g, '.')); // Cambiar , por .
    datos = datos.replace(/"[^"]*(?:""[^"]*)*"/g, (m) => m.replace(/"/g, '')); // Remover ""

    // Separar lineas
    const lineas = datos.split(/\r?\n/);

    // Columnas y filas
    const columnas = lineas[encabezado].split(',');
    const filas: DatosSpreadsheet = [];

    // Procesar las filas del archivo
    for (let i = encabezado + 1; i < lineas.length; i++) {
      const linea = lineas[i].split(',');

      // Ignorar si la fila está vacía
      if (linea.every((e) => e == '' || e == undefined)) continue;

      // Crear fila
      const fila: FilaSpreadsheet = {};
      for (let j = 0; j < linea.length; j++) {
        fila[columnas[j]] = formatearUTF8(linea[j]).trim();
      }
      filas.push(fila);
    }

    return new Spreadsheet(nombre ?? archivo.name, filas);
  }

  /**
   * Extrae los datos del archivo CSV.
   * @param {File} archivo - El archivo a leer
   * @returns {Promise<string>} - Los datos del archivo (promise)
   * @author Juan Corral
   */
  private static async _leerCSV$(archivo: File): Promise<string> {
    return await new Promise<string>((resolve) => {
      const lector = new FileReader();
      lector.onload = () => {
        const datos = lector.result;
        if (datos !== null && typeof datos === 'string') resolve(datos);
      };
      lector.readAsText(archivo);
    });
  }

  /**
   * Extrae los datos del archivo XLSX.
   * @param {File} archivo - El archivo a leer
   * @param {number} hoja - El índice de la hoja de cálculo donde están los datos
   * @returns {Promise<string>} - Los datos del archivo (promise)
   * @author Juan Corral
   */
  private static async _leerXLSX$(
    archivo: File,
    hoja: number,
  ): Promise<string> {
    return await new Promise<string>((resolve) => {
      const lector = new FileReader();
      lector.onload = () => {
        const wb = read(lector.result);
        const sheets = wb.SheetNames;
        if (sheets.length) resolve(utils.sheet_to_csv(wb.Sheets[sheets[hoja]]));
      };
      lector.readAsArrayBuffer(archivo);
    });
  }
}

/* Tipos de hoja de calculo */
enum TiposArchivoSpreadsheet {
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
}

/* Fila del spreadsheet */
export interface FilaSpreadsheet {
  [columna: string]: string;
}

/* Datos del spreadsheet */
export type DatosSpreadsheet = FilaSpreadsheet[];
