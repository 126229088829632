import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CONFIGURACION_FICHA_APROBAR_ORDEN } from './utilidades/aprobar-orden.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { OrdenesService } from '../../utilidades/ordenes.service';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Orden } from '../../utilidades/orden.class';
import { firstValueFrom, map } from 'rxjs';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { EstadoOrden } from '../../utilidades/ordenes.models';

@Component({
  selector: 'orden-aprobar-orden',
  templateUrl: './aprobar-orden.component.html',
  styleUrls: ['./aprobar-orden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AprobarOrdenComponent implements OnInit {
  /* ID de la orden */
  @Input() public idOrden: number | undefined;

  /* Emite si la orden se ha aprobado */
  @Output() protected readonly aprobada = new EventEmitter<boolean | null>();

  /* Orden */
  protected orden: Orden | undefined;

  /* Configuración ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_APROBAR_ORDEN);

  /* Constantes */
  protected readonly EstadoOrden = EstadoOrden;

  /* Motivo de aprobación o rechazo */
  protected motivo: string | undefined;

  constructor(
    private readonly _alertasService: AlertasService,
    private readonly _ordenesService: OrdenesService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Obtener la orden
    firstValueFrom<number>(
      this._route.params.pipe(map((params: Params) => parseInt(params['id']))),
    ).then((id: number | undefined) => {
      const idOrden = this.idOrden ?? id;
      this._ordenesService
        .obtenerOrden$(idOrden!)
        .then((orden: Orden | undefined) => {
          this.orden = orden;
          if (this.orden === undefined)
            throw new ErrorFrontend('No se encontró la orden');
          this._cdr.markForCheck();
        });
    });
  }

  /**
   * Aprueba o rechaza la orden.
   * @param {boolean} aprobar - Indica si se aprueba o rechaza la orden.
   * @author Juan Corral
   */
  protected aprobarOrden(aprobar: boolean): void {
    this._ordenesService
      .aprobarOrden$(this.orden!.id, aprobar, this.motivo)
      .then(() => {
        this._alertasService.alertarExito(
          `Orden ${aprobar ? 'aprobada' : 'rechazada'} con éxito`,
        );
        this.aprobada.emit(aprobar);
        if (!this.idOrden) this._router.navigate(['ordenes']);
      })
      .catch((error: ErrorFrontend) => {
        this._alertasService.alertarError(error.message);
        this.aprobada.emit(null);
        this._cdr.markForCheck();
      });
  }
}
