<mat-toolbar role="heading" color="primary" class="header">
  <div class="seccion">
    <button mat-icon-button (click)="clickBotonSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="logo" routerLink="/">
      <img src="assets/logo/atlas-light.png" />
      <img class="organizacion" *ngIf="logoOrg" [src]="logoOrg" />
    </a>
  </div>
  <div class="seccion">
    <ng-container
      *ngIf="notificacionesService.notificaciones$ | async as notificaciones"
    >
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon
          [matBadge]="notificaciones.length"
          [matBadgeHidden]="notificaciones.length == 0"
          matBadgeColor="warn"
          aria-hidden="false"
        >
          notifications
        </mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="notificaciones">
        <button mat-menu-item *ngIf="notificaciones.length == 0">
          <mat-icon>notifications_paused</mat-icon>
          <span>No hay notificaciones</span>
        </button>
        <button
          mat-menu-item
          *ngFor="let notificacion of notificaciones"
          [routerLink]="notificacion.pagina || null"
        >
          <mat-icon>{{ notificacion.icono }}</mat-icon>
          <span>{{ notificacion.mensaje }}</span>
        </button>
      </mat-menu>
    </ng-container>
    <button mat-button (click)="autenticacionSevice.logout$()">
      <span>Cerrar Sesión</span>
    </button>
  </div>
</mat-toolbar>
