import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrdenesService } from '../../utilidades/ordenes.service';
import {
  CONFIGURACION_FICHA_PREPARAR_ORDENES,
  CONFIGURACION_FORMULARIO_PREPARAR_ORDENES,
  CONFIGURACION_TABLA_ETIQUETAS,
  CONFIGURACION_TABLA_PREPARAR_ORDENES,
} from './utilidades/preparar-ordenes.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { Subject, Subscription } from 'rxjs';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';
import {
  OPCIONES_FARMACIAS_EMCO_SALUD,
  OPCIONES_FARMACIAS_VIDAMEDICAL,
} from '../importar-ordenes/utilidades/importar-ordenes.static';
import { SourceTablaOrdenes } from '../tabla-ordenes/utilidades/source-tabla-ordenes.class';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { SourceTablaDomicilios } from 'src/app/domicilios/paginas/tabla-domicilios/utilidades/source-tabla-domicilios.class';
import { DomiciliosService } from 'src/app/domicilios/utilidades/domicilios.service';
import { EstadoOrden } from '../../utilidades/ordenes.models';
import {
  EstadoSeleccion,
  SeleccionAsincrona,
} from 'src/app/compartido/tabla-asincrona/utilidades/tabla-asincrona.models';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { PrepararOrdenComponent } from '../preparar-orden/preparar-orden.component';

@Component({
  selector: 'ordenes-preparar-ordenes',
  templateUrl: './preparar-ordenes.component.html',
  styleUrls: ['./preparar-ordenes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrepararOrdenesComponent implements OnInit, OnDestroy {
  /* Suscripciones */
  private readonly _suscripcion = new Subscription();

  constructor(
    private readonly _ordenesService: OrdenesService,
    private readonly _domiciliosService: DomiciliosService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    private readonly _cdr: ChangeDetectorRef,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSourceOrdenes = new SourceTablaOrdenes(_ordenesService);
    this.dataSourceEtiquetas = new SourceTablaDomicilios(_domiciliosService);
  }

  /* Configuración de la ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_PREPARAR_ORDENES);

  /* Formulario */
  protected configFormulario: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_PREPARAR_ORDENES,
  );
  protected valorFormulario: ValoresFormulario | null = null;
  protected validezFormulario: boolean = false;

  /* Tabla de Órdenes */
  protected configTablaOrdenes = deepCopy(CONFIGURACION_TABLA_PREPARAR_ORDENES);
  protected dataSourceOrdenes: SourceTablaOrdenes;
  @ViewChild('tablaOrdenes') tablaOrdenes!: TablaAsincronaComponent;

  /* Órdenes preparadas */
  protected preparadas: number[] = [];

  /* Tabla de Etiquetas */
  protected configTablaEtiquetas = deepCopy(CONFIGURACION_TABLA_ETIQUETAS);
  protected dataSourceEtiquetas: SourceTablaDomicilios;
  @ViewChild('tablaEtiquetas') tablaEtiquetas!: TablaAsincronaComponent;

  /* Selección de etiquetas */
  protected seleccionEtiquetas: SeleccionAsincrona = {
    estado: EstadoSeleccion.VACIA,
    seleccion: [],
    total: 0,
  };
  protected EstadoSeleccion = EstadoSeleccion;

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    campoOrg.valorOut = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.nombre,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].nombre);
          campoOrg.readonly = true;
        }
      });

    // Mostrar campo de farmacia para la organizaciones Vidamedical y Emco Salud
    const campoFarmacia = obtenerCampo(this.configFormulario, 'farmacia')!;
    const escucharOrg = campoOrg.valorOut.subscribe((valor) => {
      campoFarmacia.oculto = valor !== 'Vidamedical' && valor !== 'Emco Salud';
      if (campoFarmacia.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
        throw new ErrorDesarrollo(
          'El campo farmacia debe ser de tipo OPCION_MULTIPLE',
        );
      campoFarmacia.opciones =
        valor === 'Vidamedical'
          ? OPCIONES_FARMACIAS_VIDAMEDICAL
          : valor === 'Emco Salud'
            ? OPCIONES_FARMACIAS_EMCO_SALUD
            : [];
    });
    this._suscripcion.add(escucharOrg);
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Realiza la búsqueda de órdenes.
   * @autor Juan Corral
   */
  protected buscarOrdenes(): void {
    if (!this.validezFormulario) {
      this._alertasService.alertarError('Formulario inválido');
      return;
    }

    this.tablaOrdenes.valoresFiltros = {
      organizacion: this.valorFormulario!['organizacion'],
      desde: this.valorFormulario!['fecha'],
      hasta: this.valorFormulario!['fecha'],
      estado: EstadoOrden.CONFIRMADA,
    };

    const orden = this.tablaOrdenes.sort.active
      ? (this.tablaOrdenes.sort.direction == 'asc' ? '' : '-') +
        this.tablaOrdenes.sort.active
      : '';

    this.dataSourceOrdenes.obtenerDatos(
      orden,
      this.tablaOrdenes.paginator.pageIndex *
        this.tablaOrdenes.paginator.pageSize,
      this.tablaOrdenes.paginator.pageSize,
      this.tablaOrdenes.valoresFiltros,
    );
  }

  /**
   * Abre el dialogo de preparar orden.
   * @param {number} id - El id de la orden a preparar.
   * @autor Juan Corral
   */
  protected async prepararOrden$(id: number): Promise<void> {
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: PrepararOrdenComponent,
      inputs: { idOrden: id },
      output: 'preparada',
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    const preparada = await this._dialogosService.abrirVistaPrevia$(config);
    if (preparada) {
      this.preparadas.push(id);
      this.buscarOrdenes();
    }
  }

  /**
   * Realiza la búsqueda de los domicilios de las órdenes preparadas.
   * @autor Juan Corral
   */
  protected async buscarDomicilios$(): Promise<void> {
    if (this.preparadas.length === 0) return;

    const ordenes = await this._ordenesService.obtenerOrdenes$({
      ids: this.preparadas.length > 0 ? this.preparadas.join(',') : '',
    });

    const domicilios = ordenes.results
      .map((orden) => orden.domicilio?.id ?? null)
      .filter((domicilio) => domicilio !== null);

    this.tablaEtiquetas.valoresFiltros = { ids: domicilios.join(',') };

    const orden = this.tablaEtiquetas.sort.active
      ? (this.tablaEtiquetas.sort.direction == 'asc' ? '' : '-') +
        this.tablaEtiquetas.sort.active
      : '';

    this.dataSourceEtiquetas.obtenerDatos(
      orden,
      this.tablaEtiquetas.paginator.pageIndex *
        this.tablaEtiquetas.paginator.pageSize,
      this.tablaEtiquetas.paginator.pageSize,
      this.tablaEtiquetas.valoresFiltros,
    );
  }

  /**
   * Genera las etiquetas de las órdenes seleccionadas.
   * @autor Juan Corral
   */
  protected generarEtiquetas(): void {
    const estado = this.seleccionEtiquetas.estado;
    const cantidad =
      estado === EstadoSeleccion.COMPLETA
        ? this.dataSourceEtiquetas.total
        : estado === EstadoSeleccion.SELECCIONANDO
          ? this.seleccionEtiquetas.seleccion.length
          : estado === EstadoSeleccion.DESELECCIONANDO
            ? this.seleccionEtiquetas.total -
              this.seleccionEtiquetas.seleccion.length
            : 0;
    const s = cantidad > 1 ? 's' : '';

    this._dialogosService
      .abrirDialogo$(
        `¿Generar ${cantidad} etiqueta${s}?`,
        `Enviaremos un archivo PDF con la${s} etiqueta${s} al correo registrado en tu perfil.`,
        'Confirmar',
        'Cancelar',
      )
      .then((confirmado: boolean) => {
        if (!confirmado) return;

        this._alertasService.alertar(
          `Enviando la${s} etiqueta${s}, esto puede tardar unos segundos...`,
        );
        const filtros = { ...this.tablaEtiquetas.valoresFiltros };
        if (estado === EstadoSeleccion.SELECCIONANDO) {
          filtros['ids'] = this.seleccionEtiquetas.seleccion.join(',');
        } else if (estado === EstadoSeleccion.DESELECCIONANDO) {
          filtros['excluir'] = this.seleccionEtiquetas.seleccion.join(',');
        }
        this._domiciliosService
          .descargarEtiquetas$(filtros)
          .then(() => {
            this._alertasService.alertarExito(
              'El archivo ha sido enviado a tu correo',
            );
            this.seleccionEtiquetas.estado = EstadoSeleccion.VACIA;
            this._cdr.markForCheck();
          })
          .catch((error: ErrorFrontend) =>
            this._alertasService.alertarError('Error: ' + error.message),
          );
      });
  }
}
