import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom } from 'rxjs';
import { Permiso, PermisosDTO, RolUsuario } from './autorizacion.models';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class AutorizacionService {
  /* Permisos Usuario */
  private _permisos: PermisosDTO | undefined;
  private _permisosPromise$: Promise<PermisosDTO> | undefined;
  public get permisos$(): Promise<PermisosDTO> {
    if (this._permisosPromise$) return this._permisosPromise$;
    if (this._permisos) return Promise.resolve(this._permisos);
    this._permisosPromise$ = this._obtenerPermisos$().then(
      (permisos: PermisosDTO) => {
        this._permisos = permisos;
        this._permisosPromise$ = undefined;
        return permisos;
      },
    );
    return this._permisosPromise$;
  }

  constructor(private readonly _http: HttpClient) {}

  /**
   * Obtiene los permisos del usuario del servidor
   * @returns {Promise<PermisosDTO>} - Los permisos obtenidos (promise)
   * @author Juan Corral
   */
  private async _obtenerPermisos$(): Promise<PermisosDTO> {
    return firstValueFrom(
      this._http
        .get<PermisosDTO>('autorizacion/permisos/')
        .pipe(
          catchError(async () => ({ permisos: {}, rol: RolUsuario.CLIENTE })),
        ),
    );
  }

  /**
   * Devuelve si el usuario tiene todos los permisos sobre el objeto
   * @param {string} objeto - Objeto sobre el que se quiere verificar los permisos
   * @param {Permiso[]} permisos - Permisos que se quieren verificar
   * @returns {boolean} - Si el usuario tiene todos los permisos sobre el objeto
   * @autor Juan Corral
   */
  public async tienePermisos$(
    objeto: string,
    permisos: Permiso[],
  ): Promise<boolean> {
    for (const permiso of permisos) {
      if (!(await this.tienePermiso$(objeto, permiso))) return false;
    }
    return true;
  }

  /**
   * Devuelve si el usuario tiene el permiso sobre el objeto
   * @param {string} objeto - Objeto sobre el que se quiere verificar el permiso
   * @param {Permiso} permiso - Permiso que se quiere verificar
   * @returns {Promise<boolean>} - Si el usuario tiene el permiso sobre el objeto (promise)
   * @autor Juan Corral
   */
  public async tienePermiso$(
    objeto: string,
    permiso: Permiso,
  ): Promise<boolean> {
    const permisos = await this.permisos$.then((permisos) => permisos.permisos);
    if (permisos[objeto] !== undefined && permisos[objeto].includes(permiso))
      return true;
    return false;
  }

  /**
   * Devuelve si el usuario tiene el rol
   * @param {RolUsuario[]} roles - Roles que se quieren verificar
   * @returns {Promise<boolean>} - Si el usuario tiene el rol (promise)
   * @autor Juan Corral
   */
  public async tieneRol$(roles: RolUsuario[]): Promise<boolean> {
    const rol = await this.permisos$.then((permisos) => permisos.rol);
    return roles.includes(rol);
  }

  /**
   * Limpia los permisos del usuario
   * @autor Juan Corral
   */
  public limpiarPermisos(): void {
    this._permisos = undefined;
    this._permisosPromise$ = undefined;
  }
}
