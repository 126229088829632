import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CONFIGURACION_TABLA_SMS } from './utilidades/tabla-sms.static';
import { Router } from '@angular/router';
import { ComunicacionesService } from '../../utilidades/comunicaciones.service';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { SourceTablaSMS } from './utilidades/source-tabla-sms.class';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { obtenerFiltro } from 'src/app/compartido/tabla/utilidades/tabla.utils.models';

@Component({
  templateUrl: './tabla-sms.component.html',
  styleUrls: ['./tabla-sms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaSMSComponent implements OnInit {
  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_SMS);

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaSMS;

  constructor(
    private readonly _comunicacionesService: ComunicacionesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _router: Router,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaSMS(_comunicacionesService);
  }

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerFiltro(this.configTabla, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = [
          { nombre: 'Todos', valor: '' },
          ...organizaciones.map((org) => ({
            nombre: org.nombre,
            valor: org.nombre,
          })),
        ];
        this._cdr.markForCheck();
      });
  }

  /**
   * Realiza la acción de un botón.
   * @param {string} boton - El botón seleccionado.
   * @autor Juan Corral
   */
  protected clickBoton(boton: string): void {
    if (boton === 'enviar')
      this._router.navigate(['comunicaciones', 'enviar-sms']);
    else throw new ErrorDesarrollo('Botón no soportado');
  }
}
