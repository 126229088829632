import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

export type Medicamento = {
  producto: string;
  descripcionatc: string;
};

@Injectable({ providedIn: 'root' })
export class MedicamentosService {
  /* URL del API */
  private readonly API_URL = 'https://www.datos.gov.co/resource/i7cb-raxc.json';

  /* Token de la aplicación */
  private readonly APP_TOKEN = 'ImwSsfm2dCSmqb4T80t6wRppf';

  constructor(private readonly _http: HttpClient) {}

  /**
   * Busca medicamentos en el API de datos.
   * @param {string} busqueda - Texto de búsqueda.
   * @param {number} limite - Límite de resultados.
   * @returns {Promise<Medicamento[]>} - Promesa con la respuesta del API.
   * @author Juan Corral
   */
  public buscarMedicamentos$(
    busqueda: string,
    limite: number = 100,
  ): Promise<Medicamento[]> {
    const selectSoQL = `DISTINCT producto, descripcionatc`;
    const whereSoQL = `producto like '%${busqueda}%' OR descripcionatc like '%${busqueda}%'`;
    const params = new HttpParams()
      .set('$select', selectSoQL)
      .set('$where', whereSoQL)
      .set('$limit', limite);
    const headers = new HttpHeaders().set('X-App-Token', this.APP_TOKEN);
    return firstValueFrom(
      this._http.get<Medicamento[]>(this.API_URL, { params, headers }),
    );
  }
}
