import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaquetesService } from '../../utilidades/paquetes.service';
import { CONFIGURACION_TABLA_PAQUETES } from './utilidades/tabla-paquetes.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { SourceTablaPaquetes } from './utilidades/source-tabla-paquetes.class';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerFiltro } from 'src/app/compartido/tabla/utilidades/tabla.utils.models';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { MAX_PAQUETES_DESCARGA } from '../../utilidades/paquetes.static';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
@Component({
  templateUrl: './tabla-paquetes.component.html',
  styleUrls: ['./tabla-paquetes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaPaquetesComponent implements OnInit {
  /* Referencia a la tabla */
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  constructor(
    private readonly _paquetesService: PaquetesService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _organizacionService: OrganizacionService,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaPaquetes(_paquetesService);
  }

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerFiltro(this.configTabla, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = [
          { nombre: 'Todos', valor: '' },
          ...organizaciones.map((org) => ({
            nombre: org.nombre,
            valor: org.nombre,
          })),
        ];
        this._cdr.markForCheck();
      });
  }

  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_PAQUETES);

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaPaquetes;

  /**
   * Realiza la acción de un botón.
   * @param {string} boton - El botón seleccionado.
   * @autor Juan Corral
   */
  protected clickBoton(boton: string): void {
    if (boton === 'descargar') this._descargarPaquetes();
    else if (boton === 'agregar')
      this._router.navigate(['paquetes', 'agregar']);
    else throw new ErrorDesarrollo('Botón no soportado');
  }

  /**
   * Descarga los paquetes en un archivo CSV.
   * @author Juan Corral
   */
  private _descargarPaquetes(): void {
    const cantidad = this.dataSource.total;
    const s = cantidad > 1 ? 's' : '';
    const los = cantidad > 1 ? 'los' : 'el';

    if (cantidad === 0) {
      this._alertasService.alertarError('No hay paquetes para descargar');
      return;
    } else if (cantidad > MAX_PAQUETES_DESCARGA) {
      this._alertasService.alertarError(
        `No puedes descargar más de ${MAX_PAQUETES_DESCARGA} paquetes a la vez`,
      );
      return;
    }

    this._dialogosService
      .abrirDialogo$(
        `¿Descargar ${cantidad} paquete${s}?`,
        `Enviaremos un archivo CSV con ${los} paquete${s} al correo registrado en tu perfil.`,
        'Confirmar',
        'Cancelar',
      )
      .then((confirmado: boolean) => {
        if (!confirmado) return;

        this._alertasService.alertar(
          `Enviando ${los} paquete${s}, esto puede tardar unos segundos...`,
        );
        this._paquetesService
          .descargarPaquetes$(this.tabla.valoresFiltros)
          .then(() =>
            this._alertasService.alertarExito(
              'El archivo ha sido enviado a tu correo',
            ),
          )
          .catch((error: ErrorFrontend) =>
            this._alertasService.alertarError('Error: ' + error.message),
          );
      });
  }
}
