import { Pipe, PipeTransform } from '@angular/core';
import { DatosTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { FilaOrden } from './fila-orden.class';

/**
 * Formatea las instances de FilaOrden para la tabla.
 * @param {FilaOrden[]} filas - Las filas a formatear.
 * @return {DatosTabla} - Las filas formateadas para la tabla.
 * @author Juan Corral
 */
@Pipe({ name: 'filaOrdenTabla', pure: true })
export class FilaOrdenTablaPipe implements PipeTransform {
  transform(filas: FilaOrden[]): DatosTabla {
    return filas.map((fila: FilaOrden) => ({
      referencia: fila.datos.referencia,
      cliente: fila.datos.cliente.nombre + ' - ' + fila.datos.cliente.cedula,
      direccion: fila.datos.direccion.direccion_formateada,
      detalles: fila.datos.direccion.detalles,
      fecha_planeada: fila.datos.fecha_planeada,
    }));
  }
}
