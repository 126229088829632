import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { ClienteDTO } from './clientes.models';
import {
  Parametros,
  ListaDTO,
} from '../../compartido/utilidades/compartido.models';
import { deepCopy } from '../../compartido/utilidades/objetos.utils';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Cliente } from './cliente.class';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class ClientesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Crea el cliente en el servidor
   * @param {ClienteDTO} cliente - El cliente a crear
   * @returns {Promise<Cliente>} - El cliente creado (promise)
   * @throws {ErrorFrontend} - Si hubo un error al crear el cliente
   * @author Juan Corral
   */
  public crearCliente$(cliente: ClienteDTO): Promise<Cliente> {
    return firstValueFrom(
      this.http.post<ClienteDTO>('clientes/', cliente).pipe(
        map((cliente: ClienteDTO) => Cliente.fromDTO(cliente)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Actualiza el cliente en el servidor
   * @param {number} cedula - La cédula del cliente a actualizar
   * @param {ClienteDTO} cliente - El cliente con los datos actualizados
   * @returns {Promise<Cliente>} - El cliente actualizado (promise)
   * @throws {ErrorFrontend} - Si hubo un error al actualizar el cliente
   * @author Juan Corral
   */
  public actualizarCliente$(
    cedula: number,
    cliente: ClienteDTO,
  ): Promise<Cliente> {
    const datos: any = deepCopy(cliente);
    datos.organizacion = cliente.organizacion.id;
    return firstValueFrom(
      this.http.patch<ClienteDTO>('clientes/' + cedula, datos).pipe(
        map((cliente: ClienteDTO) => Cliente.fromDTO(cliente)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Obtiene información del cliente del servidor
   * @param {number} cedula - La cédula del cliente a obtener
   * @returns {Promise<Cliente | undefined>} - El cliente del servidor o undefined si hubo un error (promise)
   * @author Juan Corral
   */
  public async obtenerCliente$(cedula: number): Promise<Cliente | undefined> {
    return firstValueFrom(
      this.http.get('clientes/' + cedula).pipe(
        map((cliente: any) => Cliente.fromDTO(cliente)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Obtiene los clientes del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<ClienteDTO>>} - Los clientes obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener los clientes.
   * @author Juan Corral
   */
  public obtenerClientes$(params: Parametros): Promise<ListaDTO<ClienteDTO>> {
    return firstValueFrom(
      this.http.get<ListaDTO<ClienteDTO>>('clientes/', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }
}
