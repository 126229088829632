<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Formulario -->
    <mat-step [completed]="validezFormulario" [editable]="false">
      <ng-template matStepLabel>Configuración</ng-template>

      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="escucharCambiosFormulario($event)"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        [disabled]="!validezFormulario"
        (click)="enviarSms()"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Resultados -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Resultados</ng-template>

      <ng-container *ngIf="cargando">
        <mat-spinner class="cargando"> </mat-spinner>
        <p class="cargando" *ngIf="cargando">Creando Mensajes...</p>
      </ng-container>

      <div *ngIf="!cargando">
        <div *ngIf="creados.total == 0">
          <p class="texto color-warn">
            No se han encontrado domicilios para los cuales crear los mensajes
            sms.
          </p>
        </div>

        <div *ngIf="creados.exitosos > 0">
          <p class="texto">
            {{ creados.exitosos }} mensajes han sido creados exitosamente.
          </p>
        </div>

        <div class="texto color-warn" *ngIf="creados.fallidos > 0">
          <p>
            Hubo errores al intentar crear mensajes sms para
            {{ creados.fallidos }} domicilios.
          </p>
          <ul>
            <ng-container *ngFor="let intento of creados.resultado">
              <li *ngIf="intento.error">
                Domicilio {{ intento.id }}:
                <span>{{ intento.error }}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        routerLink="/comunicaciones/sms"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
