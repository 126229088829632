import { SourceTablaAsincrona } from 'src/app/compartido/tabla-asincrona/utilidades/source-tabla-asincrona.class';
import { DatosTablaAsincrona } from 'src/app/compartido/tabla-asincrona/utilidades/tabla-asincrona.models';
import { Parametros } from 'src/app/compartido/utilidades/compartido.models';
import { Paquete } from 'src/app/paquetes/utilidades/paquete.class';
import { PaquetesService } from 'src/app/paquetes/utilidades/paquetes.service';

export class SourceTablaPaquetes extends SourceTablaAsincrona<Paquete> {
  constructor(private readonly _paquetesService: PaquetesService) {
    super(Paquete.fromDTO);
  }

  /**
   * Obtiene los datos para la tabla de mensajes sms del servidor.
   * @param {string} orden - Columna por la que se ordenaran los resultados.
   * @param {number} inicio - Index del record por el cual empezar.
   * @param {number} limite - Número de records a devolver.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @returns {Promise<DatosTablaAsincrona>} - Los datos del servidor (promise).
   * @author Juan Corral
   */
  protected async _obtenerDatos$(
    orden: string,
    inicio: number,
    limite: number,
    filtros?: Parametros,
  ): Promise<DatosTablaAsincrona> {
    const params: Parametros = {
      ordering: orden,
      offset: inicio,
      limit: limite,
    };
    if (filtros) {
      for (const filtro of Object.keys(filtros)) {
        params[filtro] = filtros[filtro];
      }
    }
    return this._paquetesService
      .obtenerPaquetes$(params)
      .then((respuesta) => this._formatearDatosTabla(respuesta));
  }
}
