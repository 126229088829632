import {
  ConfiguracionTabla,
  FiltroTabla,
  FiltroTablaAsincrona,
  FiltroTablaSincrona,
} from './tabla.models';

/**
 * Obtiene un filtro de una tabla a partir de su slug.
 * @param {ConfiguracionTabla | FiltroTabla[]} fuente - Fuente de los filtros.
 * @param {string} slugFiltro - Slug del filtro a obtener.
 * @returns {FiltroTabla | undefined} El filtro o undefined si no se encuentra.
 * @author Juan Corral
 */
export function obtenerFiltro(
  fuente: ConfiguracionTabla | FiltroTabla[],
  slugFiltro: string,
): FiltroTablaAsincrona | FiltroTablaSincrona | undefined {
  if (Array.isArray(fuente)) {
    return fuente.find((filtro) => filtro.slug === slugFiltro);
  }
  return fuente.filtros?.find((filtro) => filtro.slug === slugFiltro);
}
