import {
  Permiso,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionTablaAsincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { EstadoMensajeSMS } from 'src/app/comunicaciones/utilidades/comunicaciones.models';

/* Configuración de la tabla de mensajes SMS */
export const CONFIGURACION_TABLA_SMS: ConfiguracionTablaAsincrona = {
  columnas: [
    { slug: 'id', nombre: 'ID', ordenable: true },
    { slug: 'organizacion', nombre: 'Organizacion' },
    { slug: 'domicilio', nombre: 'Domiclio' },
    { slug: 'estado', nombre: 'Estado' },
    { slug: 'fecha_envio', nombre: 'Fecha Envío', ordenable: true },
    { slug: 'cliente', nombre: 'Cliente' },
    { slug: 'numero_destino', nombre: 'Número Destino' },
    // { slug: 'texto', nombre: 'Texto' },
    { slug: 'id_externo', nombre: 'Id Externo' },
  ],
  busqueda: true,
  filtros: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // Se poblará en el componente
      roles: [RolUsuario.ELEVA],
    },
    {
      nombre: 'Estado',
      slug: 'estado',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [
        { nombre: 'Todos', valor: '' },
        { nombre: 'Enviado', valor: EstadoMensajeSMS.ENVIADO },
        { nombre: 'En Cola', valor: EstadoMensajeSMS.COLA },
        { nombre: 'Fallido', valor: EstadoMensajeSMS.ERROR },
      ],
    },
    {
      nombre: 'Desde',
      slug: 'desde',
      tipo: TipoCampoFormulario.FECHA,
    },
    {
      nombre: 'Hasta',
      slug: 'hasta',
      tipo: TipoCampoFormulario.FECHA,
    },
  ],
  botones: [
    {
      nombre: 'Enviar',
      slug: 'enviar',
      icono: Icono.ENVIAR_SMS,
      autorizacion: {
        objeto: 'mensajesms',
        permisos: [Permiso.CREAR_MASIVO, Permiso.ENVIAR_MASIVO],
      },
    },
  ],
};
