import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnviarSMSComponent } from './paginas/enviar-sms/enviar-sms.component';
import { TablaSMSComponent } from './paginas/tabla-sms/tabla-sms.component';
import { Permiso } from '../nucleo/autorizacion/autorizacion.models';

export const routes: Routes = [
  {
    path: 'sms',
    component: TablaSMSComponent,
    title: 'SMS',
    data: {
      objeto: 'mensajesms',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'enviar-sms',
    component: EnviarSMSComponent,
    title: 'Enviar SMS',
    data: {
      objeto: 'mensajesms',
      permisos: [Permiso.CREAR_MASIVO, Permiso.ENVIAR_MASIVO],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComunicacionesRouting {}
