import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { NotificacionesService } from './notificaciones.service';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';

@Component({
  selector: 'nucleo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  /* Emisores */
  @Output() clickBotonSidenav: EventEmitter<undefined> =
    new EventEmitter<undefined>();

  /* Organización */
  protected logoOrg: string | undefined = undefined;

  constructor(
    protected readonly autenticacionSevice: AutenticacionService,
    protected readonly notificacionesService: NotificacionesService,
  ) {}

  ngOnInit(): void {
    // Obtiene el logo de la organización para los clientes
    this.autenticacionSevice.usuario$.then((usuario) => {
      if (!usuario || usuario.rol !== RolUsuario.CLIENTE || !usuario.cliente)
        return;
      this.logoOrg =
        'assets/organizaciones/' + usuario.cliente.organizacion.alias + '.png';
    });

    // Obtiene las notificaciones
    this.notificacionesService.actualizarNotificaciones();
  }
}
