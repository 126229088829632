import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  PermisosDirective,
  RolesDirective,
} from './autorizacion.directives';

@NgModule({
  declarations: [PermisosDirective, RolesDirective],
  imports: [CommonModule],
  exports: [PermisosDirective, RolesDirective],
})
export class AutorizacionModule {}
