import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { tap, merge, Subscription, firstValueFrom } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ConfiguracionTablaAsincrona,
  DatosTabla,
  FilaTabla,
  TipoColumnaTabla,
} from '../tabla/utilidades/tabla.models';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from '../formulario/utilidades/formulario.models';
import {
  deepCopy,
  removerAtributosIndefinidos,
} from '../utilidades/objetos.utils';
import { CONFIGURACION_FORMULARIO_FILTROS_TABLA } from '../tabla/utilidades/tabla.static';
import { SourceTablaAsincrona } from './utilidades/source-tabla-asincrona.class';
import {
  EstadoSeleccion,
  FormateableTabla,
  SeleccionAsincrona,
} from './utilidades/tabla-asincrona.models';
import { obtenerFiltro } from '../tabla/utilidades/tabla.utils.models';
import { ErrorDesarrollo } from '../utilidades/error.utils';

@Component({
  selector: 'compartido-tabla-asincrona',
  templateUrl: './tabla-asincrona.component.html',
  styleUrls: ['../tabla/tabla.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaAsincronaComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  /* Suscripción */
  private readonly _suscripcion = new Subscription();

  /* Tipos de columna */
  protected readonly TipoColumnaTabla = TipoColumnaTabla;

  /* Datos */
  @Input({ required: true })
  public dataSource!: SourceTablaAsincrona<FormateableTabla>;

  /* Configuración */
  @Input({ required: true }) configuracion!: ConfiguracionTablaAsincrona;

  /* Columnas */
  protected columnas: string[] = [];

  /* Herramientas */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  /* Selección */
  protected readonly EstadoSeleccion = EstadoSeleccion;
  protected seleccion = new SelectionModel<any[]>(true, []);
  protected estadoSeleccion: EstadoSeleccion = EstadoSeleccion.VACIA;
  protected get numSeleccionados(): number {
    if (this.estadoSeleccion === EstadoSeleccion.COMPLETA)
      return this.dataSource.total;
    if (this.estadoSeleccion === EstadoSeleccion.VACIA) return 0;
    if (this.estadoSeleccion === EstadoSeleccion.DESELECCIONANDO)
      return this.dataSource.total - this.seleccion.selected.length;
    return this.seleccion.selected.length;
  }

  /* Filtros */
  protected configFormularioFiltros: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_FILTROS_TABLA,
  );
  public valoresFiltros: Record<string, any> = {};

  /* Emisores de eventos */
  @Output() clickFila = new EventEmitter<FilaTabla>();
  @Output() seleccionados = new EventEmitter<SeleccionAsincrona>();
  @Output() clickAccion = new EventEmitter<{
    fila: FilaTabla;
    accion: string;
  }>();
  @Output() protected clickBoton: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // Escuchar cambios en el estado de carga
    const escucharCargando = this.dataSource.cargando$.subscribe(() =>
      this._cdr.markForCheck(),
    );
    this._suscripcion.add(escucharCargando);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configuracion']) {
      // Configuración de búsqueda y filtros
      const camposFiltros = this.configuracion.filtros;
      if (this.configuracion.busqueda) {
        const filtros = camposFiltros ?? [];
        if (!obtenerFiltro(filtros, 'search')) {
          filtros.unshift({
            nombre: 'Buscar',
            slug: 'search',
            tipo: TipoCampoFormulario.TEXTO,
          });
        }
        this.configFormularioFiltros.campos = filtros;
      } else if (camposFiltros !== undefined) {
        this.configFormularioFiltros.campos = camposFiltros;
      }

      // Columna de selección
      this.columnas = this.configuracion.columnas.map((col) => col.slug);
      if (this.configuracion.seleccion) this.columnas.unshift('seleccion');

      // Columna de acciones
      if (this.configuracion.acciones !== undefined)
        this.columnas.push('acciones');
    }
  }

  ngAfterViewInit() {
    // Resetear la paginación tras ordenar
    const escucharOrden = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this._suscripcion.add(escucharOrden);

    // Cargar nueva página cuando se ordena o se cambia de página
    const escucharPaginacion = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.cargarPagina()))
      .subscribe();
    this._suscripcion.add(escucharPaginacion);

    // Cambios en la selección
    const escucharSeleccion = this.seleccion.changed.subscribe(() => {
      this.seleccionados.next({
        estado: this.estadoSeleccion,
        seleccion: this.seleccion.selected,
        total: this.dataSource.total,
      });
    });
    this._suscripcion.add(escucharSeleccion);

    // Cargar la primera página
    this.cargarPagina();
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Carga la pagina seleccionada.
   * @author Juan Corral
   */
  public cargarPagina(): void {
    const orden = this.sort.active
      ? (this.sort.direction == 'asc' ? '' : '-') + this.sort.active
      : '';
    this.dataSource.obtenerDatos(
      orden,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.paginator.pageSize,
      this.valoresFiltros,
    );
  }

  /**
   * Aplica los filtros a los datos.
   * @param {any} valores - Los valores de los filtros.
   * @author Juan Corral
   */
  protected aplicarFiltros(valores: ValoresFormulario | undefined): void {
    if (valores === undefined) return;
    this.valoresFiltros = removerAtributosIndefinidos(valores);
    if (this.paginator === undefined || this.sort === undefined) return;
    this.paginator.pageIndex = 0;
    this.cargarPagina();
  }

  /**
   * Invierte el estado de selección completa.
   * @author Juan Corral
   */
  protected toggleSeleccionCompleta(): void {
    const slug = this.configuracion.seleccion;
    if (slug === undefined)
      throw new ErrorDesarrollo('No hay columna de selección');

    if (this.estadoSeleccion === EstadoSeleccion.COMPLETA) {
      this.estadoSeleccion = EstadoSeleccion.VACIA;
      this.seleccion.clear();
    } else {
      this.estadoSeleccion = EstadoSeleccion.COMPLETA;
      this.seleccion.clear();
      firstValueFrom(this.dataSource.datos$).then((datos) =>
        datos.forEach((row) => this.seleccion.select(row[slug])),
      );
    }
    this._cdr.markForCheck();
  }

  /**
   * Invierte el estado de selección de una fila.
   * @param {FilaTabla} fila - La fila cuya seleccion será invertida.
   * @author Juan Corral
   */
  protected toggleSeleccionFila(fila: FilaTabla): void {
    const slug = this.configuracion.seleccion;
    if (slug === undefined)
      throw new ErrorDesarrollo('No hay columna de selección');

    if (this.estadoSeleccion === EstadoSeleccion.COMPLETA) {
      this.estadoSeleccion = EstadoSeleccion.DESELECCIONANDO;
      this.seleccion.clear();
      this.seleccion.select(fila[slug]);
    } else if (this.estadoSeleccion === EstadoSeleccion.VACIA) {
      this.estadoSeleccion = EstadoSeleccion.SELECCIONANDO;
      this.seleccion.select(fila[slug]);
    } else {
      this.seleccion.toggle(fila[slug]);
    }

    if (this.seleccion.selected.length === 0) {
      if (this.estadoSeleccion === EstadoSeleccion.DESELECCIONANDO)
        this.estadoSeleccion = EstadoSeleccion.COMPLETA;
      else this.estadoSeleccion = EstadoSeleccion.VACIA;
      this.seleccion.clear();
    } else if (this.seleccion.selected.length === this.dataSource.total) {
      if (this.estadoSeleccion === EstadoSeleccion.DESELECCIONANDO)
        this.estadoSeleccion = EstadoSeleccion.VACIA;
      else this.estadoSeleccion = EstadoSeleccion.COMPLETA;
      this.seleccion.clear();
    }

    this._cdr.markForCheck();
  }

  /**
   * Devuelve si la fila está seleccionada.
   * @param {FilaTabla} fila - La fila a comprobar.
   * @returns {boolean} Si la fila está seleccionada o no. Si no hay columna de selección, devuelve false.
   * @author Juan Corral
   */
  protected filaSeleccionada(fila: FilaTabla): boolean {
    if (this.configuracion.seleccion === undefined) return false;

    if (this.estadoSeleccion === EstadoSeleccion.COMPLETA) return true;
    if (this.estadoSeleccion === EstadoSeleccion.VACIA) return false;
    if (this.estadoSeleccion === EstadoSeleccion.SELECCIONANDO)
      return this.seleccion.isSelected(fila[this.configuracion.seleccion]);
    return !this.seleccion.isSelected(fila[this.configuracion.seleccion]);
  }
}
