import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionTablaSincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';

/* Configuración de la ficha de importar órdenes */
export const CONFIGURACION_FICHA_IMPORTAR_ORDENES: ConfiguracionFicha = {
  header: { titulo: 'Importar Órdenes', icono: Icono.SUBIR },
  maxWidth: 800,
};

/* Configuración del formulario de subir archivo */
export const CONFIGURACION_FORMULARIO_ARCHIVO: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      requerido: true,
      opciones: [], // opciones se define dinámicamente en el componente
    },
    {
      nombre: 'Fecha planeada',
      slug: 'fecha',
      tipo: TipoCampoFormulario.FECHA,
      requerido: true,
      minimo: formatearFecha(Date.now()),
    },
    {
      nombre: 'Número de la hoja de cálculo',
      slug: 'hoja',
      tipo: TipoCampoFormulario.NUMERO,
      requerido: true,
      minimo: 1,
      valor: 2,
    },
    {
      nombre: 'Número de la fila con encabezados',
      slug: 'encabezados',
      tipo: TipoCampoFormulario.NUMERO,
      requerido: true,
      minimo: 1,
      valor: 1,
    },
    {
      nombre: 'Archivo',
      slug: 'archivo',
      tipo: TipoCampoFormulario.ARCHIVO,
      requerido: true,
      aceptados: ['.csv', '.xlsx'],
      placeholder: 'Seleccionar archivo',
    },
    {
      nombre: 'Farmacia',
      slug: 'farmacia',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      requerido: false,
      opciones: [], // opciones se define dinámicamente en el componente
      oculto: true,
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    esconderErrores: true,
  },
};

/* Configuración del formulario de formatear dirección */
export const CONFIGURACION_FORMULARIO_DIRECCION: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Dirección',
      slug: 'direccion',
      tipo: TipoCampoFormulario.AUTOCOMPLETE,
      requerido: true,
      valor: '',
      opciones: [], // opciones se define dinámicamente en el componente
    },
    {
      nombre: 'Detalles',
      slug: 'detalles',
      tipo: TipoCampoFormulario.AUTOCOMPLETE,
      valor: '',
      opciones: [], // opciones se define dinámicamente en el componente
    },
  ],
  estilo: { widthCampo: 250 },
};

/* Configuración de la tabla de órdenes a subir */
export const CONFIGURACION_TABLA_ORDENES_A_SUBIR: ConfiguracionTablaSincrona = {
  columnas: [
    { slug: 'referencia', nombre: 'N° Referencia' },
    { slug: 'cliente', nombre: 'Cliente' },
    { slug: 'direccion', nombre: 'Dirección' },
    { slug: 'detalles', nombre: 'Detalles' },
  ],
};

/* Configuración de la tabla de órdenes subidas */
export const CONFIGURACION_TABLA_ORDENES_SUBIDAS: ConfiguracionTablaSincrona = {
  ...CONFIGURACION_TABLA_ORDENES_A_SUBIR,
  busqueda: true,
  botones: [
    {
      nombre: 'Descargar',
      icono: Icono.DESCARGAR,
      slug: 'descargar',
    },
  ],
};

/* Opciones de farmacias de la organización Vidamedical */
export const OPCIONES_FARMACIAS_VIDAMEDICAL = [
  { valor: 'Castellana', nombre: 'Castellana' },
  { valor: 'Dolor', nombre: 'Dolor' },
  { valor: 'Calle 100', nombre: 'Calle 100' },
  { valor: 'Americas', nombre: 'Americas' },
  { valor: 'Bosque', nombre: 'Bosque' },
];

/* Opciones de farmacias de la organización Emco Salud */
export const OPCIONES_FARMACIAS_EMCO_SALUD = [
  { valor: 'Galerias', nombre: 'Galerias' },
  { valor: 'Country', nombre: 'Country' },
  { valor: 'Corferias', nombre: 'Corferias' },
];
