import { ConfiguracionRecurso, SeccionRecurso } from './recurso.models';

/**
 * Obtiene la sección de un recurso a partir de su slug.
 * @param {ConfiguracionRecurso} configuracion - Configuración del recurso.
 * @param {string} slugSeccion - Slug de la sección a obtener.
 * @returns {SeccionRecurso | undefined} La sección del recurso o undefined si no se encuentra.
 * @author Juan Corral
 */
export function obtenerSeccion(
  configuracion: ConfiguracionRecurso,
  slugSeccion: string,
): SeccionRecurso | undefined {
  return configuracion.secciones.find(
    (seccion) => seccion.slug === slugSeccion,
  );
}
