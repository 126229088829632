import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TablaDomiciliosComponent } from './paginas/tabla-domicilios/tabla-domicilios.component';
import { VerDomicilioComponent } from './paginas/ver-domicilio/ver-domicilio.component';
import { ErroresSimpliComponent } from './paginas/errores-simpli/errores-simpli.component';
import { Permiso } from '../nucleo/autorizacion/autorizacion.models';
import { DespacharDomiciliosComponent } from './paginas/despachar-domicilios/despachar-domicilios.component';
import { ReprogramarDomiciliosComponent } from './paginas/reprogramar-domicilios/reprogramar-domicilios.component';
import { ReprogramarDomicilioComponent } from './paginas/reprogramar-domicilio/reprogramar-domicilio.component';

export const routes: Routes = [
  {
    path: '',
    component: TablaDomiciliosComponent,
    title: 'Domicilios',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'errores-simpli',
    component: ErroresSimpliComponent,
    title: 'Errores SimpliRoute',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'despachar',
    component: DespacharDomiciliosComponent,
    title: 'Despachar Domicilios',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER_MASIVO, Permiso.VER, Permiso.DESPACHAR],
    },
  },
  {
    path: 'despachar/:id',
    component: DespacharDomiciliosComponent,
    title: 'Despachar Domicilio',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER, Permiso.DESPACHAR],
    },
  },
  {
    path: 'reprogramar',
    component: ReprogramarDomiciliosComponent,
    title: 'Re-programar Domicilios',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER_MASIVO, Permiso.VER, Permiso.REPROGRAMAR],
    },
  },
  {
    path: 'reprogramar/:id',
    component: ReprogramarDomicilioComponent,
    title: 'Re-programar Domicilio',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER, Permiso.REPROGRAMAR],
    },
  },
  {
    path: ':id',
    component: VerDomicilioComponent,
    title: 'Ver Domicilio',
    data: {
      objeto: 'domicilio',
      permisos: [Permiso.VER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DomiciliosRoutingModule {}
