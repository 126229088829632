import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TablaClientesComponent } from './paginas/tabla-clientes/tabla-clientes.component';
import { VerClienteComponent } from './paginas/ver-cliente/ver-cliente.component';
import { TablaVerificacionesIdentidadComponent } from './paginas/tabla-verificaciones-identidad/tabla-verificaciones-identidad.component';
import { VerificacionIdentidadComponent } from './paginas/verificacion-identidad/verificacion-identidad.component';
import { Permiso } from '../nucleo/autorizacion/autorizacion.models';

export const routes: Routes = [
  {
    path: '',
    component: TablaClientesComponent,
    title: 'Clientes',
    data: {
      objeto: 'cliente',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'verificaciones',
    component: TablaVerificacionesIdentidadComponent,
    title: 'Verificaciones de Identidad',
    data: {
      objeto: 'verificacionidentidad',
      permisos: [Permiso.VER_MASIVO, Permiso.APROBAR],
    },
  },
  {
    path: 'verificaciones/:id',
    component: VerificacionIdentidadComponent,
    title: 'Aprobar Identidad',
    data: {
      objeto: 'verificacionidentidad',
      permisos: [Permiso.VER, Permiso.APROBAR],
    },
  },
  {
    path: ':cedula',
    component: VerClienteComponent,
    title: 'Ver Cliente',
    data: {
      objeto: 'cliente',
      permisos: [Permiso.VER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientesRoutingModule {}
