<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Subir Archivo -->
    <mat-step
      [completed]="validezFormularioArchivo && lector.valido"
      [editable]="false"
    >
      <ng-template matStepLabel>Subir Archivo</ng-template>

      <compartido-formulario
        [configuracion]="configFormularioArchivo"
        (valores)="validarArchivo($event)"
        (valido)="validezFormularioArchivo = $event"
      ></compartido-formulario>

      <ng-container *ngIf="cargando">
        <mat-spinner class="cargando"> </mat-spinner>
        <p class="cargando" *ngIf="cargando">Validando archivo...</p>
      </ng-container>

      <ng-container *ngIf="!cargando && lector.filasIncompletas as filas">
        <div *ngIf="filas.length > 0" class="texto color-warn">
          <p>{{ lector.error }}</p>
          <ul>
            <li *ngFor="let fila of filas">
              Fila {{ fila.indice + 1 }}:
              <span
                *ngFor="let incompleta of fila.celdasIncompletas; let i = index"
              >
                {{ incompleta }}
                {{ i != fila.celdasIncompletas!.length - 1 ? ", " : "" }}
              </span>
            </li>
          </ul>
          <p>Por favor corrija los errores e intente de nuevo</p>
        </div>
      </ng-container>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        [disabled]="!validezFormularioArchivo || !lector.valido || cargando"
        (click)="formatearDirecciones()"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Formatear Direcciones -->
    <mat-step [completed]="validezFormateo" [editable]="false">
      <ng-template matStepLabel>Formatear Direcciones</ng-template>

      <ng-container *ngIf="cargando">
        <mat-spinner class="cargando"> </mat-spinner>
        <p class="cargando" *ngIf="cargando">Formateando direcciones...</p>
      </ng-container>

      <ng-container *ngIf="!cargando && lector.filasSinFormatear as filas">
        <p *ngIf="filas.length == 0" class="texto">
          ¡Todas las direcciones fueron formateadas correctamente!
        </p>

        <ng-container
          *ngIf="
            filas.length > 0 &&
            configsFormulariosDireccion.length == filas.length
          "
        >
          <p class="texto">
            El sistema no reconoció las siguientes ({{ filas.length }})
            direcciones, por favor formatee manualmente:
          </p>
          <div *ngFor="let fila of filas; let i = index" class="sin-formatear">
            <div class="label">
              <b>
                {{ fila.datos.cliente.nombre }}
                ({{ fila.datos.cliente.cedula }}):
              </b>
              <span>{{ fila.datos.direccion.direccion }}</span>
            </div>
            <compartido-formulario
              [configuracion]="configsFormulariosDireccion[i]"
              (valores)="valoresFormulariosDireccion[i] = $event"
              (valido)="validezFormulariosDireccion[i] = $event"
            ></compartido-formulario>
          </div>
        </ng-container>
      </ng-container>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="guardarFormateo()"
        [disabled]="!validezFormateo || cargando"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 3: Confirmar -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Confirmar</ng-template>

      <ng-container *ngIf="cargando">
        <mat-spinner class="cargando"> </mat-spinner>
        <p class="cargando" *ngIf="cargando">Guardando formateo...</p>
      </ng-container>

      <ng-container
        *ngIf="
          !cargando && lector.filas.length === lector.filasFormateadas.length
        "
      >
        <p class="texto">Se subirán las siguientes órdenes:</p>
        <compartido-tabla
          class="tabla-a-subir {{ responsiveService.claseCSS$ | async }}"
          [datos]="lector.filas | filaOrdenTabla"
          [configuracion]="CONFIGURACION_TABLA_ORDENES_A_SUBIR"
        ></compartido-tabla>
      </ng-container>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="subirOrdenes$()"
      >
        Subir <mat-icon>publish</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 4: Resultados -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Resultados</ng-template>

      <ng-container *ngIf="resultadoSubida | async as subidas">
        <ng-container *ngIf="cargando">
          <mat-spinner
            class="cargando"
            mode="determinate"
            [value]="(subidas.total * 100) / lector.filas.length"
          ></mat-spinner>
          <p class="cargando">Subiendo órdenes...</p>
          <p class="cargando">
            Progreso: {{ subidas.total }} / {{ lector.filas.length }}
          </p>
          <p class="cargando">Subidos: {{ subidas.exitosos }}</p>
          <p class="cargando">Errores: {{ subidas.fallidos }}</p>
        </ng-container>

        <div *ngIf="!cargando && subidas.total === lector.filas.length">
          <div *ngIf="subidas.exitosos > 0">
            <p class="texto">
              Las siguientes ({{ subidas.exitosos }}) órdenes fueron subidas
              con éxito:
            </p>
            <compartido-tabla
              class="tabla-subidas {{ responsiveService.claseCSS$ | async }}"
              [datos]="lector.filasSubidas | filaOrdenTabla"
              [configuracion]="CONFIGURACION_TABLA_ORDENES_SUBIDAS"
              (clickBoton)="lector.descargarSubidas()"
            ></compartido-tabla>
          </div>

          <div class="texto color-warn" *ngIf="subidas.fallidos > 0">
            <p>
              Hubo errores al intentar crear algunas ({{ subidas.fallidos }})
              órdenes.
            </p>
            <p>
              Un archivo con las filas afectadas ha sido descargado, por favor
              corrija los errores e intente de nuevo.
            </p>
          </div>
        </div>
      </ng-container>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        [disabled]="cargando"
        routerLink="/ordenes"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
