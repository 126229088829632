import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FichaComponent } from './ficha.component';

import { AutorizacionModule } from 'src/app/nucleo/autorizacion/autorizacion.module';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [FichaComponent],
  imports: [
    CommonModule,

    AutorizacionModule,

    MatToolbarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  exports: [FichaComponent],
})
export class FichaModule {}
