import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { OrganizacionDTO, DireccionDTO, ClienteDTO } from './clientes.models';
import { FormateableTabla } from 'src/app/compartido/tabla-asincrona/utilidades/tabla-asincrona.models';

/* Cliente */
export class Cliente implements FormateableTabla {
  constructor(
    public cedula: number,
    public nombre: string,
    public telefono: number | null | undefined,
    public correo: string | null | undefined,
    public organizacion: OrganizacionDTO,
    public direcciones: DireccionDTO[],
  ) {}

  /**
   * Devuelve los datos del cliente con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      cedula: this.cedula,
      nombre: this.nombre,
      telefono: this.telefono,
      correo: this.correo,
      organizacion: this.organizacion.nombre,
    };
  }

  /**
   * Crea un objeto Cliente a partir de los datos del API
   * @param {ClienteDTO} cliente - El cliente como llega del API
   * @returns {Cliente} - El objeto Cliente creado
   * @author Juan Corral
   */
  public static fromDTO(cliente: ClienteDTO): Cliente {
    return new Cliente(
      cliente.cedula,
      cliente.nombre,
      cliente.telefono,
      cliente.correo,
      cliente.organizacion,
      cliente.direcciones,
    );
  }
}
