import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfiguracionDialogoVistaPrevia } from './utilidades/dialogo-vista-previa.static';
import { Subscription } from 'rxjs';
import { ErrorDesarrollo } from '../../utilidades/error.utils';

@Component({
  selector: 'dialogos-vista-previa',
  templateUrl: './dialogo-vista-previa.component.html',
  styleUrls: ['./dialogo-vista-previa.component.scss'],
})
export class DialogoVistaPreviaComponent implements AfterViewInit, OnDestroy {
  /* Suscripción */
  private _suscripcion = new Subscription();

  @ViewChild('contenedor', { read: ViewContainerRef })
  contenedor!: ViewContainerRef;

  constructor(
    protected readonly dialogRef: MatDialogRef<DialogoVistaPreviaComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected readonly configuracion: ConfiguracionDialogoVistaPrevia,
  ) {}

  ngAfterViewInit() {
    // Limpia el contenedor y crea el componente dinámicamente
    this.contenedor.clear();
    const componenteRef = this.contenedor.createComponent(
      this.configuracion.componente,
    );

    // Asigna las propiedades @Input del componente
    const inputs = this.configuracion.inputs;
    if (inputs !== undefined)
      Object.keys(inputs).forEach(
        (key) => (componenteRef.instance[key] = inputs[key]),
      );

    // Asigna el @Output del componente
    const output = this.configuracion.output;
    if (output !== undefined) {
      if (!componenteRef.instance[output])
        throw new ErrorDesarrollo(
          'El output especificado no existe en el componente',
        );
      else if (!componenteRef.instance[output].subscribe)
        throw new ErrorDesarrollo('El output especificado no es subscribible');
      else
        this._suscripcion.add(
          componenteRef.instance[output].subscribe((valor: any) =>
            this.dialogRef.close(valor),
          ),
        );
    }
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Abre la URL en una nueva pestaña.
   * @autor Juan Corral
   */
  protected abrirUrl(): void {
    window.open(this.configuracion.url, '_blank');
  }
}
