import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfiguracionTablaAsincrona,
  FilaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import {
  CONFIGURACION_FICHA_TABLA_VERIFICACIONES_IDENTIDAD,
  CONFIGURACION_TABLA_VERIFICACIONES_IDENTIDAD,
} from './utilidades/tabla-verificaciones-identidad.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { SourceTablaVerificacionesIdentidad } from './utilidades/source-verificaciones-identidad.class';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { IngresoService } from 'src/app/ingreso/utilidades/ingreso.service';

@Component({
  selector: 'clientes-tabla-verificaciones-identidad',
  templateUrl: './tabla-verificaciones-identidad.component.html',
  styleUrls: ['./tabla-verificaciones-identidad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaVerificacionesIdentidadComponent {
  /* Configuración de la ficha */
  protected configFicha: ConfiguracionFicha = deepCopy(
    CONFIGURACION_FICHA_TABLA_VERIFICACIONES_IDENTIDAD,
  );

  /* Configuración de la tabla */
  protected configTabla: ConfiguracionTablaAsincrona = deepCopy(
    CONFIGURACION_TABLA_VERIFICACIONES_IDENTIDAD,
  );

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaVerificacionesIdentidad;

  constructor(
    private readonly _ingresoService: IngresoService,
    private readonly _router: Router,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaVerificacionesIdentidad(
      this._ingresoService,
    );
  }

  /**
   * Ejecuta una acción en la fila.
   * @param {FilaTabla} fila - La fila seleccionada.
   * @param {string} accion - La acción a ejecutar.
   * @autor Juan Corral
   */
  protected clickAccion(fila: FilaTabla, accion: string): void {
    const mapaAcciones: Record<string, string[]> = {
      aprobar: ['clientes', 'verificaciones', fila['id']],
    };
    const segments = mapaAcciones[accion];
    if (segments === undefined)
      throw new ErrorDesarrollo('Acción no soportada');
    const url = this._router.serializeUrl(this._router.createUrlTree(segments));
    window.open(url, '_blank');
  }
}
