import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { OrganizacionDTO } from 'src/app/clientes/utilidades/clientes.models';
import { OrdenesService } from '../../utilidades/ordenes.service';
import { ArchivoOrdenes } from './utilidades/archivo-ordenes/archivo-ordenes.class';
import {
  CONFIGURACION_FICHA_IMPORTAR_ORDENES,
  CONFIGURACION_FORMULARIO_ARCHIVO,
  CONFIGURACION_FORMULARIO_DIRECCION,
  CONFIGURACION_TABLA_ORDENES_A_SUBIR,
  CONFIGURACION_TABLA_ORDENES_SUBIDAS,
  OPCIONES_FARMACIAS_EMCO_SALUD,
  OPCIONES_FARMACIAS_VIDAMEDICAL,
} from './utilidades/importar-ordenes.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { stringVacío } from 'src/app/compartido/utilidades/valores.utils';
import {
  EstadoFilaOrden,
  ResultadoSubida,
} from './utilidades/archivo-ordenes/archivo-ordenes.models';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { FileInput } from 'ngx-custom-material-file-input';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';

@Component({
  selector: 'ordenes-importar-ordenes',
  templateUrl: './importar-ordenes.component.html',
  styleUrls: ['./importar-ordenes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportarOrdenesComponent implements OnInit, OnDestroy {
  /* Suscripciones */
  private readonly _suscripcion = new Subscription();

  constructor(
    private readonly _ordenesService: OrdenesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _alertasService: AlertasService,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  /* Configuración de la ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_IMPORTAR_ORDENES);

  /* Formulario Subir Archivo */
  protected configFormularioArchivo: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_ARCHIVO,
  );
  protected validezFormularioArchivo: boolean = false;

  /* Formularios Formatear Direcciones */
  protected configsFormulariosDireccion: ConfiguracionFormulario[] = [];
  protected valoresFormulariosDireccion: ValoresFormulario[] = [];
  protected validezFormulariosDireccion: boolean[] = [];
  private formularioCreado: boolean = false;
  protected get validezFormateo(): boolean {
    return (
      this.formularioCreado && this.validezFormulariosDireccion.every((v) => v)
    );
  }

  /* Lista de organizaciones */
  protected listaOrganizaciones: OrganizacionDTO[] = [];

  /* Lector del Archivo */
  protected lector: ArchivoOrdenes = new ArchivoOrdenes();

  /* Resultado de la subida de órdenes */
  protected resultadoSubida = new BehaviorSubject<ResultadoSubida>({
    exitosos: 0,
    fallidos: 0,
    total: 0,
  });

  /* Configuraciones tablas */
  protected readonly CONFIGURACION_TABLA_ORDENES_A_SUBIR = deepCopy(
    CONFIGURACION_TABLA_ORDENES_A_SUBIR,
  );
  protected readonly CONFIGURACION_TABLA_ORDENES_SUBIDAS = deepCopy(
    CONFIGURACION_TABLA_ORDENES_SUBIDAS,
  );

  /* Cargando */
  protected cargando: boolean = false;

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(
      this.configFormularioArchivo,
      'organizacion',
    )!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    campoOrg.valorOut = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        this.listaOrganizaciones = organizaciones;
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.id,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].id);
          campoOrg.readonly = true;
        }
      });

    // Mostrar campo de farmacia para la organizaciones Vidamedical y Emco Salud
    const campoFarmacia = obtenerCampo(
      this.configFormularioArchivo,
      'farmacia',
    )!;
    const escucharOrg = campoOrg.valorOut.subscribe((valor) => {
      const org = this.listaOrganizaciones.find((org) => org.id == valor);
      campoFarmacia.oculto =
        org?.nombre !== 'Vidamedical' && org?.nombre !== 'Emco Salud';
      if (campoFarmacia.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
        throw new ErrorDesarrollo(
          'El campo farmacia debe ser de tipo OPCION_MULTIPLE',
        );
      campoFarmacia.opciones =
        org?.nombre === 'Vidamedical'
          ? OPCIONES_FARMACIAS_VIDAMEDICAL
          : org?.nombre === 'Emco Salud'
            ? OPCIONES_FARMACIAS_EMCO_SALUD
            : [];
    });
    this._suscripcion.add(escucharOrg);
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Establece el estado de carga de la página.
   * @param {boolean} estado - El estado de carga.
   * @author Juan Corral
   */
  private _establecerCargando(estado: boolean): void {
    this.cargando = estado;
    this._cdr.markForCheck();
  }

  /**
   * Procesa y valida el archivo subido.
   * @param {Event} evento - El evento de cambio de archivo.
   * @author Juan Corral
   */
  protected validarArchivo(valores: ValoresFormulario): void {
    if (!this.validezFormularioArchivo) return;

    // Activar cargando
    this._establecerCargando(true);

    // Extraer datos formulario
    const datos = valores as {
      organizacion: number;
      fecha: string;
      archivo: FileInput;
      hoja: number;
      encabezados: number;
      farmacia?: string;
    };

    // Buscar organización
    const organizacion = this.listaOrganizaciones.find(
      (org) => org.id == datos.organizacion,
    );
    if (organizacion === undefined)
      throw new ErrorDesarrollo('La organización buscada no existe');

    // Procesar Archivo
    this.lector
      .establecerArchivo$(
        datos.archivo.files[0],
        organizacion,
        datos.fecha,
        datos.hoja - 1,
        datos.encabezados - 1,
        datos.farmacia,
      )
      .then(() => {
        // Validar Archivo
        if (!this.lector.valido)
          this._alertasService.alertarError(this.lector.error);
        // Desactivar cargando
        this._establecerCargando(false);
      });
  }

  /**
   * Formatea las direcciones de los borradores
   * @author Juan Corral
   */
  protected formatearDirecciones(): void {
    this._establecerCargando(true);
    this.lector.formatearDirecciones$().then(() => {
      this._crearFormulariosDireccion();
      this._establecerCargando(false);
    });
  }

  /**
   * Crea los formularios para las direcciones sin formatear
   * @author Juan Corral
   */
  private _crearFormulariosDireccion(): void {
    this.configsFormulariosDireccion = [];
    for (const fila of this.lector.filasSinFormatear) {
      const config = deepCopy(CONFIGURACION_FORMULARIO_DIRECCION);

      // Agregar opciones de dirección formateada
      const campoDireccion = obtenerCampo(config, 'direccion')!;
      if (campoDireccion.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
        throw new ErrorDesarrollo(
          'El campo dirección debe ser de tipo AUTOCOMPLETE',
        );

      campoDireccion.opciones = fila.opcionesDireccion
        .filter((opcion) => !stringVacío(opcion.direccion_formateada))
        .map((opcion) => ({
          nombre: opcion.direccion_formateada!,
          valor: opcion.direccion_formateada!,
        }));

      // Agregar valor y opciones de detalles
      const campoDetalles = obtenerCampo(config, 'detalles')!;
      if (campoDetalles.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
        throw new ErrorDesarrollo(
          'El campo detalles debe ser de tipo AUTOCOMPLETE',
        );
      campoDetalles.valor = fila.datos.direccion.detalles;
      campoDetalles.opciones = fila.opcionesDireccion
        .filter((opcion) => !stringVacío(opcion.detalles))
        .map((opcion) => ({
          nombre: opcion.detalles!,
          valor: opcion.detalles!,
        }));

      this.validezFormulariosDireccion.push(false);
      this.configsFormulariosDireccion.push(config);
    }
    this.formularioCreado = true;
  }

  /**
   * Guarda el formateo manual de las direcciones.
   * @author Juan Corral
   */
  protected guardarFormateo(): void {
    // Activar cargando
    this._establecerCargando(true);

    // Guardar formateo (en reversa para evitar errores de índice)
    for (let i = this.lector.filasSinFormatear.length - 1; i >= 0; i--) {
      const fila = this.lector.filasSinFormatear[i];
      const valor = this.valoresFormulariosDireccion[i] as {
        direccion: string;
        detalles: string;
      };
      fila.datos.direccion.direccion_formateada = valor.direccion;
      fila.datos.direccion.detalles = valor.detalles;
      fila.estado = EstadoFilaOrden.FORMATEADA;
    }

    // Desactivar cargando
    this._establecerCargando(false);
  }

  /**
   * Sube las órdenes del archivo al servidor.
   * @author Juan Corral
   */
  protected async subirOrdenes$(): Promise<void> {
    // Activar cargando
    this._establecerCargando(true);

    // Subir órdenes
    this._cdr.markForCheck();
    this.lector
      .subirOrdenes$(this.resultadoSubida)
      .then((resultado: ResultadoSubida) => {
        // Desactivar cargando
        this._establecerCargando(false);
        // Descargar archivo de fallidos
        if (resultado.fallidos > 0) this.lector.descargarFallidas();
      });
  }
}
