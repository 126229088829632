import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenService } from './token.service';
import { AutenticacionService } from './autenticacion.service';

/**
 * Guardian que redirige al login si el usuario no tiene una sesión activa.
 * Y verifica que los usuarios que son clientes tengan un cliente asociado.
 * @author Juan Corral
 */
export const autenticacionGuard: CanActivateFn = async () => {
  const tokenService = inject(TokenService);
  const autenticacionService = inject(AutenticacionService);
  const router = inject(Router);

  const refreshExpirado = await tokenService.refreshTokenExpirado$();
  if (refreshExpirado) return router.createUrlTree(['ingreso', 'login']);
  const usuario = await autenticacionService.usuario$;
  if (!usuario) {
    autenticacionService.limpiarUsuario();
    tokenService.limpiarToken();
    return router.createUrlTree(['ingreso', 'login']);
  }
  if (!usuario.acceso)
    return router.createUrlTree(['ingreso', 'identidad']);
  return true;
};

/**
 * Guardian que redirige a la página principal si el usuario ya tiene una sesión activa.
 * @author Juan Corral
 */
export const autenticadoGuard: CanActivateFn = async () => {
  const tokenService = inject(TokenService);
  const autenticacionService = inject(AutenticacionService);
  const router = inject(Router);

  const refreshExpirado = await tokenService.refreshTokenExpirado$();
  if (refreshExpirado) {
    autenticacionService.limpiarUsuario();
    tokenService.limpiarToken();
    return true;
  }
  const usuario = await autenticacionService.usuario$;
  if (!usuario) {
    autenticacionService.limpiarUsuario();
    tokenService.limpiarToken();
    return true;
  }
  if (!usuario.acceso) return true;
  return router.createUrlTree(['']);
};
