import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  CONFIGURACION_FORMULARIO_AGREGAR_PAQUETE,
  CONFIGURACION_TABLA_AGREGAR_PAQUETE,
  CONFIGURACION_FICHA_FORMULARIO_AGREGAR_PAQUETE,
  CONFIGURACION_FICHA_TABLA_AGREGAR_PAQUETE,
} from './utilidades/agregar-paquetes.static';
import {
  ConfiguracionTablaSincrona,
  DatosTabla,
  FilaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import { TablaComponent } from 'src/app/compartido/tabla/tabla.component';
import { FormularioComponent } from 'src/app/compartido/formulario/formulario.component';
import {
  DatosSpreadsheet,
  Spreadsheet,
} from 'src/app/compartido/utilidades/spreadsheet.class';
import { PaquetesService } from '../../utilidades/paquetes.service';
import { PaqueteBase } from '../../utilidades/paquetes.models';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { Subject } from 'rxjs';
import { OrganizacionDTO } from 'src/app/clientes/utilidades/clientes.models';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';
import { Paquete } from '../../utilidades/paquete.class';
import { VehiculoDTO } from 'src/app/domicilios/utilidades/domicilios.models';

@Component({
  selector: 'paquetes-agregar-paquetes',
  templateUrl: './agregar-paquetes.component.html',
  styleUrls: ['./agregar-paquetes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgregarPaquetesComponent implements OnInit {
  /* Configuración Ficha Formulario */
  protected configFichaFormulario: ConfiguracionFicha =
    CONFIGURACION_FICHA_FORMULARIO_AGREGAR_PAQUETE;

  /* Configuración Ficha Tabla */
  protected configFichaTabla: ConfiguracionFicha =
    CONFIGURACION_FICHA_TABLA_AGREGAR_PAQUETE;

  /* Configuración Formulario */
  protected configFormulario: ConfiguracionFormulario =
    CONFIGURACION_FORMULARIO_AGREGAR_PAQUETE;

  /* Validez Formulario */
  protected formularioValido: boolean = false;

  /* Configuración Tabla */
  protected configTabla: ConfiguracionTablaSincrona =
    CONFIGURACION_TABLA_AGREGAR_PAQUETE;

  /* Lista de organizaciones */
  private _organizaciones: OrganizacionDTO[] = [];

  /* Lista de vehículos */
  private _vehiculos: VehiculoDTO[] = [];

  /* Filas de la tabla */
  protected filas: DatosTabla = [];

  /* Paquete actual */
  protected idPaquete: number = 0;
  protected paquete: Paquete | undefined;

  /* Referencia del componente del formulario */
  @ViewChild(FormularioComponent) private formularioRef!: FormularioComponent;

  /* Referencia del componente de la tabla */
  @ViewChild(TablaComponent) private tablaRef!: TablaComponent;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _paquetesService: PaquetesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _alertasService: AlertasService,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        this._organizaciones = organizaciones;
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.id,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].id);
          campoOrg.readonly = true;
        }
      });

    // Poblar opciones de vehículo
    const campoVehiculo = obtenerCampo(this.configFormulario, 'vehiculo')!;
    if (campoVehiculo.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo vehículo debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService.obtenerVehiculos$().then((vehiculos) => {
      this._vehiculos = vehiculos;
      campoVehiculo.opciones = vehiculos.map((vehiculo) => ({
        nombre: vehiculo.nombre,
        valor: vehiculo.id,
      }));
      this._cdr.markForCheck();
    });
  }

  /**
   * Calcula el precio del envío del paquete y lo guarda en la variable.
   * @param {ValoresFormulario} valorFormulario - El valor del formulario.
   * @author Juan
   */
  protected calcularPrecio(valorFormulario: ValoresFormulario): void {
    if (!this.formularioValido) return;

    // Calcular precio
    const largo: number = valorFormulario['largo'];
    const ancho: number = valorFormulario['ancho'];
    const alto: number = valorFormulario['alto'];
    const peso: number = Math.ceil(valorFormulario['peso']);
    const precio = Paquete.calcularPrecio(largo, ancho, alto, peso);

    // Crear paquete
    this.paquete = new Paquete(
      this.idPaquete,
      this._organizaciones.find(
        (org) => org.id === valorFormulario['organizacion'],
      )!,
      valorFormulario['fecha'],
      valorFormulario['origen'],
      valorFormulario['destino'],
      this._vehiculos.find(
        (vehiculo) => vehiculo.id === valorFormulario['vehiculo'],
      )!,
      valorFormulario['notas'],
      peso,
      alto,
      ancho,
      largo,
      precio,
    );
  }

  /**
   * Agregar el paquete a la tabla.
   * @author Juan Corral
   */
  protected agregarPaquete(): void {
    if (this.paquete === undefined) return;
    const fila = { paquete: this.paquete, ...this.paquete.datosTabla() };
    this.filas.push(fila);
    this.tablaRef.dataSource.data = this.filas;
    this.paquete = undefined;
    this.idPaquete++;
    this.formularioRef.limpiarCampos();
  }

  /**
   * Descarga los paquetes a CSV.
   * @author Juan Corral
   */
  protected descargarPaquetes(): void {
    if (this.filas.length === 0) return;
    const archivo = new Spreadsheet('Paquetes', this.filas as DatosSpreadsheet);
    archivo.descargarCSV();
  }

  /**
   * Sube los paquetes a la base de datos.
   * @author Juan Corral
   */
  protected subirPaquetes(): void {
    const paquetes: PaqueteBase[] = this.filas.map((fila) =>
      fila['paquete'].aBase(),
    );
    this._paquetesService
      .subirPaquete$(paquetes)
      .then(() => {
        this.filas = [];
        this.tablaRef.dataSource.data = this.filas;
        this._alertasService.alertarExito('Paquetes subidos exitosamente');
        this._cdr.markForCheck();
      })
      .catch((error: ErrorFrontend) =>
        this._alertasService.alertarError('Error: ' + error.message),
      );
  }

  /**
   * Elimina un paquete de la tabla.
   * @param {FilaTabla} fila - La fila a eliminar.
   * @author Juan Corral
   */
  protected eliminarPaquete(fila: FilaTabla): void {
    this.filas = this.filas.filter(
      (f) => f['paquete'].id !== fila['paquete'].id,
    );
    this.tablaRef.dataSource.data = this.filas;
  }
}
