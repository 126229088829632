import {
  ClienteBase,
  DireccionBase,
  DireccionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { EstadoFilaOrden } from './archivo-ordenes/archivo-ordenes.models';
import {
  capitalizar,
  stringVacío,
  valorNumérico,
} from 'src/app/compartido/utilidades/valores.utils';
import {
  FilaSpreadsheet,
  Spreadsheet,
} from 'src/app/compartido/utilidades/spreadsheet.class';
import { COLUMNAS_REQUERIDAS_ARCHIVO_ORDEN } from './archivo-ordenes/archivo-ordenes.static';
import { OrdenBase } from 'src/app/ordenes/utilidades/ordenes.models';

/* Fila representando una orden en el archivo */
export class FilaOrden {
  /* Las celdas incompletas de la fila */
  public celdasIncompletas: string[] = [];

  /* Las direcciones del cliente de la fila */
  public opcionesDireccion: DireccionDTO[] = [];

  constructor(
    public indice: number,
    public datos: OrdenBase,
    public estado: EstadoFilaOrden,
    public errores?: string,
  ) {}

  /**
   * Crea una instancia de FilaOrden a partir de una fila de una hoja de cálculo.
   * @param {Spreadsheet} spreadsheet - La hoja de cálculo de donde se obtiene la fila.
   * @param {number} indice - El índice de la fila en la hoja de cálculo.
   * @returns {FilaOrden} - La instancia de FilaOrden creada.
   * @author Juan Corral
   */
  public static fromSpreadsheet(
    spreadsheet: Spreadsheet,
    indice: number,
  ): FilaOrden {
    const fila = spreadsheet.obtenerFila(indice);

    // Determinar ciudad
    let ciudad = capitalizar(fila['ciudad']);
    if (stringVacío(ciudad)) ciudad = 'Bogotá';
    const localidad = capitalizar(fila['localidad']);
    if (localidad.toLowerCase() == 'soacha') ciudad = 'Soacha';
    else if (localidad.toLowerCase() in ['facatativa', 'facatativá'])
      ciudad = 'Facatativá';

    // Crear dirección
    const direccion: DireccionBase = {
      direccion: fila['direccion'],
      direccion_formateada: undefined,
      detalles: fila['detalles'],
      barrio: capitalizar(fila['barrio']),
      localidad: localidad,
      ciudad: ciudad,
      latitud: valorNumérico(fila['latitud'])?.toString(),
      longitud: valorNumérico(fila['longitud'])?.toString(),
      creacion_manual: false,
    };

    // Crear cliente
    const cliente: ClienteBase = {
      cedula: parseInt(fila['cedula']),
      nombre: capitalizar(fila['nombre']),
      telefono: valorNumérico(fila['telefono']),
      correo: fila['correo'],
      organizacion: parseInt(fila['id_organizacion']),
    };

    // Crear orden
    const orden: OrdenBase = {
      referencia: fila['alias_organizacion'] + ' - ' + fila['referencia'],
      fecha_planeada: fila['fecha_planeada'],
      direccion: direccion,
      cliente: cliente,
      notas: fila['farmacia'] ? 'Farmacia: ' + fila['farmacia'] : undefined,
    };

    // Establecer celdas incompletas
    const celdasIncompletas: string[] = [];
    for (const requerida of COLUMNAS_REQUERIDAS_ARCHIVO_ORDEN) {
      if (stringVacío(fila[requerida.slug]))
        celdasIncompletas.push(requerida.nombre);
    }

    // Establecer estado
    const estado =
      celdasIncompletas.length > 0
        ? EstadoFilaOrden.INCOMPLETA
        : EstadoFilaOrden.SIN_FORMATEAR;

    // Crear instancia
    const filaOrden = new FilaOrden(indice, orden, estado);
    filaOrden.celdasIncompletas = celdasIncompletas;
    return filaOrden;
  }

  /**
   * Crea una instancia de FilaSpreadsheet a partir de los datos de la fila.
   * @returns {FilaSpreadsheet} - La instancia de FilaSpreadsheet creada.
   * @author Juan Corral
   */
  public toFilaSpreadsheet(): FilaSpreadsheet {
    const fila = {
      referencia: this.datos.referencia,
      cedula: this.datos.cliente.cedula.toString(),
      nombre: this.datos.cliente.nombre,
      direccion: this.datos.direccion.direccion,
      direccion_formateada: this.datos.direccion.direccion_formateada ?? '',
      detalles: this.datos.direccion.detalles ?? '',
      barrio: this.datos.direccion.barrio ?? '',
      localidad: this.datos.direccion.localidad ?? '',
      ciudad: this.datos.direccion.ciudad ?? '',
      latitud: this.datos.direccion.latitud ?? '',
      longitud: this.datos.direccion.longitud ?? '',
      telefono: this.datos.cliente.telefono?.toString() ?? '',
      correo: this.datos.cliente.correo ?? '',
    } as FilaSpreadsheet;
    if (this.errores) fila['errores'] = this.errores;
    return fila;
  }
}
