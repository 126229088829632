import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import {
  ListaDTO,
  Parametros,
} from 'src/app/compartido/utilidades/compartido.models';
import {
  DomicilioDTO,
  EstadoDomicilio,
} from '../../utilidades/domicilios.models';
import { CONFIGURACION_TABLA_DOMICILIOS_CON_ERROR } from './utilidades/errores-simpli.static';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Router } from '@angular/router';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';

@Component({
  selector: 'domicilios-errores-simpli',
  templateUrl: './errores-simpli.component.html',
  styleUrls: ['./errores-simpli.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErroresSimpliComponent implements OnInit {
  /* Configuración Ficha */
  protected configFicha: ConfiguracionFicha = {
    header: { titulo: 'Domicilios con errores' },
    contenido: { padding: true },
  };

  constructor(
    private readonly http: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.getErrors();
  }

  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_DOMICILIOS_CON_ERROR);

  /* Domicilios con error */
  protected errores: DomicilioDTO[] = [];

  /**
   * Obtiene los domicilios con error
   * @author Juan Corral
   */
  private getErrors(): void {
    const params: Parametros = { estado: EstadoDomicilio.ERROR };
    firstValueFrom(
      this.http
        .get<ListaDTO<DomicilioDTO>>('domicilios/', { params })
        .pipe(map((res: ListaDTO<DomicilioDTO>) => res.results)),
    ).then((domicilios) => {
      this.errores = domicilios;
      this.cdr.markForCheck();
    });
  }

  /**
   * Navega a la página del domicilio
   * @param {FilaTabla} domicilio - El domicilio a mostrar
   * @author Juan Corral
   */
  protected clickFila(domicilio: FilaTabla): void {
    this.router.navigate(['domicilios', domicilio['id']]);
  }
}
