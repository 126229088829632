<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Formulario -->
    <mat-step [completed]="validezFormulario" [editable]="false">
      <ng-template matStepLabel>Selección</ng-template>

      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="valorFormulario = $event"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="buscarDomicilios()"
        [disabled]="!validezFormulario"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Domicilios -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Despacho</ng-template>

      <div class="container">
        <input
          style="display: none"
          *ngIf="validezFormulario"
          type="text"
          (window:keypress)="escaneo($event)"
          autofocus
        />
      </div>

      <p *ngIf="dataSource.total > 0" class="texto">
        Los siguientes domicilios están listos para despachar:
      </p>

      <p
        *ngIf="dataSource.total === 0 && despachados.length === 0"
        class="texto color-warn"
      >
        ¡No hay domicilios listos para despachar en esta selección!
      </p>

      <p *ngIf="dataSource.total === 0 && despachados.length > 0" class="texto">
        ¡Todos los domicilios han sido despachados!
      </p>

      <compartido-tabla-asincrona
        #tabla
        class="tabla {{ responsiveService.claseCSS$ | async }}"
        [class.oculta]="dataSource.total === 0"
        [dataSource]="dataSource"
        [configuracion]="configTabla"
        (clickAccion)="despacharDomicilio$($event.fila['id'])"
      ></compartido-tabla-asincrona>

      <p *ngIf="despachados.length > 0" class="texto despachados">
        Domicilios despachados: {{ despachados.length }}
      </p>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        routerLink="/"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
