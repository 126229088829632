import { PrototipoColumna } from './archivo-ordenes.models';

/* Las columnas que deben estar en el archivo */
export const COLUMNAS_REQUERIDAS_ARCHIVO_ORDEN: PrototipoColumna[] = [
  {
    nombre: 'N° Referencia',
    slug: 'referencia',
    palabras: ['orden', 'factura', 'referencia'],
    requerida: true,
  },
  {
    nombre: 'Identificación del paciente',
    slug: 'cedula',
    palabras: ['cedula', 'identificacion'],
    requerida: true,
  },
  {
    nombre: 'Nombre del paciente',
    slug: 'nombre',
    palabras: ['nombre'],
    requerida: true,
  },
  {
    nombre: 'Dirección de la residencia del paciente',
    slug: 'direccion',
    palabras: ['direccion'],
    requerida: true,
  },
];

/* Columnas que deben ser leídas si existen en el archivo */
export const COLUMNAS_OPCIONALES_ARCHIVO_ORDEN: PrototipoColumna[] = [
  {
    nombre: 'Detalles de la dirección',
    slug: 'detalles',
    palabras: ['detalles', 'detalle'],
    requerida: false,
  },
  {
    nombre: 'Barrio de la residencia',
    slug: 'barrio',
    palabras: ['barrio'],
    requerida: false,
  },
  {
    nombre: 'Localidad de la residencia',
    slug: 'localidad',
    palabras: ['localidad'],
    requerida: false,
  },
  {
    nombre: 'Ciudad de la residencia',
    slug: 'ciudad',
    palabras: ['ciudad'],
    requerida: false,
  },
  {
    nombre: 'Latitud de la residencia',
    slug: 'latitud',
    palabras: ['latitud'],
    requerida: false,
  },
  {
    nombre: 'Longitud de la residencia',
    slug: 'longitud',
    palabras: ['longitud'],
    requerida: false,
  },
  {
    nombre: 'Teléfono de contacto del paciente',
    slug: 'telefono',
    palabras: ['telefono'],
    requerida: false,
  },
  {
    nombre: 'Correo electrónico del paciente',
    slug: 'correo',
    palabras: ['correo'],
    requerida: false,
  },
  {
    nombre: 'Medicamento ordenado',
    slug: 'medicamento',
    palabras: ['medicamento'],
    requerida: false,
  },
  {
    nombre: 'Cantidad del medicamento',
    slug: 'cantidad',
    palabras: ['cantidad'],
    requerida: false,
  },
  {
    nombre: 'Recaudo de la orden',
    slug: 'recaudo',
    palabras: ['recaudo'],
    requerida: false,
  },
];

/* Todas la columnas relevantes en el archivo */
export const COLUMNAS_ARCHIVO_ORDEN: PrototipoColumna[] =
  COLUMNAS_REQUERIDAS_ARCHIVO_ORDEN.concat(COLUMNAS_OPCIONALES_ARCHIVO_ORDEN);
