import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionTablaSincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Configuración de la ficha de enviar sms */
export const CONFIGURACION_FICHA_ENVIAR_SMS: ConfiguracionFicha = {
  header: { titulo: 'Enviar Mensajes SMS', icono: Icono.ENVIAR_SMS },
  maxWidth: 400,
};

/* Configuración del formulario de enviar sms */
export const CONFIGURACION_FORMULARIO_ENVIAR_SMS: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      requerido: true,
      opciones: [], // opciones se define dinámicamente en el componente
    },
    {
      nombre: 'Fecha Domicilios',
      slug: 'fecha',
      tipo: TipoCampoFormulario.FECHA,
      requerido: true,
      // minimo: formatearFecha(Date.now()),
    },
    {
      nombre: 'Texto',
      slug: 'texto',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      requerido: true,
      opciones: [], // opciones se define dinámicamente en el componente
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    esconderErrores: true,
  },
};

/* Configuración de la tabla de sms a enviar */
export const CONFIGURACION_TABLA_SMS_A_ENVIAR: ConfiguracionTablaSincrona = {
  columnas: [
    { slug: 'nombre', nombre: 'Nombre' },
    { slug: 'telefono', nombre: 'Teléfono' },
    { slug: 'organizacion', nombre: 'Organización' },
  ],
};
