import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { OrdenBase, OrdenDTO } from './ordenes.models';
import {
  AccionMasivaDTO,
  ListaDTO,
  Parametros,
} from '../../compartido/utilidades/compartido.models';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Orden } from './orden.class';
import { stringAleatorio } from 'src/app/compartido/utilidades/valores.utils';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class OrdenesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Obtiene las órdenes del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<OrdenDTO>>} - Las órdenes obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener las órdenes.
   * @author Juan Corral
   */
  public obtenerOrdenes$(params: {
    [param: string]: any;
  }): Promise<ListaDTO<OrdenDTO>> {
    return firstValueFrom(
      this.http.get<ListaDTO<OrdenDTO>>('ordenes/', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Obtiene información de la orden del servidor.
   * @param {number} id - El id de la orden a obtener.
   * @returns {Promise<Orden | undefined> } - La respuesta del servidor (promise).
   * @author Juan Corral
   */
  public obtenerOrden$(id: number): Promise<Orden | undefined> {
    return firstValueFrom(
      this.http.get<OrdenDTO>('ordenes/' + id).pipe(
        map((orden: OrdenDTO) => Orden.fromDTO(orden)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Crea la orden en el servidor.
   * @param {OrdenBase} orden - La orden a crear.
   * @returns {Promise<Orden>} - La orden creada (promise).
   * @throws {ErrorFrontend} - Si hubo un error al crear la orden.
   * @author Juan Corral
   */
  public async crearOrden$(orden: OrdenBase): Promise<Orden> {
    const formulas = orden.formulas;
    delete orden.formulas;
    // Crear la orden
    const creada = await firstValueFrom(
      this.http.post<OrdenDTO>('ordenes/', orden).pipe(
        map((res: OrdenDTO) => Orden.fromDTO(res)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
    // Subir las formulas
    if (formulas && formulas.length > 0) {
      for (let i = 0; i < formulas.length; i++) {
        const datos = new FormData();
        const extension = formulas[i].archivo.name.split('.').pop();
        datos.append('orden', creada.id.toString());
        datos.append('referencia', String(formulas[i].referencia));
        datos.append(
          'archivo',
          formulas[i].archivo,
          `${creada.id}_${i}_${stringAleatorio(7)}.${extension}`,
        );
        await firstValueFrom(
          this.http.post(`ordenes/formulas/`, datos).pipe(
            catchError((error: HttpErrorResponse) => {
              throw new ErrorFrontend(error);
            }),
          ),
        );
      }
    }
    return creada;
  }

  /**
   * Crea las órdenes en el servidor.
   * @param {OrdenBase[]} ordenes - Las órdenes a crear.
   * @returns {Promise<AccionMasivaDTO<string, object>>} - La respuesta del servidor (promise).
   * @author Juan Corral
   */
  public crearOrdenes$(
    ordenes: OrdenBase[],
  ): Promise<AccionMasivaDTO<string, object>> {
    return firstValueFrom(
      this.http
        .post<
          AccionMasivaDTO<string, object>
        >('ordenes/creacion_masiva/', ordenes)
        .pipe(
          catchError(() =>
            of({ resultado: [], exitosos: 0, fallidos: 0, total: 0 }),
          ),
        ),
    );
  }

  /**
   * Aprueba o rechaza la orden en el servidor.
   * @param {number} id - El id de la orden a aprobar.
   * @param {boolean} aprobar - Indica si se aprueba o rechaza la orden.
   * @param {string | null | undefined} motivo - El motivo de la aprobación o rechazo.
   * @returns {Promise<Orden>} - La orden actualizada (promise).
   * @throws {ErrorFrontend} - Si hubo un error al actualizar la orden.
   * @author Juan Corral
   */
  public async aprobarOrden$(
    id: number,
    aprobar: boolean,
    motivo: string | null | undefined,
  ): Promise<Orden> {
    const datos = { aprobar, motivo };
    return firstValueFrom(
      this.http.patch<OrdenDTO>(`ordenes/${id}/aprobar/`, datos).pipe(
        map((orden: OrdenDTO) => Orden.fromDTO(orden)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Prepara la orden en el servidor.
   * @param {number} id - El id de la orden a preparar.
   * @param {Map<number, number>} mapa - El mapeo de los items a cantidad preparada.
   * @returns {Promise<Orden>} - La orden actualizada (promise).
   * @throws {ErrorFrontend} - Si hubo un error al preparar la orden.
   * @author Juan Corral
   */
  public prepararOrden$(id: number, mapa: Map<number, number>): Promise<Orden> {
    const datos = Object.fromEntries(mapa);
    return firstValueFrom(
      this.http.patch<OrdenDTO>(`ordenes/${id}/preparar/`, datos).pipe(
        map((orden: OrdenDTO) => Orden.fromDTO(orden)),
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }

  /**
   * Envía un archivo CSV con las órdenes al correo del usuario.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @throws {ErrorFrontend} - Si hubo un error al descargar las órdenes.
   * @author Juan Corral
   */
  public async descargarOrdenes$(filtros?: {
    [filtro: string]: string | number;
  }): Promise<void> {
    const params: Parametros = {};
    if (filtros) {
      for (const filtro of Object.keys(filtros)) {
        params[filtro] = filtros[filtro];
      }
    }
    await firstValueFrom(
      this.http.get<ListaDTO<OrdenDTO>>('ordenes/descargar/', { params }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw new ErrorFrontend(error);
        }),
      ),
    );
  }
}
