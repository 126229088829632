import { Permiso, RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaAsincrona,
  TipoColumnaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Configuración de la tabla de paquetes */
export const CONFIGURACION_TABLA_PAQUETES: ConfiguracionTablaAsincrona = {
  columnas: [
    {
      slug: 'id',
      nombre: 'ID',
      ordenable: true,
      tipo: TipoColumnaTabla.NUMERO,
    },
    {
      slug: 'organizacion',
      nombre: 'Organizacion',
      tipo: TipoColumnaTabla.TEXTO,
    },
    {
      slug: 'fecha',
      nombre: 'Fecha',
      ordenable: true,
      tipo: TipoColumnaTabla.FECHA,
    },
    {
      slug: 'origen',
      nombre: 'Origen',
    },
    {
      slug: 'destino',
      nombre: 'Destino',
    },
    {
      slug: 'vehiculo',
      nombre: 'Vehiculo',
      tipo: TipoColumnaTabla.TEXTO,
    },
    // {
    //   slug: 'alto',
    //   nombre: 'Alto',
    //   tipo: TipoColumnaTabla.NUMERO,
    // },
    // {
    //   slug: 'ancho',
    //   nombre: 'Ancho',
    //   tipo: TipoColumnaTabla.NUMERO,
    // },
    // {
    //   slug: 'largo',
    //   nombre: 'Largo',
    //   tipo: TipoColumnaTabla.NUMERO,
    // },
    {
      slug: 'dimensiones',
      nombre: 'Dimensiones',
      tipo: TipoColumnaTabla.TEXTO,
    },
    {
      slug: 'peso',
      nombre: 'Peso',
      tipo: TipoColumnaTabla.NUMERO,
    },
    {
      slug: 'pesoVirtual',
      nombre: 'Peso Virtual',
      tipo: TipoColumnaTabla.NUMERO,
    },
    {
      slug: 'precio',
      nombre: 'Precio',
      tipo: TipoColumnaTabla.MONEDA,
      ordenable: true,
    },
    {
      slug: 'notas',
      nombre: 'Notas',
      tipo: TipoColumnaTabla.TEXTO,
    },
  ],
  busqueda: true,
  filtros: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // Se poblará en el componente
      roles: [RolUsuario.ELEVA],
    },
    {
      nombre: 'Desde',
      slug: 'desde',
      tipo: TipoCampoFormulario.FECHA,
    },
    {
      nombre: 'Hasta',
      slug: 'hasta',
      tipo: TipoCampoFormulario.FECHA,
    },
  ],
  botones: [
    {
      nombre: 'Descargar',
      slug: 'descargar',
      icono: Icono.DESCARGAR,
      autorizacion: { objeto: 'paquete', permisos: [Permiso.VER_MASIVO] },
    },
    {
      nombre: 'Agregar',
      slug: 'agregar',
      icono: Icono.CREAR,
      autorizacion: { objeto: 'paquete', permisos: [Permiso.CREAR_MASIVO] },
    },
  ],
};
