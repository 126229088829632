import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { OrganizacionDTO } from '../../clientes/utilidades/clientes.models';
import { ListaDTO } from '../../compartido/utilidades/compartido.models';
import { VehiculoDTO } from 'src/app/domicilios/utilidades/domicilios.models';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class OrganizacionService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Obtiene la lista de organizaciones del servidor
   * @returns {Promise<OrganizacionDTO[]>} - La lista de organizaciones como llega del servidor (promise)
   * @author Juan Corral
   */
  public async obtenerOrganizaciones$(): Promise<OrganizacionDTO[]> {
    return firstValueFrom(
      this.http.get<ListaDTO<OrganizacionDTO>>('organizaciones/').pipe(
        map((res: ListaDTO<OrganizacionDTO>) => res.results),
        catchError(() => of([])),
      ),
    );
  }

  /**
   * Obtiene la lista de vehículos del servidor
   * @returns {Promise<VehiculoDTO[]>} - La lista de vehículos como llega del servidor (promise)
   * @author Juan Corral
   */
  public obtenerVehiculos$(): Promise<VehiculoDTO[]> {
    return firstValueFrom(
      this.http.get<ListaDTO<VehiculoDTO>>('domicilios/vehiculos/').pipe(
        map((res: ListaDTO<VehiculoDTO>) => res.results),
        catchError(() => of([])),
      ),
    );
  }
}
