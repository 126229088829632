<compartido-ficha [configuracion]="configFicha">
  <div class="texto">
    <p>Se encontraron {{ errores.length }} domicilio(s) con errores.</p>
    <p>Pasos para corregir un error:</p>
    <ol class="mat-small">
      <li>Leer el error en la tabla.</li>
      <li>Hacer click sobre la fila.</li>
      <li>Hacer click en el modo de edición de domicilio (esquina superior derecha).</li>
      <li>Corregir el error.</li>
      <li>Cambiar el estado a "En Cola".</li>
      <li>Guardar el domicilio.</li>
      <li>Recargar la página para refrescar las notificaciones.</li>
    </ol>
  </div>
  <compartido-tabla
    [datos]="errores"
    [configuracion]="configTabla"
    (clickFila)="clickFila($event)"
  ></compartido-tabla>
</compartido-ficha>
