import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ComparacionFiltro,
  ConfiguracionTablaSincrona,
  DatosTabla,
  FilaTabla,
  FiltroTablaSincrona,
  TipoColumnaTabla,
} from './utilidades/tabla.models';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from '../formulario/utilidades/formulario.models';
import { toMoment, valorVacío } from '../utilidades/valores.utils';
import { deepCopy } from '../utilidades/objetos.utils';
import { CONFIGURACION_FORMULARIO_FILTROS_TABLA } from './utilidades/tabla.static';
import { obtenerFiltro } from './utilidades/tabla.utils.models';

@Component({
  selector: 'compartido-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaComponent implements OnChanges, AfterViewInit, OnDestroy {
  /* Suscripción */
  private readonly _suscripcion = new Subscription();

  /* Tipos de columna */
  protected readonly TipoColumnaTabla = TipoColumnaTabla;

  /* Datos */
  @Input({ required: true }) datos!: DatosTabla;
  public readonly dataSource: MatTableDataSource<FilaTabla> =
    new MatTableDataSource();

  /* Carga */
  @Input() cargando: boolean = false;

  /* Configuración */
  @Input({ required: true }) configuracion!: ConfiguracionTablaSincrona;

  /* Columnas */
  protected columnas: string[] = [];

  /* Herramientas */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  /* Selección */
  protected seleccion: SelectionModel<any> = new SelectionModel<DatosTabla>(
    true,
    [],
  );

  /* Filtros */
  protected configFormularioFiltros: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_FILTROS_TABLA,
  );

  /* Emisores de eventos */
  @Output() clickFila = new EventEmitter<FilaTabla>();
  @Output() seleccionados = new EventEmitter<DatosTabla>();
  @Output() clickAccion = new EventEmitter<{
    fila: FilaTabla;
    accion: string;
  }>();
  @Output() protected clickBoton: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configuracion']) {
      // Configuración de búsqueda y filtros
      const camposFiltros = this.configuracion.filtros;
      if (this.configuracion.busqueda) {
        const filtros = camposFiltros ?? [];
        if (!obtenerFiltro(filtros, 'buscar')) {
          filtros.unshift({
            nombre: 'Buscar',
            slug: 'buscar',
            tipo: TipoCampoFormulario.TEXTO,
          });
        }
        this.configFormularioFiltros.campos = filtros;
      } else if (camposFiltros !== undefined) {
        this.configFormularioFiltros.campos = camposFiltros;
      }

      // Columna de selección
      this.columnas = this.configuracion.columnas.map((col) => col.slug);
      if (this.configuracion.seleccion) this.columnas.unshift('seleccion');

      // Columna de acciones
      if (this.configuracion.acciones !== undefined)
        this.columnas.push('acciones');
    }

    // Escuchar cambios de datos
    if (changes['datos']) {
      this.dataSource.data = this.datos;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Cambios en la selección
    const escucharSeleccion = this.seleccion.changed.subscribe(() => {
      this.seleccionados.next(this.seleccion.selected);
    });
    this._suscripcion.add(escucharSeleccion);
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Invierte el estado de selección completa.
   * @author Juan Corral
   */
  protected toggleSeleccionCompleta(): void {
    const seleccionCompleta =
      this.seleccion.selected.length === this.dataSource.data.length;
    this.seleccion.clear();
    if (seleccionCompleta)
      this.dataSource.data.forEach((row) => this.seleccion.select(row));
  }

  /**
   * Aplica los filtros a los datos
   * @param {ValoresFormulario | undefined} valores - Los valores de los filtros
   * @author Juan Corral
   */
  protected aplicarFiltros(valores: ValoresFormulario | undefined): void {
    if (this.paginator === undefined || valores === undefined) return;

    // Búsqueda
    if (this.configuracion.busqueda)
      this.dataSource.filter = valores['buscar'] ?? undefined;

    const datos = [];

    // Filtros
    for (const fila of this.datos) {
      let incluir = true;
      for (const [slugFiltro, valorFiltro] of Object.entries(valores)) {
        if (valorVacío(valorFiltro) || slugFiltro === 'buscar') continue;

        const filtro = obtenerFiltro(
          this.configuracion,
          slugFiltro,
        ) as FiltroTablaSincrona;
        if (filtro === undefined) continue;

        if (this._pasaFiltro(fila, filtro, valorFiltro) === false) {
          incluir = false;
          break;
        }
      }
      if (incluir) datos.push(fila);
    }

    this.dataSource.data = datos;
  }

  /**
   * Devuelve si una fila pasa el filtro
   * @param {FilaTabla} fila - La fila a comprobar
   * @param {FiltroTabla} filtro - El filtro a aplicar
   * @param {any} valorFiltro - El valor del filtro
   * @returns {boolean} - Si la fila pasa el filtro o no
   * @author Juan Corral
   */
  private _pasaFiltro(
    fila: FilaTabla,
    filtro: FiltroTablaSincrona,
    valorFiltro: any,
  ): boolean {
    const valorFila = fila[filtro.parametro];
    if (valorFila === undefined) return false;

    if (filtro.tipo === TipoCampoFormulario.FECHA) {
      const fechaFiltro = toMoment(valorFiltro)!;
      const fechaFila = toMoment(valorFila)!;
      if (
        (filtro.comparacion === ComparacionFiltro.MAYOR &&
          fechaFila < fechaFiltro) ||
        (filtro.comparacion === ComparacionFiltro.MENOR &&
          fechaFila > fechaFiltro)
      ) {
        return false;
      }
    } else if (
      filtro.comparacion === ComparacionFiltro.EXACTA &&
      valorFila != valorFiltro
    ) {
      return false;
    } else if (
      filtro.comparacion === ComparacionFiltro.PARCIAL &&
      valorFila.toString().indexOf(valorFiltro) === -1
    ) {
      return false;
    }
    return true;
  }
}
