import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, of, Subscription, timer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Token } from './autenticacion.models';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class TokenService {
  /* Decodificador de JWT */
  private readonly _jwtService: JwtHelperService = new JwtHelperService();

  /** Access Token */
  private _accessValido: boolean = false;
  private _accessTemporizador: Subscription | undefined;
  private _accessValidoPromise$?: Promise<boolean>;
  private _accessToken: string | null = null;
  public get accessToken(): string | null {
    if (this._accessToken !== null) return this._accessToken;
    const token = this._obtenerTokenGuardado();
    this._accessToken = token?.access ?? null;
    return this._accessToken;
  }

  /* Refresh Token */
  private _refreshValido: boolean = false;
  private _refreshValidoPromise$?: Promise<boolean>;
  private _refrescandoPromise$: Promise<boolean> | undefined;
  public get refreshToken(): string | null {
    const token = this._obtenerTokenGuardado();
    return token?.refresh ?? null;
  }

  constructor(private readonly http: HttpClient) {}

  /**
   * Devuelve si el access token está expirado
   * @returns {Promise<boolean>} - Si el access token está expirado (promise)
   * @author Juan Corral
   */
  public async accessTokenExpirado$(): Promise<boolean> {
    // Obtener el access token
    const access = this.accessToken;
    if (access === null) return Promise.resolve(true);

    // Si el access token guardado está expirado, devolver true
    if (this._jwtService.isTokenExpired(access)) return Promise.resolve(true);

    // Si el token ya fue validado, devolver false
    if (this._accessValido) return Promise.resolve(false);

    // Si se está validando el token, devolver el promise actual
    if (this._accessValidoPromise$) return this._accessValidoPromise$;

    // Validar el access token con el servidor
    const expirado = firstValueFrom(
      this.http.post('autenticacion/token/verificar/', { token: access }).pipe(
        map(() => false),
        catchError(() => of(true)),
        tap((expirado) => {
          this._accessValido = !expirado;
          if (!expirado) this._empezarTemporizadorAccess();
          this._accessValidoPromise$ = undefined;
        }),
      ),
    );
    this._accessValidoPromise$ = expirado;
    return expirado;
  }

  /**
   * Devuelve si el refresh token está expirado
   * @returns {Promise<boolean>} - Si el refresh token está expirado (promise)
   * @author Juan Corral
   */
  public async refreshTokenExpirado$(): Promise<boolean> {
    // Obtener el refresh token
    const refresh = this.refreshToken;
    if (refresh === null) return Promise.resolve(true);

    // Si el refresh token guardado está expirado, devolver true
    if (this._jwtService.isTokenExpired(refresh)) {
      this.limpiarToken();
      return Promise.resolve(true);
    }

    // Si el token ya fue validado, devolver false
    if (this._refreshValido) return Promise.resolve(false);

    // Si se está validando el token, devolver el promise actual
    if (this._refreshValidoPromise$) return this._refreshValidoPromise$;

    // Validar el refresh token con el servidor
    const expirado = firstValueFrom(
      this.http.post('autenticacion/token/verificar/', { token: refresh }).pipe(
        map(() => false),
        catchError(() => of(true)),
        tap((expirado) => {
          this._refreshValido = !expirado;
          this._refreshValidoPromise$ = undefined;
        }),
      ),
    );
    this._refreshValidoPromise$ = expirado;
    return expirado;
  }

  /**
   * Obtiene el token guardado en el local storage
   * @returns {Token | null} - El token guardado (o null si no existe)
   * @autor Juan Corral
   */
  private _obtenerTokenGuardado(): Token | null {
    const tokenJson = localStorage.getItem('token');
    if (tokenJson === null) return null;
    const token: Token = JSON.parse(tokenJson);
    return token;
  }

  /**
   * Guarda el token en la sesión del usuario
   * @param {Token} token - El token a guardar
   * @param {boolean} soloToken - Si solo se debe guardar el token (no permisos, etc.)
   * @author Juan Corral
   */
  public guardarToken(token: Token): void {
    this._accessToken = token.access;
    localStorage.setItem('token', JSON.stringify(token));
    this._empezarTemporizadorAccess();
  }

  /**
   * Borra el token de la sesión del usuario
   * @autor Juan Corral
   */
  public limpiarToken(): void {
    localStorage.removeItem('token');
    this._accessToken = null;
    this._accessValido = false;
    if (this._accessTemporizador) this._accessTemporizador.unsubscribe();
  }

  /**
   * Usa el refresh token para actualizar el token de la sesión
   * Devuelve si tuvo éxito refrescando el token
   * @return {Promise<boolean>} - Si hubo éxito refrescando el token (promise)
   * @author Juan Corral
   */
  public async refrescarToken$(): Promise<boolean> {
    // Obtener el refresh token
    const refresh = this.refreshToken;

    // Si no hay refresh token, devolver false
    if (refresh === null) return Promise.resolve(false);

    // Si se está refrescando el token, devolver el promise actual
    if (this._refrescandoPromise$) return this._refrescandoPromise$;

    // Crear un promise para evitar duplicados
    this._refrescandoPromise$ = firstValueFrom(
      this.http.post<Token>('autenticacion/token/refrescar/', { refresh }).pipe(
        map((data: Token) => {
          this.guardarToken(data);
          return true;
        }),
        catchError(() => {
          this.limpiarToken();
          return of(false);
        }),
        tap(() => (this._refrescandoPromise$ = undefined)),
      ),
    );

    return this._refrescandoPromise$;
  }

  /**
   * Empieza el temporizador para la expiración del access token
   * @autor Juan Corral
   */
  private _empezarTemporizadorAccess(): void {
    if (this._accessTemporizador) this._accessTemporizador.unsubscribe();
    const access = this.accessToken;
    if (access === null) return;
    const expira = this._jwtService.getTokenExpirationDate(access);
    if (expira === null) return;
    const tiempo = expira.getTime() - Date.now();
    if (tiempo < 0) return;
    this._accessTemporizador = timer(tiempo).subscribe(
      () => (this._accessValido = false),
    );
  }
}
