import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { DomiciliosService } from '../../utilidades/domicilios.service';
import { CONFIGURACION_RECURSO_DOMICILIO } from './utilidades/ver-domicilio.static';
import { ConfiguracionRecurso } from 'src/app/compartido/recurso/utilidades/recurso.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Domicilio } from '../../utilidades/domicilio.class';
import { obtenerSeccion } from 'src/app/compartido/recurso/utilidades/recurso.utils';

@Component({
  selector: 'domicilios-ver-domicilio',
  templateUrl: './ver-domicilio.component.html',
  styleUrls: ['./ver-domicilio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerDomicilioComponent implements OnInit {
  /* ID del domicilio */
  @Input() public idDomicilio: number | undefined;

  /* Domicilio */
  private _domicilio: Domicilio | undefined;

  /* Configuración del recurso */
  protected configuracion: ConfiguracionRecurso | undefined;

  constructor(
    private readonly _domiciliosService: DomiciliosService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // Crear la configuración del recurso
    this._crearConfiguracion$();
  }

  /**
   * Crea la configuración del recurso para mostrar en la vista
   * @author Juan Corral
   */
  private async _crearConfiguracion$(): Promise<void> {
    // Obtener el domicilio
    const id = await firstValueFrom<number>(
      this._route.params.pipe(map((params: Params) => params['id'])),
    );
    const idDomicilio = this.idDomicilio ?? id;
    this._domicilio =
      await this._domiciliosService.obtenerDomicilio$(idDomicilio);
    if (this._domicilio === undefined)
      throw new ErrorFrontend('No se encontró el domicilio');

    // Crear la configuración
    const configuracion = deepCopy(CONFIGURACION_RECURSO_DOMICILIO);

    // Sección: Información
    const domicilioPlano = this._domicilio as Record<string, any>;
    configuracion.nombre = this._domicilio.titulo;
    const seccionInformacion = obtenerSeccion(configuracion, 'informacion');
    for (const subseccion of seccionInformacion!.subsecciones) {
      for (const campo of subseccion.formulario.campos) {
        campo.valor = domicilioPlano[campo.slug];
        if (campo.slug === 'cliente' && !!this._domicilio.cliente) {
          campo.valor = this._domicilio.cliente.nombre;
        } else if (
          campo.slug === 'vehiculo' &&
          !!this._domicilio.ruta?.vehiculo
        ) {
          campo.valor = this._domicilio.ruta.vehiculo.nombre;
        }
      }
    }

    // Sección: Direcciones
    const seccionDireccion = obtenerSeccion(configuracion, 'direccion');
    for (const subseccion of seccionDireccion!.subsecciones) {
      for (const campo of subseccion.formulario.campos) {
        if (campo.slug !== 'coordenadas') {
          campo.valor = domicilioPlano['direccion'][campo.slug];
        } else {
          const latitud = parseFloat(this._domicilio.direccion.latitud ?? '');
          const longitud = parseFloat(this._domicilio.direccion.longitud ?? '');
          if (!isNaN(latitud) && !isNaN(longitud))
            campo.valor = { latitud, longitud };
        }
      }
    }

    // Guardar la configuración
    this.configuracion = configuracion;
    this._cdr.markForCheck();
  }
}
