import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { PaqueteBase, PaqueteDTO } from './paquetes.models';
import {
  AccionMasivaDTO,
  ListaDTO,
  Parametros,
} from '../../compartido/utilidades/compartido.models';
import { deepCopy } from '../../compartido/utilidades/objetos.utils';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { Paquete } from './paquete.class';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class PaquetesService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Obtiene los paquetes del servidor.
   * @param {Parametros} params - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<PaqueteDTO>>} - Los paquetes obtenidos del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error al obtener los paquetes.
   * @author Juan Corral
   */
  public obtenerPaquetes$(params: {
    [param: string]: any;
  }): Promise<ListaDTO<PaqueteDTO>> {
    return firstValueFrom(
      this.http
        .get<ListaDTO<PaqueteDTO>>('domicilios/paquetes/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Crea los paquetes en el servidor.
   * @param {PaqueteBase[]} paquetes - Los paquetes a crear.
   * @returns {Promise<AccionMasivaDTO<string, object>>} - La respuesta del servidor (promise).
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @author Juan Corral
   */
  public subirPaquete$(
    paquetes: PaqueteBase[],
  ): Promise<AccionMasivaDTO<string, object>> {
    return firstValueFrom(
      this.http
        .post<
          AccionMasivaDTO<string, object>
        >('domicilios/paquetes/creacion_masiva/', paquetes)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Actualiza el paquete en el servidor.
   * @param {number} id - El id del paquete a actualizar.
   * @param {PaqueteDTO} paquete - Los datos del paquete a actualizar.
   * @returns {Promise<Paquete | undefined>} - El paquete actualizado o undefined si hubo un error (promise).
   * @author Juan Corral
   */
  public actualizarPaquete$(
    id: number,
    paquete: PaqueteDTO,
  ): Promise<Paquete | undefined> {
    const datos: any = deepCopy(paquete);
    delete datos.cliente;
    return firstValueFrom(
      this.http.patch<PaqueteDTO>('domicilios/paquetes/' + id, datos).pipe(
        map((paquete: PaqueteDTO) => Paquete.fromDTO(paquete)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Obtiene información del paquete del servidor.
   * @param {number} id - El id del paquete a obtener.
   * @returns {Promise<Paquete | undefined> } - La respuesta del servidor (promise).
   * @author Juan Corral
   */
  public obtenerPaquete$(id: number): Promise<Paquete | undefined> {
    return firstValueFrom(
      this.http.get<PaqueteDTO>('domicilios/paquetes/' + id).pipe(
        map((paquete: PaqueteDTO) => Paquete.fromDTO(paquete)),
        catchError(() => of(undefined)),
      ),
    );
  }

  /**
   * Envía un archivo CSV con los paquetes al correo del usuario.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @throws {ErrorFrontend} - Si hubo un error al descargar los paquetes.
   * @author Juan Corral
   */
  public async descargarPaquetes$(filtros?: {
    [filtro: string]: string | number;
  }): Promise<void> {
    const params: Parametros = {};
    if (filtros) {
      for (const filtro of Object.keys(filtros)) {
        params[filtro] = filtros[filtro];
      }
    }
    await firstValueFrom(
      this.http
        .get<ListaDTO<PaqueteDTO>>('domicilios/paquetes/descargar/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }
}
