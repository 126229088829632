import { Modificar } from 'src/app/compartido/utilidades/compartido.models';
import {
  ClienteBase,
  ClienteDTO,
  DireccionBase,
  DireccionDTO,
} from '../../clientes/utilidades/clientes.models';
import { DomicilioDTO } from 'src/app/domicilios/utilidades/domicilios.models';

/* Estructura básica de Orden */
export interface OrdenBase {
  referencia: string | null | undefined;
  fecha_planeada: string;
  direccion: DireccionBase;
  cliente: ClienteBase;
  items?: ItemOrdenBase[];
  formulas?: FormulaBase[];
  notas?: string | null | undefined;
}

/* Estructura de Orden en el API */
export interface OrdenDTO
  extends Modificar<
    OrdenBase,
    {
      direccion: DireccionDTO;
      cliente: ClienteDTO;
      items?: ItemOrdenDTO[];
      formulas?: FormulaDTO[];
    }
  > {
  id: number;
  titulo: string;
  estado: EstadoOrden;
  estado_display: string;
  antecesora?: OrdenDTO;
  domicilio?: DomicilioDTO;
  fecha_aprobacion: string;
  autor_aprobacion: string;
  motivo_aprobacion: string;
  fecha_confirmacion?: string;
  fecha_preparacion?: string;
  fecha_creacion: string;
}

/* Estados de Orden */
export enum EstadoOrden {
  PENDIENTE = 'pendiente',
  APROBADA = 'aprobada',
  RECHAZADA = 'rechazada',
  CONFIRMADA = 'confirmada',
  PREPARADA = 'preparada',
  PREPARADA_PARCIAL = 'preparada_parcial',
  NO_PREPARADA = 'no_preparada',
  CANCELADA = 'cancelada',
}

/* Estructura básica de Fórmula de Orden */
export interface FormulaBase {
  referencia: string | null | undefined;
  archivo: File;
}

/* Estructura de Fórmula de Orden en el API */
export interface FormulaDTO
  extends Modificar<FormulaBase, { archivo: string }> {
  id: number;
}

/* Estructura básica de Item de Orden */
export interface ItemOrdenBase {
  cantidad: number;
  producto: ProductoBase;
}

/* Estructura de Item de Orden en el API */
export interface ItemOrdenDTO
  extends Modificar<ItemOrdenBase, { producto: ProductoDTO }> {
  id: number;
  orden: number;
  estado: EstadoItemOrden;
  estado_display: string;
  cantidad_preparada?: number;
  fecha_creacion: string;
}

/* Estados de Item de Orden */
export enum EstadoItemOrden {
  PENDIENTE = 'pendiente',
  PREPARADO = 'preparado',
  PREPARADO_PARCIAL = 'preparado_parcial',
  NO_PREPARADO = 'no_preparado',
}

/* Estructura básica de Producto */
export interface ProductoBase {
  tipo: string;
  nombre: string;
  origen_datos: string;
}

/* Estructura de Producto en el API */
export interface ProductoDTO extends ProductoBase {
  id: number;
  tipo_display: string;
  id_externo: string;
  fecha_creacion: string;
}
