import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, catchError, map } from 'rxjs';
import { Token } from '../../nucleo/autenticacion/autenticacion.models';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { TokenService } from '../../nucleo/autenticacion/token.service';
import {
  Parametros,
  ListaDTO,
} from 'src/app/compartido/utilidades/compartido.models';
import { AutorizacionService } from 'src/app/nucleo/autorizacion/autorizacion.service';
import { Router } from '@angular/router';
import { stringAleatorio } from 'src/app/compartido/utilidades/valores.utils';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { VerificacionIdentidadDTO } from './ingreso.models';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient, TokenService, AutorizacionService, Router],
})
export class IngresoService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _tokenService: TokenService,
    private readonly _autorizacionService: AutorizacionService,
    private readonly _autenticacionService: AutenticacionService,
  ) {}

  /**
   * Registra un nuevo usuario en el servidor.
   * @param {string} usuario - El nombre de usuario a registrar.
   * @param {string} correo - El correo a registrar.
   * @param {string} contrasena - La contraseña a registrar.
   * @param {number} cedula - La cédula del cliente al que se asocia el usuario.
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @author Juan Corral
   */
  public async registro$(
    organizacion: number,
    cedula: number,
    correo: string,
    usuario: string,
    nombre: string,
    telefono: string,
    contrasena: string,
  ): Promise<void> {
    return firstValueFrom(
      this._http
        .post<Token>('autenticacion/usuarios/registro_cliente/', {
          organizacion: organizacion,
          cedula: cedula,
          email: correo,
          username: usuario,
          nombre: nombre,
          telefono: telefono,
          password: contrasena,
        })
        .pipe(
          map((data: Token) => {
            this._tokenService.guardarToken(data);
            this._autenticacionService.limpiarUsuario();
            this._autorizacionService.limpiarPermisos();
            return;
          }),
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Valida las credenciales en el servidor.
   * @param {string} usuario - El nombre de usuario a validar.
   * @param {string} contrasena - La contraseña a validar.
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @author Juan Corral
   */
  public async login$(usuario: string, contrasena: string): Promise<void> {
    return firstValueFrom(
      this._http
        .post<Token>('autenticacion/login/', {
          username: usuario,
          password: contrasena,
        })
        .pipe(
          map((data: Token) => {
            this._tokenService.guardarToken(data);
            this._autenticacionService.limpiarUsuario();
            this._autorizacionService.limpiarPermisos();
            return;
          }),
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Obtiene las verificaciones de identidad del servidor.
   * @param {Parametros} params [Opcional] - Los parámetros de búsqueda.
   * @returns {Promise<ListaDTO<VerificacionIdentidadDTO>>} - Las verificaciones de identidad (promise).
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @autor Juan Corral
   */
  public async obtenerVerificacionesIdentidad$(
    params?: Parametros,
  ): Promise<ListaDTO<VerificacionIdentidadDTO>> {
    return firstValueFrom(
      this._http
        .get<
          ListaDTO<VerificacionIdentidadDTO>
        >('autenticacion/verificaciones/', { params })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Obtiene una verificación de identidad del servidor.
   * @param {number} id - El id de la verificación a obtener.
   * @returns {Promise<VerificacionIdentidadDTO>} - La verificación de identidad (promise).
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @autor Juan Corral
   */
  public async obtenerVerificacionIdentidad$(
    id: number,
  ): Promise<VerificacionIdentidadDTO> {
    return firstValueFrom(
      this._http
        .get<VerificacionIdentidadDTO>(`autenticacion/verificaciones/${id}`)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Aprueba o rechaza la verificación de identidad en el servidor.
   * @param {number} id - El id de la verificación a aprobar.
   * @param {boolean} aprobar - Si se aprueba o no la verificación.
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @autor Juan Corral
   */
  public async aprobarVerificacionIdentidad$(
    id: number,
    aprobar: boolean,
  ): Promise<void> {
    return firstValueFrom(
      this._http
        .post<void>(`autenticacion/verificaciones/${id}/aprobar`, { aprobar })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }

  /**
   * Actualiza la verificación de identidad en el servidor con el documento.
   * @param {number} id - El id de la verificación a actualizar.
   * @param {File} documento - El documento a subir.
   * @returns {Promise<VerificacionIdentidadDTO>} - La verificación actualizada (promise).
   * @throws {ErrorFrontend} - Si hubo un error en el servidor.
   * @autor Juan Corral
   */
  public async actualizarVerificacionIdentidad$(
    id: number,
    documento: File,
  ): Promise<VerificacionIdentidadDTO> {
    const formData = new FormData();
    const extension = documento.name.split('.').pop();
    formData.append(
      'archivo',
      documento,
      `${id}_${stringAleatorio(7)}.${extension}`,
    );
    return firstValueFrom(
      this._http
        .put<VerificacionIdentidadDTO>(
          `autenticacion/verificaciones/${id}`,
          formData,
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            throw new ErrorFrontend(error);
          }),
        ),
    );
  }
}
