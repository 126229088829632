<form [formGroup]="formulario" *ngIf="configuracion">
  <div
    formArrayName="campos"
    class="campos"
    [ngClass]="obtenerClases('campos')"
    [style.--width-campo]="(configuracion.estilo?.widthCampo ?? 200) + 'px'"
    [style.--width-label]="(configuracion.estilo?.widthLabel ?? 100) + 'px'"
  >
    <div
      *ngFor="let campo of configuracion.campos; let i = index"
      [ngClass]="obtenerClases('campo')"
      class="campo {{ 'campo-' + campo.slug }} {{ 'campo-tipo-' + campo.tipo }}"
      [class.oculto]="campo.oculto"
    >
      <!-- Label -->
      <span
        class="label"
        *ngIf="
          mostrarLabel(PosicionLabelFormulario.TOP) ||
          mostrarLabel(PosicionLabelFormulario.IZQUIERDA)
        "
      >
        {{ campo.nombre }}
      </span>

      <div class="cont-input">
        <!-- Form Field -->
        <mat-form-field
          [class.readonly]="
            campo.readonly && campo.tipo === TipoCampoFormulario.OPCION_MULTIPLE
          "
          [class.mat-mdc-form-field-has-icon-suffix]="
            campo.tipo === TipoCampoFormulario.FECHA ||
            campo.tipo === TipoCampoFormulario.ARCHIVO
          "
          appearance="outline"
        >
          <mat-label *ngIf="mostrarLabel(PosicionLabelFormulario.DENTRO)">
            {{ campo.nombre }}
          </mat-label>

          <!-- Campo: Texto -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.TEXTO">
            <input
              matInput
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [readonly]="campo.readonly || false"
              type="{{
                campo.contrasena ? 'password' : campo.correo ? 'email' : 'text'
              }}"
            />
          </ng-container>

          <!-- Campo: Número -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.NUMERO">
            <span matTextPrefix *ngIf="campo.prefijo">{{ campo.prefijo }}</span>
            <input
              matInput
              type="number"
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [min]="campo.minimo || null"
              [max]="campo.maximo || null"
              [readonly]="campo.readonly || false"
            />
            <span matTextSuffix *ngIf="campo.sufijo">{{ campo.sufijo }}</span>
          </ng-container>

          <!-- Campo: Fecha -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.FECHA">
            <input
              matInput
              [matDatepicker]="picker"
              [placeholder]="campo.placeholder ?? ''"
              [formControlName]="i"
              [min]="campo.minimo || null"
              [max]="campo.maximo || null"
              [readonly]="campo.readonly || false"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
              *ngIf="!campo.readonly && !campo.disabled"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </ng-container>

          <!-- Campo: Archivo -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.ARCHIVO">
            <ngx-mat-file-input
              [formControlName]="i"
              [multiple]="campo.multiples || false"
              [accept]="campo.aceptados.join(',')"
              [class.readonly]="campo.readonly"
              [valuePlaceholder]="campo.placeholder ?? ''"
            >
            </ngx-mat-file-input>
            <button matSuffix mat-icon-button>
              <mat-icon>attach_file</mat-icon>
            </button>
          </ng-container>

          <!-- Campo: Opción Múltiple -->
          <ng-container
            *ngIf="campo.tipo === TipoCampoFormulario.OPCION_MULTIPLE"
          >
            <mat-select
              [formControlName]="i"
              [placeholder]="campo.placeholder ?? ''"
            >
              <mat-option
                *ngFor="let opcion of campo.opciones"
                [value]="opcion.valor"
              >
                {{ opcion.nombre }}
              </mat-option>
            </mat-select>
          </ng-container>

          <!-- Campo: Autocomplete -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.AUTOCOMPLETE">
            <input
              matInput
              type="text"
              [formControlName]="i"
              [placeholder]="campo.placeholder ?? ''"
              [matAutocomplete]="auto"
              [readonly]="campo.readonly || false"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let opcion of campo.opciones"
                [value]="opcion.valor"
                (onSelectionChange)="campo.opcionOut?.next(opcion)"
              >
                {{ opcion.nombre }}
              </mat-option>
            </mat-autocomplete>
          </ng-container>

          <!-- Campo: Checkbox -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.CHECKBOX">
            <input hidden="true" matInput readonly style="display: none" />
            <mat-checkbox
              [formControlName]="i"
              class="checkbox"
              [class.readonly]="campo.readonly"
            >
              {{ campo.placeholder }}
            </mat-checkbox>
          </ng-container>

          <!-- Campo: Coordenadas -->
          <ng-container *ngIf="campo.tipo === TipoCampoFormulario.COORDENADAS">
            <input hidden="true" matInput readonly style="display: none" />
            <span
              *ngIf="campo.readonly && !campo.valor"
              style="padding: 0 1rem"
            >
              Sin coordenadas
            </span>
            <google-map
              #mapa
              *ngIf="!campo.readonly || campo.valor"
              [class.readonly]="campo.readonly"
              [mapId]="campo.slug"
              [options]="CONFIGURACION_MAPA_FORMULARIO"
              [center]="
                campo.valor
                  ? { lat: campo.valor.latitud, lng: campo.valor.longitud }
                  : CENTRO_MAPA
              "
              width="100%"
              (mapClick)="ponerMarcadorMapa(campo, $event.latLng!.toJSON())"
            >
              <map-marker
                *ngIf="campos.controls[i].value as coords"
                [position]="{ lat: coords.latitud, lng: coords.longitud }"
              />
              <p
                *ngIf="
                  campo.placeholder &&
                  !campo.readonly &&
                  !campos.controls[i].value
                "
                class="placeholder"
              >
                {{ campo.placeholder }}
              </p>
            </google-map>
          </ng-container>
        </mat-form-field>

        <!-- Error -->
        <mat-error
          *ngIf="
            !configuracion.estilo?.esconderErrores &&
            campos.controls[i] as control
          "
        >
          <span *ngIf="control.invalid && (control.dirty || control.touched)">
            {{ obtenerErrorControl(campos.controls[i]) }}
          </span>
        </mat-error>
      </div>
    </div>
  </div>
</form>
