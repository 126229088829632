import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaAsincrona,
  FilaTabla,
  TipoColumnaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { OPCIONES_ESTADO_ORDEN } from '../../../utilidades/ordenes.static';
import {
  Permiso,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import { EstadoOrden } from 'src/app/ordenes/utilidades/ordenes.models';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { VerOrdenComponent } from '../../ver-orden/ver-orden.component';
import { MAX_WIDTH_FICHA_RECURSO } from 'src/app/compartido/recurso/utilidades/recurso.static';
import { AprobarOrdenComponent } from '../../aprobar-orden/aprobar-orden.component';
import { CONFIGURACION_FICHA_APROBAR_ORDEN } from '../../aprobar-orden/utilidades/aprobar-orden.static';
import { PrepararOrdenComponent } from '../../preparar-orden/preparar-orden.component';
import { CONFIGURACION_FICHA_PREPARAR_ORDEN } from '../../preparar-orden/utilidades/preparar-orden.static';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Configuración de la tabla de órdenes */
export const CONFIGURACION_TABLA_ORDENES: ConfiguracionTablaAsincrona = {
  columnas: [
    { slug: 'id', nombre: 'ID', ordenable: true },
    { slug: 'cliente', nombre: 'Cliente' },
    { slug: 'estado', nombre: 'Estado' },
    {
      slug: 'fecha_planeada',
      nombre: 'Fecha Planeada',
      ordenable: true,
      tipo: TipoColumnaTabla.FECHA,
    },
    { slug: 'referencia', nombre: 'N° Referencia' },
    // { slug: 'direccion', nombre: 'Dirección' },
    // { slug: 'items', nombre: 'Items', tipo: TipoColumnaTabla.NUMERO },
    { slug: 'formulas', nombre: 'Fórmulas', tipo: TipoColumnaTabla.NUMERO },
  ],
  busqueda: true,
  filtros: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // opciones se define dinámicamente en el componente
      roles: [RolUsuario.ELEVA],
    },
    {
      nombre: 'Estado',
      slug: 'estado',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [{ nombre: 'Todos', valor: '' }, ...OPCIONES_ESTADO_ORDEN],
    },
    {
      nombre: 'Desde',
      slug: 'desde',
      tipo: TipoCampoFormulario.FECHA,
    },
    {
      nombre: 'Hasta',
      slug: 'hasta',
      tipo: TipoCampoFormulario.FECHA,
    },
  ],
  botones: [
    {
      nombre: 'Descargar',
      slug: 'descargar',
      icono: Icono.DESCARGAR,
      autorizacion: { objeto: 'orden', permisos: [Permiso.VER_MASIVO] },
    },
    {
      nombre: 'Importar',
      slug: 'importar',
      icono: Icono.SUBIR,
      autorizacion: { objeto: 'orden', permisos: [Permiso.CREAR_MASIVO] },
    },
    {
      nombre: 'Crear',
      slug: 'crear',
      icono: Icono.CREAR,
      autorizacion: { objeto: 'orden', permisos: [Permiso.CREAR] },
    },
  ],
  acciones: [
    {
      nombre: 'Ver',
      slug: 'ver',
      icono: Icono.VER,
      autorizacion: { objeto: 'orden', permisos: [Permiso.VER] },
    },
    {
      nombre: 'Aprobar',
      slug: 'aprobar',
      icono: Icono.APROBACION,
      autorizacion: { objeto: 'orden', permisos: [Permiso.APROBAR] },
      ocultar: (fila: FilaTabla) =>
        fila['estado'].toLowerCase() !== EstadoOrden.PENDIENTE,
    },
    {
      nombre: 'Preparar',
      slug: 'preparar',
      icono: Icono.PREPARACION,
      autorizacion: { objeto: 'orden', permisos: [Permiso.PREPARAR] },
      ocultar: (fila: FilaTabla) =>
        fila['estado'].toLowerCase() !== EstadoOrden.CONFIRMADA,
    },
  ],
};

/* Detalles de la acciones de la tabla de órdenes */
export const DETALLES_ACCIONES_TABLA_ORDENES: Record<
  string,
  {
    componente: any;
    output?: string;
    segmentosUrl: string[];
    width?: number;
  }
> = {
  ver: {
    componente: VerOrdenComponent,
    segmentosUrl: ['ordenes'],
    width: MAX_WIDTH_FICHA_RECURSO,
  },
  aprobar: {
    componente: AprobarOrdenComponent,
    output: 'aprobada',
    segmentosUrl: ['ordenes', 'aprobar'],
    width: CONFIGURACION_FICHA_APROBAR_ORDEN.maxWidth,
  },
  preparar: {
    componente: PrepararOrdenComponent,
    output: 'preparada',
    segmentosUrl: ['ordenes', 'preparar'],
    width: CONFIGURACION_FICHA_PREPARAR_ORDEN.maxWidth,
  },
};
