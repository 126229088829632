<div
  class="ficha mat-elevation-z2"
  [ngStyle]="
    configuracion?.maxWidth
      ? { maxWidth: configuracion!.maxWidth + 'px', margin: 'auto' }
      : {}
  "
>
  <mat-toolbar
    class="header-ficha"
    *ngIf="configuracion !== undefined && configuracion.header !== undefined"
  >
    <div class="titulo-ficha">
      <mat-icon
        class="avatar-ficha"
        mat-card-avatar
        *ngIf="configuracion.header.icono"
      >
        {{ configuracion.header.icono }}
      </mat-icon>
      <span *ngIf="configuracion.header.titulo">{{
        configuracion.header.titulo
      }}</span>
    </div>
    <div
      class="botones-ficha"
      *ngIf="
        configuracion.header.botones && configuracion.header.botones!.length > 0
      "
    >
      <ng-container *ngFor="let boton of configuracion.header.botones">
        <ng-container
          *permisos="
            boton.autorizacion?.permisos ?? null;
            objeto: boton.autorizacion?.objeto ?? null
          "
        >
          <button
            *ngIf="boton.primario"
            class="boton"
            mat-flat-button
            (click)="clickBoton.next(boton.slug)"
            color="primary"
          >
            {{ boton.nombre }} <mat-icon>{{ boton.icono }}</mat-icon>
          </button>
          <button
            *ngIf="!boton.primario"
            class="boton"
            mat-icon-button
            (click)="clickBoton.next(boton.slug)"
            color="primary"
            [matTooltip]="boton.nombre"
          >
            <mat-icon>{{ boton.icono }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </mat-toolbar>

  <div
    class="contenido-ficha"
    [ngClass]="{ padding: configuracion?.contenido?.padding }"
  >
    <ng-content></ng-content>
  </div>

  <div class="cargando-ficha" *ngIf="cargando">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
