import {
  ClienteDTO,
  OrganizacionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { FormateadorDireccion } from '../../../../../compartido/utilidades/formateador-direccion.class';
import { ClientesService } from 'src/app/clientes/utilidades/clientes.service';
import { inject } from '@angular/core';
import {
  DatosSpreadsheet,
  Spreadsheet,
} from 'src/app/compartido/utilidades/spreadsheet.class';
import { EstadoFilaOrden, ResultadoSubida } from './archivo-ordenes.models';
import { COLUMNAS_ARCHIVO_ORDEN } from './archivo-ordenes.static';
import { FilaOrden } from '../fila-orden.class';
import {
  arrayADiccionario,
  dividirEnLotes,
} from 'src/app/compartido/utilidades/array.utils';
import { stringVacío } from 'src/app/compartido/utilidades/valores.utils';
import { OrdenesService } from 'src/app/ordenes/utilidades/ordenes.service';
import { OrdenBase } from 'src/app/ordenes/utilidades/ordenes.models';
import { AccionMasivaDTO } from 'src/app/compartido/utilidades/compartido.models';
import { Subject } from 'rxjs';

export class ArchivoOrdenes {
  /* Servicios */
  private readonly _clientesService: ClientesService = inject(ClientesService);
  private readonly _ordenesService: OrdenesService = inject(OrdenesService);

  /* Archivo */
  private _archivo: File | undefined;

  /* Si el archivo es válido */
  private _valido: boolean = false;
  public get valido(): boolean {
    return this._valido;
  }

  /* Descripción del último error ocurrido */
  private _error: string = '';
  public get error(): string {
    return this._error;
  }

  /* Filas representando una orden */
  public filas: FilaOrden[] = [];

  /* Getters de filas por estado */
  public get filasIncompletas(): FilaOrden[] {
    return this.obtenerFilasPorEstado(EstadoFilaOrden.INCOMPLETA);
  }
  public get filasSinFormatear(): FilaOrden[] {
    return this.obtenerFilasPorEstado(EstadoFilaOrden.SIN_FORMATEAR);
  }
  public get filasFormateadas(): FilaOrden[] {
    return this.obtenerFilasPorEstado(EstadoFilaOrden.FORMATEADA);
  }
  public get filasFallidas(): FilaOrden[] {
    return this.obtenerFilasPorEstado(EstadoFilaOrden.FALLIDA);
  }
  public filasSubidas: FilaOrden[] = [];

  /**
   * Devuelve las filas con el estado proveído.
   * @returns {FilaOrden[]} - Las filas con el estado proveído.
   * @author Juan Corral
   */
  private obtenerFilasPorEstado(estado: EstadoFilaOrden): FilaOrden[] {
    return this.filas.filter((fila) => fila.estado === estado);
  }

  /**
   * Establece y procesa el archivo.
   * @param {File} archivo - El archivo a procesar.
   * @param {OrganizacionDTO} organizacion - La organización a la que pertenecen las órdenes.
   * @param {string} fechaPlaneada - La fecha planeada de las órdenes.
   * @param {number} hoja [Opcional] - El índice de la hoja de cálculo donde están los datos.
   * @param {number} encabezado [Opcional] - El índice de la fila donde están los encabezados de las columnas.
   * @returns {Promise<boolean>} - Si la validación de datos fue exitosa o no (promise).
   * @author Juan Corral
   */
  public async establecerArchivo$(
    archivo: File,
    organizacion: OrganizacionDTO,
    fechaPlaneada: string,
    hoja: number = 0,
    encabezado: number = 0,
    farmacia?: string,
  ): Promise<void> {
    // Establecer variables
    this._archivo = archivo;
    this._valido = true;
    this._error = '';
    this.filas = [];

    // Leer archivo
    const spreadsheet: Spreadsheet = await Spreadsheet.fromArchivo$(
      this._archivo,
      null,
      hoja,
      encabezado,
    );

    // Validar columnas requeridas
    spreadsheet.normalizarColumnas();
    if (!this.validarColumnas(spreadsheet)) return;

    // Agregar columnas de organización y fecha planeada
    spreadsheet.agregarColumna('id_organizacion', organizacion.id.toString());
    spreadsheet.agregarColumna('alias_organizacion', organizacion.alias);
    spreadsheet.agregarColumna('fecha_planeada', fechaPlaneada);
    if (farmacia) spreadsheet.agregarColumna('farmacia', farmacia);

    // Procesar las filas del archivo
    for (let i = 0; i < spreadsheet.filas.length; i++) {
      // Crear fila
      const fila = FilaOrden.fromSpreadsheet(spreadsheet, i);

      // Notifica si la fila está incompleta
      if (fila.estado === EstadoFilaOrden.INCOMPLETA) {
        fila.errores = 'La fila tiene algunas celdas vacías';
        this._error = 'El archivo tiene algunas filas incompletas';
        this._valido = false;
      }

      this.filas.push(fila);
    }
  }

  /**
   * Valida y renombra las columnas del archivo.
   * @param {Spreadsheet} spreadsheet - El spreadsheet a validar.
   * @returns {boolean} - Si el spreadsheet tiene las columnas requeridas o no.
   */
  private validarColumnas(spreadsheet: Spreadsheet): boolean {
    for (let columna of COLUMNAS_ARCHIVO_ORDEN) {
      let encontrada = false;
      for (let palabra of columna.palabras) {
        if (spreadsheet.tieneColumna(palabra, true)) {
          spreadsheet.renombrarColumna(palabra, columna.slug, true);
          encontrada = true;
          break;
        }
      }
      if (!encontrada && columna.requerida) {
        this._error = 'El archivo no contiene la columna: ' + columna.nombre;
        this._valido = false;
        return false;
      }
    }
    return true;
  }

  /**
   * Formatea la dirección de cada orden.
   * @author Juan Corral
   */
  public async formatearDirecciones$() {
    const lotes = dividirEnLotes<FilaOrden>(100, this.filas);
    for (const lote of lotes) {
      const cedulas = lote.map((fila) => fila.datos.cliente.cedula);
      const params = { cedulas: cedulas.toString(), limit: cedulas.length };
      const clientes: ClienteDTO[] = (
        await this._clientesService.obtenerClientes$(params)
      ).results;
      // Crear diccionario de clientes
      const clienteDict: Record<string, ClienteDTO> =
        arrayADiccionario<ClienteDTO>(clientes, 'cedula');

      // Itere sobre las filas
      for (const fila of lote) {
        // Comprobar si la dirección ya está formateada
        const cliente = clienteDict[fila.datos.cliente.cedula.toString()];
        let encontrada = false;
        if (cliente !== undefined) {
          for (const dir of cliente.direcciones) {
            if (
              fila.datos.direccion.direccion.trim() == dir.direccion.trim() &&
              !stringVacío(dir.direccion_formateada)
            ) {
              fila.datos.direccion.direccion_formateada =
                dir.direccion_formateada;
              fila.datos.direccion.detalles = dir.detalles;
              encontrada = true;
            }
          }
        }
        if (encontrada) {
          fila.estado = EstadoFilaOrden.FORMATEADA;
          continue;
        }

        // Formatear dirección
        const formateador = new FormateadorDireccion(
          fila.datos.direccion.direccion,
          fila.datos.direccion.detalles,
        );
        const exito = formateador.formatearDireccion();
        if (exito) {
          fila.datos.direccion.direccion_formateada =
            formateador.direccionFormateada;
          fila.datos.direccion.detalles = formateador.detalles;
          fila.estado = EstadoFilaOrden.FORMATEADA;
        } else {
          fila.opcionesDireccion = cliente?.direcciones ?? [];
          this._error =
            'Algunas direcciones no pudieron ser formateadas correctamente';
          fila.estado = EstadoFilaOrden.SIN_FORMATEAR;
        }
      }
    }
  }

  /**
   * Sube las órdenes al servidor.
   * @param {Subject<ResultadoSubida>} resultadoSubject - El subject para notificar el resultado de la subida.
   * @returns {Promise<ResultadoSubida>} - El resultado de la subida de las órdenes (promise).
   * @author Juan Corral
   */
  public async subirOrdenes$(
    resultadoSubject: Subject<ResultadoSubida>,
  ): Promise<ResultadoSubida> {
    const resultado: ResultadoSubida = {
      exitosos: 0,
      fallidos: 0,
      total: 0,
    };
    const filas = this.filas.map((fila) => fila.datos);
    const lotes = dividirEnLotes<OrdenBase>(30, filas);
    for (const [i, lote] of lotes.entries()) {
      const respuesta: AccionMasivaDTO<string, object> =
        await this._ordenesService.crearOrdenes$(lote);
      for (const [j, orden] of respuesta.resultado.entries()) {
        const fila = this.filas[i * 30 + j];
        if (orden.error) {
          fila.errores = JSON.stringify(orden.error);
          fila.estado = EstadoFilaOrden.FALLIDA;
          resultado.fallidos++;
        } else {
          fila.estado = EstadoFilaOrden.SUBIDA;
          this.filasSubidas.push(fila);
          resultado.exitosos++;
        }
        resultado.total++;
        resultadoSubject.next(resultado);
      }
    }
    return resultado;
  }

  /**
   * Descarga un archivo csv con las filas fallidas.
   * @author Juan Corral
   */
  public descargarFallidas(): void {
    const datosSpreadsheet: DatosSpreadsheet = this.filasFallidas.map((fila) =>
      fila.toFilaSpreadsheet(),
    );
    const spreadsheet = new Spreadsheet('Fallidos', datosSpreadsheet);
    spreadsheet.descargarCSV();
  }

  /**
   * Descarga un archivo csv con las filas subidas.
   * @author Juan Corral
   */
  public descargarSubidas(): void {
    const datosSpreadsheet: DatosSpreadsheet = this.filasSubidas.map((fila) =>
      fila.toFilaSpreadsheet(),
    );
    const spreadsheet = new Spreadsheet('Subidos', datosSpreadsheet);
    spreadsheet.descargarCSV();
  }
}
