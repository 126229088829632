import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { CONFIGURACION_FORMULARIO_BIENVENIDA } from './utilidades/bienvenida.static';
import { Subscription } from 'rxjs';
import { PlacesService } from 'src/app/nucleo/utilidades/places.service';
import { ValoresFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ClientesService } from 'src/app/clientes/utilidades/clientes.service';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import {
  ClienteDTO,
  DireccionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';

@Component({
  selector: 'nucleo-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BienvenidaComponent implements OnInit {
  /* Suscripción */
  private readonly _suscripcion = new Subscription();

  /* Emisores de eventos */
  @Output() protected completado = new EventEmitter();

  /* Formulario de dirección */
  protected configFormulario = deepCopy(CONFIGURACION_FORMULARIO_BIENVENIDA);
  protected validezFormulario: boolean = false;
  protected valorFormulario: ValoresFormulario | null = null;

  constructor(
    private readonly _autenticacionService: AutenticacionService,
    private readonly _alertasService: AlertasService,
    private readonly _placesService: PlacesService,
    private readonly _clientesService: ClientesService,
  ) {}

  ngOnInit(): void {
    // Auto-completar la dirección
    this._placesService
      .configurarAutocompleteDireccion(this.configFormulario.campos)
      .then((suscripcion) => this._suscripcion.add(suscripcion));
  }

  /**
   * Crea una dirección para el cliente.
   * @author Juan Corral
   */
  protected async _crearDireccion$(): Promise<void> {
    if (!this.validezFormulario)
      throw new ErrorDesarrollo('Formulario inválido');

    const cliente = (await this._autenticacionService.usuario$)!.cliente;
    if (!cliente) throw new ErrorDesarrollo('No se encontró el cliente');

    const valores = this.valorFormulario!;

    const direccion: DireccionDTO = {
      id: 0,
      direccion: valores['direccion'],
      direccion_formateada: valores['direccion_formateada'],
      detalles: valores['detalles'],
      barrio: valores['barrio'],
      localidad: valores['localidad'],
      ciudad: valores['ciudad'],
      latitud: valores['coordenadas']['latitud'],
      longitud: valores['coordenadas']['longitud'],
      creacion_manual: true,
    };

    const datos: ClienteDTO = {
      cedula: cliente.cedula,
      nombre: cliente.nombre,
      telefono: cliente.telefono,
      correo: cliente.correo,
      organizacion: cliente.organizacion,
      direcciones: [direccion],
    };

    this._clientesService
      .actualizarCliente$(cliente.cedula, datos)
      .then(() => {
        this._alertasService.alertarExito('Actualización exitosa');
        this._autenticacionService.limpiarUsuario();
        this.completado.emit();
      })
      .catch((error: ErrorFrontend) =>
        this._alertasService.alertarError('Error: ' + error.message),
      );
  }
}
