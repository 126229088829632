import {
  CampoFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionRecurso,
  SeccionRecurso,
} from 'src/app/compartido/recurso/utilidades/recurso.models';
import {
  OPCIONES_ESTADO_ITEM_ORDEN,
  OPCIONES_ESTADO_ORDEN,
} from '../../../utilidades/ordenes.static';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Campos de la sección: Información */
const CAMPOS_INFORMACION_ORDEN: CampoFormulario[] = [
  {
    nombre: 'Cliente',
    slug: 'cliente',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Estado',
    slug: 'estado',
    tipo: TipoCampoFormulario.OPCION_MULTIPLE,
    opciones: OPCIONES_ESTADO_ORDEN,
  },
  {
    nombre: 'Fecha de Creación',
    slug: 'fechaCreacion',
    tipo: TipoCampoFormulario.FECHA,
  },
  {
    nombre: 'Fecha Planeada',
    slug: 'fechaPlaneada',
    tipo: TipoCampoFormulario.FECHA,
  },
  {
    nombre: 'N° Referencia',
    slug: 'referencia',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Notas',
    slug: 'notas',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Domicilio',
    slug: 'domicilio',
    tipo: TipoCampoFormulario.TEXTO,
  },
];

/* Campos de la sección: Dirección */
const CAMPOS_DIRECCION_ORDEN: CampoFormulario[] = [
  // {
  //   nombre: 'ID',
  //   slug: 'id',
  //   tipo: TipoCampoFormulario.NUMERO,
  // },
  {
    nombre: 'Dirección',
    slug: 'direccion',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Formateada',
    slug: 'direccion_formateada',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Detalles',
    slug: 'detalles',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Barrio',
    slug: 'barrio',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Localidad',
    slug: 'localidad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Ciudad',
    slug: 'ciudad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Latitud',
    slug: 'latitud',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Longitud',
    slug: 'longitud',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Coordenadas',
    slug: 'coordenadas',
    tipo: TipoCampoFormulario.COORDENADAS,
  },
];

/* Configuración del recurso: Orden */
export const CONFIGURACION_RECURSO_ORDEN: ConfiguracionRecurso = {
  nombre: '', // Se obtiene de la orden
  icono: Icono.ORDENES,
  objeto: 'orden',
  secciones: [
    {
      nombre: 'Información',
      slug: 'informacion',
      editable: false,
      subsecciones: [
        {
          slug: 'informacion',
          formulario: { campos: CAMPOS_INFORMACION_ORDEN },
          readonly: ['fechaCreacion', 'cliente'],
        },
      ],
    },
    {
      nombre: 'Dirección',
      slug: 'direccion',
      editable: false,
      subsecciones: [
        {
          slug: 'direccion',
          formulario: { campos: CAMPOS_DIRECCION_ORDEN },
        },
      ],
    },
  ],
};

/* Campos de la sección: Items */
export const CAMPOS_ITEMS_ORDEN: CampoFormulario[] = [
  {
    nombre: 'Producto',
    slug: 'producto',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Estado',
    slug: 'estado',
    tipo: TipoCampoFormulario.OPCION_MULTIPLE,
    opciones: OPCIONES_ESTADO_ITEM_ORDEN,
  },
  {
    nombre: 'Cantidad',
    slug: 'cantidad',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Cantidad Preparada',
    slug: 'cantidad_preparada',
    tipo: TipoCampoFormulario.NUMERO,
  },
];

/* Configuración de la sección: Items */
export const SECCION_ITEMS_ORDEN: SeccionRecurso = {
  nombre: 'Items',
  slug: 'items',
  editable: false,
  subsecciones: [],
};

/* Campos de la sección: Fórmulas */
export const CAMPOS_FORMULAS_ORDEN: CampoFormulario[] = [
  {
    nombre: 'Referencia',
    slug: 'referencia',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Archivo',
    slug: 'archivo',
    tipo: TipoCampoFormulario.TEXTO,
  },
];

/* Configuración de la sección: Fórmulas */
export const SECCION_FORMULAS_ORDEN: SeccionRecurso = {
  nombre: 'Fórmulas',
  slug: 'formulas',
  editable: false,
  subsecciones: [],
};

/* Configuración de la sección: Aprobación */
export const SECCION_APROBACION_ORDEN: SeccionRecurso = {
  nombre: 'Aprobación',
  slug: 'aprobacion',
  editable: false,
  subsecciones: [
    {
      slug: 'aprobacion',
      formulario: {
        campos: [
          {
            nombre: 'Fecha',
            slug: 'fechaAprobacion',
            tipo: TipoCampoFormulario.FECHA,
          },
          {
            nombre: 'Autor',
            slug: 'autorAprobacion',
            tipo: TipoCampoFormulario.TEXTO,
          },
          {
            nombre: 'Motivo',
            slug: 'motivoAprobacion',
            tipo: TipoCampoFormulario.TEXTO,
          },
        ],
      },
    },
  ],
};
