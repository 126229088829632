import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ComunicacionesService } from '../../utilidades/comunicaciones.service';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  CONFIGURACION_FICHA_ENVIAR_SMS,
  CONFIGURACION_FORMULARIO_ENVIAR_SMS,
} from './utilidades/enviar-sms.static';
import { firstValueFrom, Subject, tap } from 'rxjs';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AccionMasivaDTO } from 'src/app/compartido/utilidades/compartido.models';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';

@Component({
  templateUrl: './enviar-sms.component.html',
  styleUrls: ['./enviar-sms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnviarSMSComponent implements OnInit {
  /* Configuración Ficha */
  protected configFicha: ConfiguracionFicha = deepCopy(
    CONFIGURACION_FICHA_ENVIAR_SMS,
  );

  /* Formulario */
  protected configFormulario: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_ENVIAR_SMS,
  );
  protected validezFormulario: boolean = false;
  protected valorFormulario: ValoresFormulario | null = null;

  /* Mensajes Creados */
  protected creados: AccionMasivaDTO<number, string> = {
    exitosos: 0,
    fallidos: 0,
    total: 0,
    resultado: [],
  };

  /* Cargando */
  protected cargando: boolean = false;

  constructor(
    private readonly _comunicacionesService: ComunicacionesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _alertasService: AlertasService,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.id,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].id);
          campoOrg.readonly = true;
        }
      });
  }

  /**
   * Escucha los cambios en el formulario
   * @param {ValoresFormulario} valor - Los valores del formulario
   * @author Juan Corral
   */
  protected escucharCambiosFormulario(valor: ValoresFormulario): void {
    this.valorFormulario = valor;
    if (!valor['organizacion']) return;
    const campoTexto = obtenerCampo(this.configFormulario, 'texto')!;
    if (campoTexto.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo texto debe ser de tipo OPCION_MULTIPLE',
      );
    this._comunicacionesService
      .obtenerTextoMensajes$(valor['organizacion'])
      .then((mensajes) => {
        campoTexto.opciones = mensajes.results.map((mensaje) => ({
          nombre: mensaje.nombre,
          valor: mensaje.id,
        }));
      });
  }

  /**
   * Establece el estado de carga de la página
   * @param {boolean} estado - El estado de carga
   * @author Juan Corral
   */
  protected establecerCargando(estado: boolean): void {
    this.cargando = estado;
    this._cdr.markForCheck();
  }

  /**
   * Envía los SMS de los domicilios seleccionados
   * @author Juan Corral
   */
  protected async enviarSms() {
    // Activar cargando
    this.establecerCargando(true);

    // Crear y enviar mensajes SMS
    this._comunicacionesService
      .crearMensajesSMS$(
        this.valorFormulario!['organizacion'],
        this.valorFormulario!['fecha'],
        this.valorFormulario!['texto'],
      )
      .then((resultado: AccionMasivaDTO<number, string>) => {
        this.establecerCargando(false);
        this.creados = resultado;
        this._comunicacionesService.enviarMensajesSMS$();
      })
      .catch((error: ErrorFrontend) => {
        this.establecerCargando(false);
        this._alertasService.alertarError('Error: ' + error.message);
      });
  }
}
