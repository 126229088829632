<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Formulario -->
    <mat-step [completed]="validezFormulario" [editable]="false">
      <ng-template matStepLabel>Selección</ng-template>

      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="valorFormulario = $event"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="buscarOrdenes()"
        [disabled]="!validezFormulario"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Órdenes -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Preparación</ng-template>

      <p *ngIf="dataSourceOrdenes.total > 0" class="texto">
        Las siguientes órdenes están pendientes de preparar:
      </p>

      <p
        *ngIf="dataSourceOrdenes.total === 0 && preparadas.length === 0"
        class="texto color-warn"
      >
        ¡No hay órdenes pendientes de preparar para esta selección!
      </p>

      <p
        *ngIf="dataSourceOrdenes.total === 0 && preparadas.length > 0"
        class="texto"
      >
        ¡Todas las órdenes han sido preparadas!
      </p>

      <compartido-tabla-asincrona
        #tablaOrdenes
        class="tabla {{ responsiveService.claseCSS$ | async }}"
        [class.oculta]="dataSourceOrdenes.total === 0"
        [dataSource]="dataSourceOrdenes"
        [configuracion]="configTablaOrdenes"
        (clickAccion)="prepararOrden$($event.fila['id'])"
      ></compartido-tabla-asincrona>

      <p *ngIf="preparadas.length > 0" class="texto preparadas">
        Órdenes preparadas: {{ preparadas.length }}
      </p>

      <button
        *ngIf="dataSourceOrdenes.total > 0 || preparadas.length > 0"
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        [disabled]="preparadas.length === 0"
        (click)="buscarDomicilios$()"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
      <button
        *ngIf="dataSourceOrdenes.total === 0 && preparadas.length === 0"
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        routerLink="/"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 3: Etiquetas -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Etiquetas</ng-template>

      <p class="texto">
        Selecciona las órdenes para las cuales deseas generar la etiqueta:
      </p>

      <compartido-tabla-asincrona
        #tablaEtiquetas
        class="tabla {{ responsiveService.claseCSS$ | async }}"
        [dataSource]="dataSourceEtiquetas"
        [configuracion]="configTablaEtiquetas"
        (seleccionados)="seleccionEtiquetas = $event"
      ></compartido-tabla-asincrona>

      <button
        *ngIf="seleccionEtiquetas.estado !== EstadoSeleccion.VACIA"
        class="boton-stepper"
        mat-flat-button
        color="primary"
        (click)="generarEtiquetas()"
      >
        Generar Etiquetas<mat-icon>receipt_long</mat-icon>
      </button>
      <button
        *ngIf="seleccionEtiquetas.estado === EstadoSeleccion.VACIA"
        class="boton-stepper"
        mat-flat-button
        color="primary"
        routerLink="/"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
