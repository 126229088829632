import { ConfiguracionTablaSincrona } from 'src/app/compartido/tabla/utilidades/tabla.models';

/* Configuración de la tabla de domicilios con error */
export const CONFIGURACION_TABLA_DOMICILIOS_CON_ERROR: ConfiguracionTablaSincrona =
  {
    columnas: [
      { slug: 'referencia', nombre: 'N° Referencia' },
      { slug: 'titulo', nombre: 'Título' },
      { slug: 'estado', nombre: 'Estado' },
      { slug: 'error', nombre: 'Error' },
    ],
  };
