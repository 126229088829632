import { Injectable } from '@angular/core';
import {
  LayoutModule,
  BreakpointObserver,
  Breakpoints,
} from '@angular/cdk/layout';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root', deps: [LayoutModule] })
export class ResponsiveService {
  constructor(private readonly _breakpointService: BreakpointObserver) {}

  /* Indicador de móvil vertical */
  public get movilVertical$(): Observable<boolean> {
    return this._breakpointService
      .observe(Breakpoints.HandsetPortrait)
      .pipe(map((result) => result.matches));
  }

  /* Indicador de móvil horizontal */
  public get movilHorizontal$(): Observable<boolean> {
    return this._breakpointService
      .observe(Breakpoints.HandsetLandscape)
      .pipe(map((result) => result.matches));
  }

  /* Indicador de tablet vertical */
  public get tabletVertical$(): Observable<boolean> {
    return this._breakpointService
      .observe(Breakpoints.TabletPortrait)
      .pipe(map((result) => result.matches));
  }

  /* Indicador de tablet horizontal */
  public get tabletHorizontal$(): Observable<boolean> {
    return this._breakpointService
      .observe(Breakpoints.TabletLandscape)
      .pipe(map((result) => result.matches));
  }

  /* Clase CSS de acuerdo al tamaño de la pantalla */
  public get claseCSS$(): Observable<string> {
    return combineLatest([
      this.movilVertical$,
      this.movilHorizontal$,
      this.tabletVertical$,
      this.tabletHorizontal$,
    ]).pipe(
      map(([mV, mH, tV, tH]) => {
        if (mV) return 'res-movil-vertical';
        if (mH) return 'res-movil-horizontal';
        if (tV) return 'res-tablet-vertical';
        if (tH) return 'res-tablet-horizontal';
        return 'res-escritorio';
      }),
    );
  }
}
