/* Formato de fecha para provider de moment */
export const FORMATO_FECHA = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/* Icono de la plataforma */
export enum Icono {
  // Órdenes
  ORDENES = 'receipt', // insert_drive_file // description
  CREAR_ORDEN = 'note_add',
  APROBACION = 'verified',
  PREPARACION = 'shopping_bag', // 'shopping_basket',

  // Domicilios
  DOMICILIOS = 'location_on', // 'house' // 'local_shipping',
  DESPACHO = 'qr_code_scanner',
  ETIQUETAS = 'receipt_long', // 'sticky_note_2'
  REPROGRAMAR = 'event_repeat', // 'repartition',

  // Paquetes
  PAQUETES = 'inventory',

  // Comunicaciones
  SMS = 'sms',
  ENVIAR_SMS = 'send',

  // Clientes
  CLIENTES = 'group',
  VERIFICACIONES = 'fingerprint', // verified_user // how_to_reg
  PERFIL = 'person',

  // Acciones
  CREAR = 'add',
  VER = 'visibility',
  EDITAR = 'mode_edit',
  ELIMINAR = 'delete',
  GUARDAR = 'save',
  CANCELAR = 'cancel',
  DESCARGAR = 'download',
  SUBIR = 'upload',
  CERRAR = 'close',

  // Otros
  ERROR = 'error',
  ERROR_SINCRONIZACION = 'sync_problem',
  ADVERTENCIA = 'warning',
}

/* Imágenes de la plataforma */
export enum Imagenes {
  // Órdenes
  ORDENES = 'ordenes',
  CREAR_ORDEN = 'crear_orden',
  APROBACION = 'aprobacion2',
  PREPARACION = 'preparacion2',

  // Domicilios
  DOMICILIOS = 'domicilios',
  DESPACHO = 'despacho',
  REPROGRAMAR = 'reprogramar',

  // Paquetes
  PAQUETES = 'paquetes2',

  // Comunicaciones
  SMS = 'sms',

  // Clientes
  CLIENTES = 'clientes',
  VERIFICACIONES = 'verificaciones2',
  PERFIL = 'clientes',
}
