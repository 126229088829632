import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';

/* Configuración de la ficha de re-programar domicilio */
export const CONFIGURACION_FICHA_REPROGRAMAR_DOMICILIO: ConfiguracionFicha = {
  header: { titulo: 'Re-programar Domicilio', icono: Icono.REPROGRAMAR },
  contenido: { padding: true },
  maxWidth: 1000,
};

/* Configuración del formulario de re-programar domicilio */
export const CONFIGURACION_FORMULARIO_REPROGRAMAR_DOMICILIO: ConfiguracionFormulario =
  {
    campos: [
      {
        nombre: 'Re-programar para',
        slug: 'fecha',
        tipo: TipoCampoFormulario.FECHA,
        requerido: true,
        minimo: formatearFecha(Date.now() + 86400000),
      },
    ],
    estilo: {
      posicionLabel: PosicionLabelFormulario.TOP,
      widthCampo: 350,
      widthLabel: 350,
    },
  };
