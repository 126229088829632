<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Formulario -->
    <mat-step [completed]="validezFormulario" [editable]="false">
      <ng-template matStepLabel>Selección</ng-template>

      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="valorFormulario = $event"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="buscarDomicilios()"
        [disabled]="!validezFormulario"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Domicilios -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Re-programar</ng-template>

      <p *ngIf="dataSource.total > 0" class="texto">
        Los siguientes domicilios fallaron y pueden ser re-programados:
      </p>

      <p
        *ngIf="dataSource.total === 0 && reprogramados.length === 0"
        class="texto color-warn"
      >
        ¡No hay domicilios por re-programar en esta selección!
      </p>

      <p
        *ngIf="dataSource.total === 0 && reprogramados.length > 0"
        class="texto"
      >
        ¡Todos los domicilios han sido re-programados!
      </p>

      <compartido-tabla-asincrona
        #tabla
        class="tabla {{ responsiveService.claseCSS$ | async }}"
        [class.oculta]="dataSource.total === 0"
        [dataSource]="dataSource"
        [configuracion]="configTabla"
        (clickAccion)="reprogramarDomicilio$($event.fila['id'])"
      ></compartido-tabla-asincrona>

      <p *ngIf="reprogramados.length > 0" class="texto reprogramados">
        Domicilios re-programados: {{ reprogramados.length }}
      </p>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        routerLink="/"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
