import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogoVistaPreviaComponent } from './dialogo-vista-previa/dialogo-vista-previa.component';
import { DialogoAccionComponent } from './dialogo-accion/dialogo-accion.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [DialogoVistaPreviaComponent, DialogoAccionComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class DialogosModule {}
