import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { UsuarioDTO } from './autenticacion.models';
import { TokenService } from './token.service';
import { ListaDTO } from 'src/app/compartido/utilidades/compartido.models';
import { AutorizacionService } from 'src/app/nucleo/autorizacion/autorizacion.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient, TokenService, AutorizacionService, Router],
})
export class AutenticacionService {
  /** Usuario */
  private _usuario: UsuarioDTO | undefined;
  private _usuarioPromise$: Promise<UsuarioDTO | undefined> | undefined;
  public get usuario$(): Promise<UsuarioDTO | undefined> {
    if (this._usuarioPromise$) return this._usuarioPromise$;
    if (this._usuario) return Promise.resolve(this._usuario);
    this._usuarioPromise$ = this._obtenerUsuario$().then(
      (usuario: UsuarioDTO | undefined) => {
        this._usuario = usuario;
        this._usuarioPromise$ = undefined;
        return usuario;
      },
    );
    return this._usuarioPromise$;
  }

  constructor(
    private readonly _http: HttpClient,
    private readonly _tokenService: TokenService,
    private readonly _autorizacionService: AutorizacionService,
    private readonly _router: Router,
  ) {}

  /**
   * Obtiene el usuario autenticado del servidor.
   * @returns {Promise<UsuarioDTO | undefined>} - El usuario autenticado, si existe (promise).
   * @author Juan Corral
   */
  private async _obtenerUsuario$(): Promise<UsuarioDTO | undefined> {
    return await firstValueFrom(
      this._http
        .get<ListaDTO<UsuarioDTO>>('autenticacion/usuarios/')
        .pipe(
          map((usuarios: ListaDTO<UsuarioDTO>) =>
            usuarios.count > 0 ? usuarios.results[0] : undefined,
          ),
        ),
    ).catch(() => undefined);
  }

  /**
   * Cierra sesión del usuario en el servidor.
   * @author Juan Corral
   */
  public async logout$(): Promise<void> {
    await firstValueFrom(
      this._http.post('autenticacion/logout/', {
        token: this._tokenService.refreshToken,
      }),
    );
    this.limpiarUsuario();
    this._tokenService.limpiarToken();
    this._autorizacionService.limpiarPermisos();
    this._router.navigate(['ingreso', 'login']);
  }

  /**
   * Limpia el usuario autenticado.
   * @autor Juan Corral
   */
  public limpiarUsuario(): void {
    this._usuario = undefined;
    this._usuarioPromise$ = undefined;
  }
}
