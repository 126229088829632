import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConfiguracionRecurso,
  SeccionRecurso,
  SubseccionRecurso,
  ValoresRecurso,
} from './utilidades/recurso.models';
import { deepCopy } from '../utilidades/objetos.utils';
import { ValoresFormulario } from '../formulario/utilidades/formulario.models';
import { FormularioComponent } from '../formulario/formulario.component';
import { ConfiguracionFicha } from '../ficha/utilidades/ficha.models';
import { FichaComponent } from '../ficha/ficha.component';
import {
  BOTON_MODO_EDICION,
  ESTILO_FORMULARIO_RECURSO,
  MAX_WIDTH_FICHA_RECURSO,
} from './utilidades/recurso.static';
import { AutorizacionService } from 'src/app/nucleo/autorizacion/autorizacion.service';
import { ResponsiveService } from '../../nucleo/utilidades/responsive.service';
import { combineLatest, startWith, Subject, Subscription } from 'rxjs';
import { Permiso } from 'src/app/nucleo/autorizacion/autorizacion.models';

@Component({
  selector: 'compartido-recurso',
  templateUrl: './recurso.component.html',
  styleUrls: ['./recurso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecursoComponent implements OnInit, OnChanges, OnDestroy {
  /* Suscripción  */
  private readonly _suscripcion: Subscription = new Subscription();

  /* Configuración del recurso */
  @Input({
    required: true,
    transform: (obj: ConfiguracionRecurso | null) => deepCopy(obj),
  })
  configuracion!: ConfiguracionRecurso | null;

  /* Configuración Ficha */
  protected configFicha: ConfiguracionFicha | undefined;

  /* Modo de edición */
  protected modoEdicion: boolean = false;
  private _valores: ValoresRecurso = {};
  private _configuracionOriginal: ConfiguracionRecurso | null = null;

  /* Subject de Edición */
  @Output() protected edicion: Subject<ValoresRecurso> =
    new Subject<ValoresRecurso>();

  /* Referencia del componente de la ficha */
  @ViewChild(FichaComponent) private _fichaRef!: FichaComponent;

  /* Referencia del componente del formulario */
  @ViewChild(FormularioComponent) private _formularioRef!: FormularioComponent;

  /* Responsiveness Emitter */
  private _responsiveEmitter = new EventEmitter<void>();

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _autorizacionService: AutorizacionService,
    private readonly _responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Responsiveness Formularios
    const escucharResMovil = combineLatest([
      this._responsiveService.movilVertical$,
      this._responsiveService.movilHorizontal$,
      this._responsiveEmitter.pipe(startWith(null)),
    ]).subscribe(([mV, mH]) => {
      if (!mV && !mH) return;
      for (const seccion of this.configuracion!.secciones ?? []) {
        for (const subseccion of seccion.subsecciones) {
          subseccion.formulario.estilo = {
            ...ESTILO_FORMULARIO_RECURSO,
            widthCampo: 150,
            widthLabel: 150,
          };
        }
      }
      this._cdr.markForCheck();
    });
    this._suscripcion.add(escucharResMovil);
  }

  ngOnChanges(): void {
    // Configurar formularios
    for (const seccion of this.configuracion?.secciones ?? []) {
      for (const subseccion of seccion.subsecciones) {
        subseccion.formulario.estilo = { ...ESTILO_FORMULARIO_RECURSO };
        for (const campo of subseccion.formulario.campos) {
          campo.readonly = true;
        }
      }
    }

    // Inicializar valores
    this._valores = {};

    // Configuracion Original (para cancelar cambios)
    this._configuracionOriginal = deepCopy(
      this.configuracion,
    ) as ConfiguracionRecurso;

    // Configuración Ficha
    this.configFicha = {
      header: {
        titulo: this.configuracion?.nombre,
        icono: this.configuracion?.icono,
      },
      maxWidth: MAX_WIDTH_FICHA_RECURSO,
    };
    if (this.configuracion?.secciones.some((s) => s.editable))
      this._mostrarBotonEdicion();

    this._responsiveEmitter.emit();
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Muestra/oculta el botón de editar.
   * @author Juan Corral
   */
  private _mostrarBotonEdicion(mostrar = true): void {
    if (mostrar) {
      const autorizacion = {
        objeto: this.configuracion!.objeto,
        permisos: [Permiso.EDITAR],
      };
      this.configFicha!.header!.botones = [
        { ...BOTON_MODO_EDICION, autorizacion },
      ];
    } else this.configFicha!.header!.botones = undefined;
    this._cdr.markForCheck();
    if (this._fichaRef) this._fichaRef.cdr.markForCheck();
    if (this._formularioRef) this._formularioRef.cdr.markForCheck();
  }

  /**
   * Activa el modo de edición
   * @author Juan Corral
   */
  protected activarModoEdicion(): void {
    for (const seccion of this.configuracion?.secciones ?? []) {
      if (seccion.editable !== true) continue;
      for (const subseccion of seccion.subsecciones) {
        for (const campo of subseccion.formulario.campos) {
          if (subseccion.readonly?.includes(campo.slug)) continue;
          campo.readonly = false;
        }
      }
    }
    this.modoEdicion = true;
    this._mostrarBotonEdicion(false);
  }

  /**
   * Cancela la edición
   * @author Juan Corral
   */
  protected cancelarEdicion(): void {
    const numSecs = this.configuracion?.secciones.length ?? 0;
    for (let i = 0; i < numSecs; i++) {
      const numSub = this.configuracion!.secciones[i].subsecciones.length ?? 0;
      for (let j = 0; j < numSub; j++) {
        this.configuracion!.secciones[i].subsecciones[j].formulario = deepCopy(
          this._configuracionOriginal!.secciones[i].subsecciones[j].formulario,
        );
      }
    }
    this.modoEdicion = false;
    this._mostrarBotonEdicion();
    this._responsiveEmitter.emit();
  }

  /**
   * Registra los cambios realizados a la sub-sección
   * @param {string} seccion - Slug de la sección
   * @param {string} subseccion - Slug de la sub-sección
   * @param {any} valores - Valores de la sub-sección
   * @author Juan Corral
   */
  protected cambioValores(
    seccion: SeccionRecurso,
    subseccion: SubseccionRecurso,
    valores: ValoresFormulario | undefined,
  ): void {
    if (!this._valores[seccion.slug]) this._valores[seccion.slug] = {};
    this._valores[seccion.slug][subseccion.slug] = valores;
  }

  /**
   * Emite los cambios realizados al recurso
   * @author Juan Corral
   */
  protected guardarCambios(): void {
    this.modoEdicion = false;
    for (const seccion of this.configuracion?.secciones ?? []) {
      if (seccion.editable !== true) continue;
      for (const subseccion of seccion.subsecciones) {
        for (const campo of subseccion.formulario.campos) {
          campo.readonly = true;
        }
      }
    }
    this.edicion.next(this._valores);
    this._mostrarBotonEdicion();
    this._responsiveEmitter.emit();
  }
}
