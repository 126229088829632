import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrdenesService } from '../../utilidades/ordenes.service';
import {
  CONFIGURACION_FICHA_APROBAR_ORDENES,
  CONFIGURACION_FORMULARIO_APROBAR_ORDENES,
  CONFIGURACION_TABLA_APROBAR_ORDENES,
} from './utilidades/aprobar-ordenes.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { Subject, Subscription } from 'rxjs';
import { ErrorDesarrollo } from 'src/app/compartido/utilidades/error.utils';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';
import {
  OPCIONES_FARMACIAS_EMCO_SALUD,
  OPCIONES_FARMACIAS_VIDAMEDICAL,
} from '../importar-ordenes/utilidades/importar-ordenes.static';
import { SourceTablaOrdenes } from '../tabla-ordenes/utilidades/source-tabla-ordenes.class';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { EstadoOrden } from '../../utilidades/ordenes.models';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';
import { AprobarOrdenComponent } from '../aprobar-orden/aprobar-orden.component';

@Component({
  selector: 'ordenes-aprobar-ordenes',
  templateUrl: './aprobar-ordenes.component.html',
  styleUrls: ['./aprobar-ordenes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AprobarOrdenesComponent implements OnInit, OnDestroy {
  /* Suscripciones */
  private readonly _suscripcion = new Subscription();

  constructor(
    private readonly _ordenesService: OrdenesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaOrdenes(_ordenesService);
  }

  /* Configuración de la ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_APROBAR_ORDENES);

  /* Formulario */
  protected configFormulario: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_APROBAR_ORDENES,
  );
  protected valorFormulario: ValoresFormulario | null = null;
  protected validezFormulario: boolean = false;

  /* Tabla de Órdenes */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_APROBAR_ORDENES);
  protected dataSource: SourceTablaOrdenes;
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  /* Órdenes aprobadas */
  protected aprobadas: number[] = [];
  protected rechazadas: number[] = [];

  ngOnInit(): void {
    // Poblar opciones de organización
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.valorIn = new Subject();
    campoOrg.valorOut = new Subject();
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = organizaciones.map((org) => ({
          nombre: org.nombre,
          valor: org.nombre,
        }));
        if (organizaciones.length === 1) {
          campoOrg.valorIn!.next(organizaciones[0].nombre);
          campoOrg.readonly = true;
        }
      });

    // Mostrar campo de farmacia para la organizaciones Vidamedical y Emco Salud
    const campoFarmacia = obtenerCampo(this.configFormulario, 'farmacia')!;
    const escucharOrg = campoOrg.valorOut.subscribe((valor) => {
      campoFarmacia.oculto = valor !== 'Vidamedical' && valor !== 'Emco Salud';
      if (campoFarmacia.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
        throw new ErrorDesarrollo(
          'El campo farmacia debe ser de tipo OPCION_MULTIPLE',
        );
      campoFarmacia.opciones =
        valor === 'Vidamedical'
          ? OPCIONES_FARMACIAS_VIDAMEDICAL
          : valor === 'Emco Salud'
            ? OPCIONES_FARMACIAS_EMCO_SALUD
            : [];
    });
    this._suscripcion.add(escucharOrg);
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Realiza la búsqueda de órdenes.
   * @autor Juan Corral
   */
  protected buscarOrdenes(): void {
    if (!this.validezFormulario) {
      this._alertasService.alertarError('Formulario inválido');
      return;
    }

    this.tabla.valoresFiltros = {
      organizacion: this.valorFormulario!['organizacion'],
      desde: this.valorFormulario!['fecha'],
      hasta: this.valorFormulario!['fecha'],
      estado: EstadoOrden.PENDIENTE,
    };

    const orden = this.tabla.sort.active
      ? (this.tabla.sort.direction == 'asc' ? '' : '-') + this.tabla.sort.active
      : '';

    this.dataSource.obtenerDatos(
      orden,
      this.tabla.paginator.pageIndex * this.tabla.paginator.pageSize,
      this.tabla.paginator.pageSize,
      this.tabla.valoresFiltros,
    );
  }

  /**
   * Abre el dialogo de aprobar orden.
   * @param {number} id - El id de la orden a aprobar.
   * @autor Juan Corral
   */
  protected async aprobarOrden$(id: number): Promise<void> {
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: AprobarOrdenComponent,
      inputs: { idOrden: id },
      output: 'aprobada',
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    const aprobada = await this._dialogosService.abrirVistaPrevia$(config);
    if (aprobada !== null && aprobada !== undefined) {
      if (aprobada) this.aprobadas.push(id);
      else this.rechazadas.push(id);
      this.buscarOrdenes();
    }
  }
}
