import { ErrorDesarrollo } from './error.utils';

/**
 * Divide un array en lotes de un tamaño especificado.
 * @param {number} tamano - El tamaño de cada lote
 * @param {T[]} arr - El array a dividir
 * @returns {T[][]} - El array dividido en lotes de tamaño especificado
 * @author Juan Corral
 */
export function dividirEnLotes<T>(tamano: number, arr: any[]): T[][] {
  const lotes: T[][] = [];
  for (let i = 0; i < arr.length; i += tamano) {
    const limite = Math.min(i + tamano, arr.length);
    const lote = arr.slice(i, limite);
    lotes.push(lote);
  }
  return lotes;
}

/**
 * Convierte un array en un diccionario usando una propiedad como llave.
 * @param {T[]} arr - El array a convertir en diccionario.
 * @param {string} llave - La propiedad que se usará como llave en el diccionario.
 * @returns {Record<string, T>} - El mapa creado a partir del array.
 * @author Juan Corral
 */
export function arrayADiccionario<T>(arr: T[], llave: string): Record<string, T> {
  const mapa: Record<string, T> = {};
  for (const elemento of arr) {
    const propiedad = elemento[llave as keyof T]?.toString();
    if (propiedad) mapa[propiedad] = elemento;
    else throw new ErrorDesarrollo('No se pudo convertir el array en mapa');
  }
  return mapa;
}
