import { EstadoVerificacionIdentidad } from 'src/app/ingreso/utilidades/ingreso.models';
import { Permiso } from 'src/app/nucleo/autorizacion/autorizacion.models';
import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaAsincrona,
  FilaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Configuración de la ficha de verificaciones de identidad */
export const CONFIGURACION_FICHA_TABLA_VERIFICACIONES_IDENTIDAD: ConfiguracionFicha =
  {
    maxWidth: 700,
  };

/* Configuración de la tabla de verificaciones de identidad */
export const CONFIGURACION_TABLA_VERIFICACIONES_IDENTIDAD: ConfiguracionTablaAsincrona =
  {
    columnas: [
      { slug: 'id', nombre: 'ID', ordenable: true },
      { slug: 'estado', nombre: 'Estado' },
      { slug: 'usuario', nombre: 'Usuario', ordenable: true },
      { slug: 'cliente', nombre: 'Cliente', ordenable: true },
      { slug: 'archivo', nombre: 'Archivo' },
    ],
    busqueda: true,
    filtros: [
      {
        nombre: 'Estado',
        slug: 'estado',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        opciones: [
          { nombre: 'Todos', valor: '' },
          { nombre: 'Pendiente', valor: EstadoVerificacionIdentidad.PENDIENTE },
          { nombre: 'Aprobada', valor: EstadoVerificacionIdentidad.APROBADA },
          { nombre: 'Rechazada', valor: EstadoVerificacionIdentidad.RECHAZADA },
        ],
        valor: EstadoVerificacionIdentidad.PENDIENTE,
      },
    ],
    acciones: [
      {
        nombre: 'Aprobar',
        slug: 'aprobar',
        icono: Icono.APROBACION,
        autorizacion: {
          objeto: 'verificacionidentidad',
          permisos: [Permiso.VER, Permiso.APROBAR],
        },
        ocultar: (fila: FilaTabla) =>
          fila['estado'].toLowerCase() !==
          EstadoVerificacionIdentidad.PENDIENTE,
      },
    ],
  };
