import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ConfiguracionFormulario,
  OpcionCampo,
  TipoCampoFormulario,
  ValoresFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  CONFIGURACION_FICHA_CREAR_ORDEN,
  CONFIGURACION_FORMULARIO_CREAR_ORDEN,
  CONFIGURACION_FORMULARIO_ITEM_ORDEN,
  CONFIGURACION_FORMULARIO_FORMULA_ORDEN,
} from './utilidades/crear-orden.static';
import { ClientesService } from 'src/app/clientes/utilidades/clientes.service';
import {
  ClienteDTO,
  DireccionDTO,
  OrganizacionDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { OrdenesService } from '../../utilidades/ordenes.service';
import { ItemOrdenBase, OrdenBase } from '../../utilidades/ordenes.models';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/clientes/utilidades/cliente.class';
import { obtenerCampo } from 'src/app/compartido/formulario/utilidades/formulario.utils';
import {
  Medicamento,
  MedicamentosService,
} from 'src/app/nucleo/utilidades/medicamentos.service';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';

@Component({
  selector: 'orden-crear-orden',
  templateUrl: './crear-orden.component.html',
  styleUrls: ['./crear-orden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrearOrdenComponent implements OnInit, OnDestroy {
  /* Suscripción */
  private readonly _suscripcion = new Subscription();
  private _subValorCliente: Subscription | undefined = undefined;

  /* Organizaciones */
  private _organizaciones: OrganizacionDTO[] = [];

  /* Configuración ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_CREAR_ORDEN);

  /* Formulario de la orden */
  protected configFormulario: ConfiguracionFormulario = deepCopy(
    CONFIGURACION_FORMULARIO_CREAR_ORDEN,
  );
  protected validezFormulario: boolean = false;
  protected valorFormulario: ValoresFormulario | null = null;

  /* Formularios de los items */
  protected configFormulariosItems: ConfiguracionFormulario[] = [];
  protected valoresFormulariosItems: ValoresFormulario[] = [];
  protected validezFormulariosItems: boolean[] = [];
  protected get validezItems(): boolean {
    return this.validezFormulariosItems.every((validez) => validez);
  }

  /* Formularios de las fórmulas */
  protected configFormulariosFormulas: ConfiguracionFormulario[] = [];
  protected valoresFormulariosFormulas: ValoresFormulario[] = [];
  protected validezFormulariosFormulas: boolean[] = [];
  protected get validezFormulas(): boolean {
    return (
      this.validezFormulariosFormulas.every((validez) => validez) &&
      this.valoresFormulariosFormulas.length >= this._minimoFormulas
    );
  }
  private _minimoFormulas: number = 0;

  /* Cliente */
  private _cliente: Cliente | null = null;

  /* Cargando */
  protected cargando: boolean = false;

  constructor(
    private readonly _autenticacionService: AutenticacionService,
    private readonly _alertasService: AlertasService,
    private readonly _clientesService: ClientesService,
    private readonly _ordenesService: OrdenesService,
    private readonly _medicamentosService: MedicamentosService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    // Asignar opciones de organizaciones
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    campoOrg.valorIn = new Subject<number>();
    this._poblarOpcionesOrganizacion$();

    // Asignar opciones de clientes dinámicamente
    const campoCliente = obtenerCampo(this.configFormulario, 'cliente')!;
    if (campoCliente.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
      throw new ErrorDesarrollo(
        'El campo cliente debe ser de tipo AUTOCOMPLETE',
      );
    campoCliente.valorIn = new Subject<number>();
    campoCliente.valorOut = new Subject<string>();
    campoCliente.opcionesIn = new Subject<OpcionCampo<string | number>[]>();
    campoCliente.opcionOut = new Subject<OpcionCampo<string | number>>();
    this._subValorCliente = campoCliente.valorOut!.subscribe((valor) =>
      this._poblarOpcionesCliente$(valor),
    );
    this._suscripcion.add(this._subValorCliente);

    // Asignar opciones de direcciones dinámicamente
    this._suscripcion.add(
      campoCliente.opcionOut!.subscribe((opcion) =>
        this._poblarOpcionesDireccion$(parseInt(opcion.valor as string)),
      ),
    );

    // Editar formulario si el usuario es cliente
    this._autenticacionService.usuario$.then((usuario) => {
      if (usuario!.rol !== RolUsuario.CLIENTE) return;
      const cliente = usuario!.cliente!;
      this._suscripcion.remove(this._suscripcion);
      this._subValorCliente?.unsubscribe();
      campoCliente.opcionOut!.next({
        nombre: `${cliente.nombre} - ${cliente.cedula}`,
        valor: cliente.cedula,
      });
      campoCliente.valorIn!.next(cliente.cedula);
      campoCliente.readonly = true;
      obtenerCampo(this.configFormulario, 'referencia')!.oculto = true;
      this._minimoFormulas = 1;
    });
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Puebla las opciones del campo de organización con las organizaciones disponibles
   * @author Juan Corral
   */
  private async _poblarOpcionesOrganizacion$(): Promise<void> {
    this._organizaciones =
      await this._organizacionService.obtenerOrganizaciones$();
    const campoOrg = obtenerCampo(this.configFormulario, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    campoOrg.opciones = this._organizaciones.map((org) => ({
      nombre: org.nombre,
      valor: org.id,
    }));
    if (this._organizaciones.length === 1) {
      campoOrg.valorIn!.next(this._organizaciones[0].id);
      campoOrg.readonly = true;
    }
    this._cdr.markForCheck();
  }

  /**
   * Puebla las opciones del campo de cliente con los clientes que coinciden con la búsqueda
   * @param {string} busqueda - Valor de la cédula a buscar
   * @author Juan Corral
   */
  private async _poblarOpcionesCliente$(busqueda: string): Promise<void> {
    const organizacionId = this.valorFormulario!['organizacion'];
    const organizacion = this._organizaciones.find(
      (org) => org.id === organizacionId,
    );
    if (!organizacion) {
      this._alertasService.alertarError('Seleccione una organización primero');
      return;
    }
    const clientes = await this._clientesService.obtenerClientes$({
      search: busqueda,
      organizacion: organizacion.nombre,
    });
    const campoCliente = obtenerCampo(this.configFormulario, 'cliente')!;
    if (campoCliente.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
      throw new ErrorDesarrollo(
        'El campo cliente debe ser de tipo AUTOCOMPLETE',
      );
    campoCliente.opcionesIn!.next(
      clientes.results.map((cliente: ClienteDTO) => ({
        nombre: `${cliente.nombre} - ${cliente.cedula}`,
        valor: cliente.cedula,
      })),
    );
  }

  /**
   * Puebla las opciones del campo de dirección con las direcciones del cliente
   * @param {number} cedula - Cédula del cliente
   * @author Juan Corral
   */
  private async _poblarOpcionesDireccion$(cedula: number): Promise<void> {
    const cliente = await this._clientesService.obtenerCliente$(cedula);
    if (!cliente) {
      this._alertasService.alertarError('Cliente no encontrado');
      return;
    }
    this._cliente = cliente;
    const direcciones = cliente.direcciones;
    if (direcciones.length === 0)
      this._alertasService.alertarError(
        'El cliente no tiene direcciones registradas',
      );
    const campoDireccion = obtenerCampo(this.configFormulario, 'direccion')!;
    if (campoDireccion.tipo !== TipoCampoFormulario.OPCION_MULTIPLE) return;
    campoDireccion.opciones = direcciones.map((direccion: DireccionDTO) => ({
      nombre: direccion.direccion,
      valor: direccion.id,
    }));
    this._cdr.markForCheck();
  }

  /**
   * Agrega un formulario de item a la lista de formularios de items de la orden.
   * @author Juan Corral
   */
  protected agregarItem(): void {
    const configuracion = deepCopy(CONFIGURACION_FORMULARIO_ITEM_ORDEN);

    // Agregar opciones de productos
    const campoProducto = obtenerCampo(configuracion, 'producto')!;
    if (campoProducto.tipo !== TipoCampoFormulario.AUTOCOMPLETE)
      throw new ErrorDesarrollo(
        'El campo producto debe ser de tipo AUTOCOMPLETE',
      );
    campoProducto.opcionesIn = new Subject<OpcionCampo<string | number>[]>();
    campoProducto.valorOut = new Subject<string>();
    const escucharValor = campoProducto.valorOut
      .pipe(
        debounceTime(100),
        tap((valor) => {
          this._medicamentosService
            .buscarMedicamentos$(valor.toUpperCase())
            .then((medicamentos) =>
              campoProducto.opcionesIn!.next(
                medicamentos.map((medicamento: Medicamento) => ({
                  nombre: `${medicamento.producto} - ${medicamento.descripcionatc}`,
                  valor: medicamento.producto,
                })),
              ),
            );
        }),
      )
      .subscribe();
    this._suscripcion.add(escucharValor);

    this.configFormulariosItems.push(configuracion);
    this.valoresFormulariosItems.push({});
    this.validezFormulariosItems.push(false);
    this._cdr.markForCheck();
  }

  /**
   * Agrega un formulario de fórmula a la lista de formularios de fórmulas de la orden.
   * @author Juan Corral
   */
  protected agregarFormula(): void {
    const configuracion = deepCopy(CONFIGURACION_FORMULARIO_FORMULA_ORDEN);
    this.configFormulariosFormulas.push(configuracion);
    this.valoresFormulariosFormulas.push({});
    this.validezFormulariosFormulas.push(false);
    this._cdr.markForCheck();
  }

  /**
   * Elimina un formulario de item de la lista de formularios de items de la orden.
   * @param {number} index - Índice del formulario a eliminar.
   * @author Juan Corral
   */
  protected eliminarItem(index: number): void {
    this.configFormulariosItems.splice(index, 1);
    this.valoresFormulariosItems.splice(index, 1);
    this.validezFormulariosItems.splice(index, 1);
    this._cdr.markForCheck();
  }

  /**
   * Elimina un formulario de fórmula de la lista de formularios de fórmulas de la orden.
   * @param {number} index - Índice del formulario a eliminar.
   * @author Juan Corral
   */
  protected eliminarFormula(index: number): void {
    this.configFormulariosFormulas.splice(index, 1);
    this.valoresFormulariosFormulas.splice(index, 1);
    this.validezFormulariosFormulas.splice(index, 1);
    this._cdr.markForCheck();
  }

  /**
   * Crea una orden con los datos del formulario.
   * @author Juan Corral
   */
  protected crearOrden(): void {
    const valores = this.valorFormulario!;
    if (!this._cliente) {
      this._alertasService.alertarError('Seleccione un cliente válido primero');
      return;
    }

    const organizacion = this._organizaciones.find(
      (org) => org.id === valores['organizacion'],
    )!;

    // Construir orden
    const orden: OrdenBase = {
      referencia: valores['referencia']
        ? organizacion.alias + ' - ' + valores['referencia']
        : undefined,
      fecha_planeada: valores['fecha'],
      direccion: this._cliente.direcciones.find(
        (dir) => dir.id === valores['direccion'],
      )!,
      cliente: {
        cedula: this._cliente.cedula,
        nombre: this._cliente.nombre,
        telefono: this._cliente.telefono ?? undefined,
        correo: this._cliente.correo ?? undefined,
        organizacion: organizacion.id,
      },
      items: this.valoresFormulariosItems.map((valoresItem) => ({
        producto: {
          tipo: 'medicamento',
          nombre: valoresItem['producto'],
          origen_datos: 'cliente',
        },
        cantidad: valoresItem['cantidad'],
      })),
      formulas: this.valoresFormulariosFormulas.map((valoresFormula) => ({
        referencia: valoresFormula['referencia'],
        archivo: valoresFormula['archivo'].files[0] as File,
      })),
      notas: valores['notas'],
    };

    // Crear orden
    this._ordenesService
      .crearOrden$(orden)
      .then(() => {
        this._alertasService.alertarExito('Orden creada exitosamente');
        this._router.navigate(['ordenes']);
      })
      .catch((error: ErrorFrontend) =>
        this._alertasService.alertarError('Error: ' + error.message),
      );
  }
}
