import { valorVacío } from './valores.utils';

/**
 * Devuelve una copia profunda del objeto.
 * @param {T} objeto - El objeto a copiar.
 * @returns {T} - La copia profunda del objeto.
 * @author Juan Corral
 */
export function deepCopy<T>(objeto: T): T {
  if (Array.isArray(objeto)) {
    return objeto.map((x) => (typeof x === 'function' ? x : deepCopy(x))) as T;
  } else if (
    typeof objeto === 'object' &&
    objeto &&
    objeto.constructor === Object
  ) {
    const copia = {} as T;
    for (const key in objeto) {
      const valor = objeto[key as keyof T];
      copia[key as keyof T] =
        typeof valor === 'function' ? valor : deepCopy(valor);
    }
    return copia;
  }
  return objeto;
}

/**
 * Devuelve una copia del objeto sin los atributos indefinidos.
 * @param {Record<string, any>} objeto - El objeto a limpiar.
 * @returns {Record<string, any>} - La copia del objeto sin los atributos indefinidos.
 * @author Juan Corral
 */
export function removerAtributosIndefinidos(
  objeto: Record<string, any>,
): Record<string, any> {
  const obj = deepCopy(objeto);
  Object.keys(obj).forEach((key) => valorVacío(obj[key]) && delete obj[key]);
  return obj;
}
