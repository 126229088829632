<compartido-ficha [configuracion]="configFicha" *ngIf="orden">
  <div class="orden {{ responsiveService.claseCSS$ | async }}">
    <div class="top fondo-primario color-primario-contraste">
      <mat-icon color="primary" class="fondo-primario-contraste">
        receipt
      </mat-icon>
      <h2>{{ orden.titulo }}</h2>
    </div>
    <div class="resto mat-app-background">
      <mat-list>
        <ng-container *ngIf="orden.estado === EstadoOrden.PENDIENTE">
          <!-- Detalles -->
          <mat-list-item>
            <mat-icon matListItemIcon>numbers</mat-icon>
            <h3 matListItemTitle>No. Referencia</h3>
            <p matListItemLine>{{ orden.referencia ?? "-" }}</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <h3 matListItemTitle>Cliente</h3>
            <p matListItemLine>{{ orden.cliente.nombre }}</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>calendar_month</mat-icon>
            <h3 matListItemTitle>Fecha Planeada</h3>
            <p matListItemLine>
              {{ orden.fechaPlaneada | date: "dd/MM/yyyy" }}
            </p>
          </mat-list-item>

          <mat-divider
            *ngIf="
              (orden?.items ?? []).length >= 0 ||
              (orden?.formulas ?? []).length >= 0
            "
          ></mat-divider>

          <!-- Items -->
          <mat-list-item *ngFor="let item of orden?.items" class="item">
            <mat-icon matListItemIcon>medication_liquid</mat-icon>
            <h3 matListItemTitle>{{ item.producto.nombre }}</h3>
            <p matListItemLine>x{{ item.cantidad }}</p>
          </mat-list-item>

          <mat-divider></mat-divider>

          <!-- Fórmulas -->
          <mat-list-item
            *ngFor="let formula of orden?.formulas; let i = index"
            class="formula"
          >
            <mat-icon matListItemIcon>medical_services</mat-icon>
            <h3 matListItemTitle>Fórmula #{{ i + 1 }}</h3>
            <p matListItemLine>
              Ref. {{ formula.referencia }} -
              <a [href]="formula.archivo" target="_blank">Archivo</a>
            </p>
          </mat-list-item>
        </ng-container>

        <!-- Ordenes con otros estados  -->
        <mat-list-item *ngIf="orden.estado !== EstadoOrden.PENDIENTE">
          <mat-icon matListItemIcon>block</mat-icon>
          <h3 matListItemTitle>
            Orden
            {{
              orden.estado === EstadoOrden.RECHAZADA ? "Rechazada" : "Aprobada"
            }}
          </h3>
          <p matListItemLine>
            La orden ya ha sido
            {{
              orden.estado === EstadoOrden.RECHAZADA ? "rechazada" : "aprobada"
            }}
            anteriormente
          </p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <div class="motivo" *ngIf="orden.estado === EstadoOrden.PENDIENTE">
    <h3>Motivo</h3>
    <mat-form-field appearance="outline" class="field">
      <textarea
        [(ngModel)]="motivo"
        matInput
        style="resize: none"
        [rows]="5"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="botones">
    <button
      mat-flat-button
      color="warn"
      (click)="aprobarOrden(false)"
      [disabled]="orden.estado !== EstadoOrden.PENDIENTE"
    >
      Rechazar <mat-icon>close</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="aprobarOrden(true)"
      [disabled]="orden.estado !== EstadoOrden.PENDIENTE"
    >
      Aprobar <mat-icon>done</mat-icon>
    </button>
  </div>
</compartido-ficha>
