import { Injectable, Type } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DialogoVistaPreviaComponent } from '../../compartido/dialogos/dialogo-vista-previa/dialogo-vista-previa.component';
import { ConfiguracionDialogoAccion } from '../../compartido/dialogos/dialogo-accion/utilidades/dialogo-accion.static';
import { DialogoAccionComponent } from '../../compartido/dialogos/dialogo-accion/dialogo-accion.component';
import { ConfiguracionDialogoVistaPrevia } from '../../compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';

@Injectable({ providedIn: 'root', deps: [MatDialogModule] })
export class DialogosService {
  constructor(private readonly _dialogo: MatDialog) {}

  /**
   * Abre un diálogo modal.
   * @param {string} titulo - Título del diálogo.
   * @param {string} mensaje - Mensaje del diálogo.
   * @param {string} botonPrincipal [Opcional] - Texto del botón principal.
   * @param {string} botonSecundario [Opcional] - Texto del botón secundario.
   * @returns {Promise<boolean>} - Si diálogo se cerró con el botón principal (promise).
   * @author Juan Corral
   */
  public async abrirDialogo$(
    titulo: string,
    mensaje: string,
    botonPrincipal: string = 'Continuar',
    botonSecundario?: string,
  ): Promise<boolean> {
    const configuracion: ConfiguracionDialogoAccion = {
      titulo,
      mensaje,
      botonPrincipal,
      botonSecundario,
    };
    const referencia = this._dialogo.open(DialogoAccionComponent, {
      autoFocus: false,
      data: configuracion,
    });
    return await firstValueFrom(referencia.afterClosed());
  }

  /**
   * Abre una vista previa de un componente.
   * @param {ConfiguracionDialogoVistaPrevia} configuracion - Configuración de la vista previa.
   * @param {MatDialogConfig} matConfig [Opcional] - Configuración del dialogo de Material.
   * @returns {Promise<any>} - Resultado de la vista previa (promise).
   */
  public async abrirVistaPrevia$(
    configuracion: ConfiguracionDialogoVistaPrevia,
    matConfig?: MatDialogConfig,
  ): Promise<any> {
    const referencia = this._dialogo.open(DialogoVistaPreviaComponent, {
      maxHeight: '90vh',
      autoFocus: false,
      ...matConfig,
      data: configuracion,
    });
    return await firstValueFrom(referencia.afterClosed());
  }

  /**
   * Cierra todos los diálogos abiertos.
   * @autor Juan Corral
   */
  public cerrarDialogos(): void {
    this._dialogo.closeAll();
  }
}
