import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  TipoCampoFormulario,
  PosicionLabelFormulario,
  DisplayFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';

/* Configuración de la ficha de crear domicilio */
export const CONFIGURACION_FICHA_CREAR_ORDEN: ConfiguracionFicha = {
  header: { titulo: 'Crear Orden', icono: Icono.ORDENES },
  contenido: { padding: true },
  maxWidth: 700,
};

/* Configuración del formulario de crear orden */
export const CONFIGURACION_FORMULARIO_CREAR_ORDEN: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Organización',
      slug: 'organizacion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // Se obtiene del servidor
      requerido: true,
    },
    {
      nombre: 'Cliente',
      slug: 'cliente',
      tipo: TipoCampoFormulario.AUTOCOMPLETE,
      opciones: [], // Se obtiene del servidor
      requerido: true,
    },
    {
      nombre: 'Dirección',
      slug: 'direccion',
      tipo: TipoCampoFormulario.OPCION_MULTIPLE,
      opciones: [], // Se obtiene del servidor
      requerido: true,
    },
    {
      nombre: 'Fecha Planeada',
      slug: 'fecha',
      tipo: TipoCampoFormulario.FECHA,
      minimo: formatearFecha(Date.now() + 86400000),
      requerido: true,
    },
    {
      nombre: 'N° Referencia',
      slug: 'referencia',
      tipo: TipoCampoFormulario.TEXTO,
    },
    {
      nombre: 'Notas',
      slug: 'notas',
      tipo: TipoCampoFormulario.TEXTO,
      requerido: false,
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    esconderErrores: true,
  },
};

/* Configuración del formulario de item de orden */
export const CONFIGURACION_FORMULARIO_ITEM_ORDEN: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'Producto',
      slug: 'producto',
      tipo: TipoCampoFormulario.AUTOCOMPLETE,
      opciones: [], // Se obtiene del servidor
      requerido: true,
    },
    {
      nombre: 'Cantidad',
      slug: 'cantidad',
      tipo: TipoCampoFormulario.NUMERO,
      requerido: true,
      minimo: 1,
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    esconderErrores: true,
  },
};

/* Configuración del formulario de fórmula de orden */
export const CONFIGURACION_FORMULARIO_FORMULA_ORDEN: ConfiguracionFormulario = {
  campos: [
    {
      nombre: 'No. de Fórmula',
      slug: 'referencia',
      tipo: TipoCampoFormulario.TEXTO,
      requerido: true,
    },
    {
      nombre: 'Archivo',
      slug: 'archivo',
      tipo: TipoCampoFormulario.ARCHIVO,
      aceptados: ['.pdf', '.png', '.jpg'],
      requerido: true,
    },
  ],
  estilo: {
    display: DisplayFormulario.GRID,
    posicionLabel: PosicionLabelFormulario.TOP,
    widthCampo: 250,
    widthLabel: 250,
    esconderErrores: true,
  },
};
