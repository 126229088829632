import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  ClienteDTO,
  DireccionDTO,
  OrganizacionDTO,
} from '../../utilidades/clientes.models';
import { ClientesService } from '../../utilidades/clientes.service';
import { ConfiguracionRecurso } from 'src/app/compartido/recurso/utilidades/recurso.models';
import {
  CONFIGURACION_RECURSO_CLIENTE,
  CAMPOS_DIRECCION_CLIENTE,
} from './utilidades/ver-cliente.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import {
  CampoFormulario,
  ConfiguracionFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { firstValueFrom, map, Subscription } from 'rxjs';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { AutenticacionService } from 'src/app/nucleo/autenticacion/autenticacion.service';
import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { PlacesService } from 'src/app/nucleo/utilidades/places.service';
import { Cliente } from '../../utilidades/cliente.class';
import { obtenerSeccion } from 'src/app/compartido/recurso/utilidades/recurso.utils';

@Component({
  selector: 'clientes-ver-cliente',
  templateUrl: './ver-cliente.component.html',
  styleUrls: ['./ver-cliente.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerClienteComponent implements OnInit, OnDestroy {
  /* Suscripción */
  private readonly _suscripcion = new Subscription();

  /* Cedula del Cliente */
  @Input() public cedula: number | undefined;

  /* Cliente */
  private _cliente: Cliente | undefined;

  /* Direcciones del cliente */
  private _direcciones: DireccionDTO[] = [];

  /* Lista Organizaciones */
  protected listaOrganizaciones: OrganizacionDTO[] = [];

  /* Configuración del recurso */
  protected configuracion: ConfiguracionRecurso | undefined;

  constructor(
    private readonly _clientesService: ClientesService,
    private readonly _organizacionService: OrganizacionService,
    private readonly _autenticacionService: AutenticacionService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _alertasService: AlertasService,
    private readonly _placesService: PlacesService,
  ) {}

  ngOnInit(): void {
    // Crear la configuración del recurso
    this._crearConfiguracion$();
  }

  ngOnDestroy(): void {
    this._suscripcion.unsubscribe();
  }

  /**
   * Crea la configuración del recurso para mostrar en la vista
   * @author Juan Corral
   */
  private async _crearConfiguracion$(): Promise<void> {
    // Obtener el cliente
    const param = await firstValueFrom<string>(
      this._route.params.pipe(map((params: Params) => params['cedula'])),
    );
    const cedula = this.cedula ?? parseInt(param);
    this._cliente =
      param === 'perfil'
        ? await this._autenticacionService.usuario$.then((usuario) =>
            usuario?.cliente ? Cliente.fromDTO(usuario?.cliente) : undefined,
          )
        : await this._clientesService.obtenerCliente$(cedula);
    if (!this._cliente) throw new ErrorDesarrollo('No se encontró el cliente');

    // Guardar la cédula y las direcciones
    const rol = (await this._autenticacionService.usuario$)?.rol;
    this._direcciones =
      rol === RolUsuario.CLIENTE
        ? this._cliente.direcciones.filter((d) => d['creacion_manual'])
        : this._cliente.direcciones;

    // Obtener la lista de organizaciones
    this.listaOrganizaciones =
      await this._organizacionService.obtenerOrganizaciones$();

    // Crear la configuración
    const configuracion: ConfiguracionRecurso = deepCopy(
      CONFIGURACION_RECURSO_CLIENTE,
    );

    // Sección: Información
    const clientePlano = this._cliente as Record<string, any>;
    configuracion.nombre = this._cliente.nombre;
    const seccionInformacion = obtenerSeccion(configuracion, 'informacion');
    for (const subseccion of seccionInformacion!.subsecciones) {
      for (const campo of subseccion.formulario.campos) {
        campo.valor = clientePlano[campo.slug];
        if (campo.slug === 'organizacion') {
          if (campo.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
            throw new ErrorDesarrollo(
              'El campo organización debe ser de tipo OPCION_MULTIPLE',
            );
          campo.valor = clientePlano['organizacion']['nombre'];
          campo.opciones = [
            ...this.listaOrganizaciones.map((org) => ({
              nombre: org.nombre,
              valor: org.nombre,
            })),
          ];
        }
      }
    }

    // Sección: Direcciones
    const seccionDirecciones = obtenerSeccion(configuracion, 'direcciones');
    for (let i = 0; i < this._direcciones.length; i++) {
      const direccion = this._direcciones[i];
      const formulario = this._crearFormularioDireccion(direccion);
      const subseccion = {
        nombre: 'Dirección #' + (i + 1),
        slug: 'direccion-' + (i + 1),
        formulario,
        readonly: ['id', 'direccion_formateada', 'creacion_manual'],
      };
      seccionDirecciones!.subsecciones.push(subseccion);
    }
    if (this._direcciones.length == 0) {
      const formulario = this._crearFormularioDireccion(null);
      const subseccion = {
        nombre: 'Dirección',
        slug: 'direccion',
        formulario,
        readonly: ['id', 'direccion_formateada', 'creacion_manual'],
      };
      seccionDirecciones!.subsecciones.push(subseccion);
    }

    // Guardar la configuración
    this.configuracion = configuracion;
    this._cdr.markForCheck();
  }

  /**
   * Crea la configuración del formulario para una dirección
   * @param {DireccionDTO | null} direccion - La dirección a configurar
   * @author Juan Corral
   */
  private _crearFormularioDireccion(
    direccion: DireccionDTO | null,
  ): ConfiguracionFormulario {
    // Obtener los campos de la dirección
    const camposDireccion = deepCopy(CAMPOS_DIRECCION_CLIENTE);

    // Crear el formulario con los valores de la dirección
    const campos: CampoFormulario[] = [];
    for (let campo of camposDireccion) {
      if (direccion !== null) {
        if (campo.slug !== 'coordenadas')
          campo.valor = (direccion as Record<string, any>)[campo.slug];
        else {
          const latitud = parseFloat(direccion['latitud'] ?? '');
          const longitud = parseFloat(direccion['longitud'] ?? '');
          if (!isNaN(latitud) && !isNaN(longitud))
            campo.valor = { latitud, longitud };
        }
      }
      campos.push(campo);
    }

    // Auto-completar la dirección
    this._placesService
      .configurarAutocompleteDireccion(campos)
      .then((suscripcion) => this._suscripcion.add(suscripcion));

    // Quitar campo ID si la dirección es nueva
    if (direccion === null) {
      const campoID = campos.find((c) => c.slug === 'id')!;
      campos.splice(campos.indexOf(campoID), 1);
    }

    return { campos };
  }

  /**
   * Guarda los cambios realizados al cliente
   * @param {any} valores - Los valores del cliente editado
   * @author Juan Corral
   */
  protected guardarCambios(valores: any): void {
    const informacion = valores.informacion.informacion;
    const organizacion = this.listaOrganizaciones.find(
      (org) => org.nombre == informacion.organizacion,
    );
    if (organizacion === undefined)
      throw new ErrorDesarrollo('La organización buscada no existe');

    const datos: ClienteDTO = {
      cedula: informacion.cedula,
      nombre: informacion.nombre,
      telefono: informacion.telefono,
      correo: informacion.correo,
      organizacion: organizacion,
      direcciones: [],
    };

    const direcciones = Object.values<Record<string, any>>(valores.direcciones);
    for (let i = 0; i < direcciones.length; i++) {
      const direccion = direcciones[i];
      if (!direccion['direccion']) continue;
      datos.direcciones.push({
        id: this._direcciones.at(i)?.id ?? 0,
        direccion: direccion['direccion'],
        direccion_formateada: direccion['direccion_formateada'],
        detalles: direccion['detalles'],
        barrio: direccion['barrio'],
        localidad: direccion['localidad'],
        ciudad: direccion['ciudad'] ?? undefined,
        latitud: direccion['coordenadas']?.['latitud'],
        longitud: direccion['coordenadas']?.['longitud'],
        creacion_manual: direccion['creacion_manual'] ?? true,
      });
    }
    this._clientesService
      .actualizarCliente$(this._cliente!.cedula, datos)
      .then(() => {
        this._alertasService.alertarExito('Actualización exitosa');
        this._autenticacionService.limpiarUsuario();
        this._crearConfiguracion$();
      })
      .catch((error: ErrorFrontend) =>
        this._alertasService.alertarError('Error: ' + error.message),
      );
  }
}
