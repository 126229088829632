import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CONFIGURACION_FICHA_PREPARAR_ORDEN } from './utilidades/preparar-orden.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { OrdenesService } from '../../utilidades/ordenes.service';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Orden } from '../../utilidades/orden.class';
import { firstValueFrom, map } from 'rxjs';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { EstadoOrden, ItemOrdenDTO } from '../../utilidades/ordenes.models';
import {
  ESTADOS_ORDEN_CONFIRMADA,
  ESTADOS_ORDEN_PREPARADA,
} from '../../utilidades/ordenes.static';

@Component({
  selector: 'orden-preparar-orden',
  templateUrl: './preparar-orden.component.html',
  styleUrls: ['./preparar-orden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrepararOrdenComponent implements OnInit {
  /* ID de la orden */
  @Input() public idOrden: number | undefined;

  /* Emite si la orden se ha preparado */
  @Output() protected readonly preparada = new EventEmitter<boolean>();

  /* Orden */
  protected orden: Orden | undefined;

  /* Cantidades Preparadas */
  protected preparado: (number | null)[] = [];
  protected get validezPreparado(): boolean {
    return this.preparado.every((cantidad: number | null) => cantidad !== null);
  }

  /* Configuración ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_PREPARAR_ORDEN);

  /* Constantes */
  protected readonly EstadoOrden = EstadoOrden;
  protected readonly ESTADOS_ORDEN_PREPARADA = ESTADOS_ORDEN_PREPARADA;
  protected readonly ESTADOS_ORDEN_CONFIRMADA = ESTADOS_ORDEN_CONFIRMADA;

  constructor(
    private readonly _alertasService: AlertasService,
    private readonly _ordenesService: OrdenesService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Obtener la orden
    firstValueFrom<number>(
      this._route.params.pipe(map((params: Params) => parseInt(params['id']))),
    ).then((id: number | undefined) => {
      const idOrden = this.idOrden ?? id;
      this._ordenesService
        .obtenerOrden$(idOrden!)
        .then((orden: Orden | undefined) => {
          this.orden = orden;
          if (this.orden === undefined)
            throw new ErrorFrontend('No se encontró la orden');
          this.preparado = this.orden.items?.map(() => null) ?? [];
          this._cdr.markForCheck();
        });
    });
  }

  /**
   * Establece la cantidad preparada de un item.
   * @param {number} index - Índice del item.
   * @param {number | string} cantidad - Cantidad preparada.
   * @author Juan Corral
   */
  protected establecerCantidad(index: number, cantidad: number | string): void {
    if (typeof cantidad === 'string') cantidad = parseInt(cantidad);
    this.preparado[index] = cantidad;
    this._cdr.markForCheck();
  }

  /**
   * Prepara la orden.
   * @author Juan Corral
   */
  protected prepararOrden(): void {
    const items: ItemOrdenDTO[] = this.orden?.items ?? [];
    const mapa = new Map<number, number>();
    items.forEach((item: ItemOrdenDTO, index: number) => {
      mapa.set(item.id, this.preparado[index]!);
    });
    this._ordenesService
      .prepararOrden$(this.orden!.id, mapa)
      .then(() => {
        this._alertasService.alertarExito('Orden preparada con éxito');
        this.preparada.emit(true);
        if (!this.idOrden) this._router.navigate(['ordenes']);
      })
      .catch((error: ErrorFrontend) => {
        this._alertasService.alertarError(error.message);
        this.preparada.emit(false);
        this._cdr.markForCheck();
      });
  }
}
