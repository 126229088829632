<h2 mat-dialog-title>{{ configuracion.titulo }}</h2>
<div mat-dialog-content>
  <p>{{ configuracion.mensaje }}</p>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button
    mat-button
    color="warn"
    tabindex="-1"
    *ngIf="configuracion.botonSecundario"
    (click)="dialogRef.close(false)"
  >
    {{ configuracion.botonSecundario }}
  </button>
  <button
    mat-flat-button
    color="primary"
    tabindex="-1"
    (click)="dialogRef.close(true)"
  >
    {{ configuracion.botonPrincipal }}
  </button>
</div>
