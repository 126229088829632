import {
  DireccionDTO,
  ClienteDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';
import { RutaDTO, DomicilioDTO, EstadoDomicilio } from './domicilios.models';

/* Domicilio */
export class Domicilio {
  constructor(
    public id: number,
    public referencia: string,
    public titulo: string,
    public estado: EstadoDomicilio,
    public estadoDisplay: string,
    public fechaCreacion: string,
    public fechaPlaneada: string,
    public idSimpli: number | null | undefined,
    public ruta: RutaDTO | null | undefined,
    public trackingId: string | null | undefined,
    public direccion: DireccionDTO,
    public cliente: ClienteDTO,
    public errores: string | null | undefined,
    public notas: string | null | undefined,
    public orden: number | null | undefined,
  ) {}

  /**
   * Devuelve los datos del domicilio con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      referencia: this.referencia,
      titulo: this.titulo,
      estado: this.estadoDisplay,
      fecha_creacion: this.fechaCreacion,
      fecha_planeada: this.fechaPlaneada,
      id_simpli: this.idSimpli,
      vehiculo: this.ruta?.vehiculo?.nombre,
      tracking_id: this.trackingId,
      direccion: this.direccion.direccion_formateada,
      cliente: this.cliente.nombre,
      cedula: this.cliente.cedula,
      notas: this.notas,
      orden: this.orden,
    };
  }

  /**
   * Crea un objeto Domicilio a partir de los datos del API
   * @param {DomicilioDTO} domicilio - El domiclio como llega del API
   * @returns {Domicilio} - El objeto Domicilio creado
   * @author Juan Corral
   */
  public static fromDTO(domicilio: DomicilioDTO): Domicilio {
    return new Domicilio(
      domicilio.id,
      domicilio.referencia,
      domicilio.titulo,
      domicilio.estado,
      domicilio.estado_display,
      formatearFecha(domicilio.fecha_creacion),
      formatearFecha(domicilio.fecha_planeada),
      domicilio.id_simpli,
      domicilio.ruta,
      domicilio.tracking_id,
      domicilio.direccion,
      domicilio.cliente,
      domicilio.errores,
      domicilio.notas,
      domicilio.orden,
    );
  }
}
