<compartido-ficha [configuracion]="configFicha">
  <compartido-formulario
    [class.oculto]="cargando"
    [configuracion]="configFormulario"
    (valores)="valorFormulario = $event"
    (valido)="validezFormulario = $event"
  ></compartido-formulario>

  <!-- Items -->
  <!-- <div class="items">
    <h3 class="titulo">Items</h3>
    <div
      class="item"
      *ngFor="let configuracion of configFormulariosItems; let i = index"
    >
      <compartido-formulario
        [configuracion]="configuracion"
        (valores)="valoresFormulariosItems[i] = $event"
        (valido)="validezFormulariosItems[i] = $event"
      ></compartido-formulario>
      <button mat-icon-button (click)="eliminarItem(i)" color="warn">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <button
    mat-flat-button
    color="secondary"
    (click)="agregarItem()"
    class="boton-agregar"
  >
    Agregar Item <mat-icon>add</mat-icon>
  </button> -->

  <!-- Fórmulas -->
  <div class="formulas">
    <h3 class="titulo">Fórmulas</h3>
    <div
      class="item"
      *ngFor="let configuracion of configFormulariosFormulas; let i = index"
    >
      <compartido-formulario
        [configuracion]="configuracion"
        (valores)="valoresFormulariosFormulas[i] = $event"
        (valido)="validezFormulariosFormulas[i] = $event"
      ></compartido-formulario>
      <button mat-icon-button (click)="eliminarFormula(i)" color="warn">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <button
    mat-flat-button
    color="secondary"
    (click)="agregarFormula()"
    class="boton-agregar"
  >
    Agregar Fórmula <mat-icon>add</mat-icon>
  </button>

  <button
    mat-flat-button
    color="primary"
    [disabled]="!validezFormulario || !validezItems || !validezFormulas"
    (click)="crearOrden()"
    class="boton-crear"
  >
    Crear <mat-icon>done</mat-icon>
  </button>
</compartido-ficha>
