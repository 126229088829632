import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permiso } from '../nucleo/autorizacion/autorizacion.models';
import { ImportarOrdenesComponent } from './paginas/importar-ordenes/importar-ordenes.component';
import { TablaOrdenesComponent } from './paginas/tabla-ordenes/tabla-ordenes.component';
import { VerOrdenComponent } from './paginas/ver-orden/ver-orden.component';
import { CrearOrdenComponent } from './paginas/crear-orden/crear-orden.component';
import { AprobarOrdenComponent } from './paginas/aprobar-orden/aprobar-orden.component';
import { PrepararOrdenComponent } from './paginas/preparar-orden/preparar-orden.component';
import { PrepararOrdenesComponent } from './paginas/preparar-ordenes/preparar-ordenes.component';
import { AprobarOrdenesComponent } from './paginas/aprobar-ordenes/aprobar-ordenes.component';

export const routes: Routes = [
  {
    path: '',
    component: TablaOrdenesComponent,
    title: 'Órdenes',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'importar',
    component: ImportarOrdenesComponent,
    title: 'Importar Órdenes',
    data: {
      objeto: 'orden',
      permisos: [Permiso.CREAR_MASIVO],
    },
  },
  {
    path: 'crear',
    component: CrearOrdenComponent,
    title: 'Crear Orden',
    data: {
      objeto: 'orden',
      permisos: [Permiso.CREAR],
    },
  },
  {
    path: 'aprobar',
    component: AprobarOrdenesComponent,
    title: 'Aprobar Órdenes',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER_MASIVO, Permiso.VER, Permiso.APROBAR],
    },
  },
  {
    path: 'aprobar/:id',
    component: AprobarOrdenComponent,
    title: 'Aprobar Orden',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER, Permiso.APROBAR],
    },
  },
  {
    path: 'preparar',
    component: PrepararOrdenesComponent,
    title: 'Preparar Órdenes',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER_MASIVO, Permiso.VER, Permiso.PREPARAR],
    },
  },
  {
    path: 'preparar/:id',
    component: PrepararOrdenComponent,
    title: 'Preparar Orden',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER, Permiso.PREPARAR],
    },
  },
  {
    path: ':id',
    component: VerOrdenComponent,
    title: 'Ver Orden',
    data: {
      objeto: 'orden',
      permisos: [Permiso.VER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdenesRoutingModule {}
