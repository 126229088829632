import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AutorizacionService } from './autorizacion.service';

/**
 * Guardian que verifica si el usuario tiene acceso a la página solicitada.
 * @author Juan Corral
 */
export const autorizacionGuard: CanActivateFn = async (route, state) => {
  const autorizacionService = inject(AutorizacionService);
  const router = inject(Router);
  const objeto = route.data['objeto'];
  const permisos = route.data['permisos'];
  const acceso =
    objeto && permisos
      ? await autorizacionService.tienePermisos$(objeto, permisos)
      : true;
  if (!acceso) return router.createUrlTree(['']);
  return true;
};
