import * as moment from 'moment';

/**
 * Devuelve si el string está vacío.
 * @param string - String a comprobar.
 * @returns {boolean} - Si el string está vacío.
 * @author Juan Corral
 */
export function stringVacío(string: string | null | undefined): boolean {
  return string === null || string === undefined || string === '';
}

/**
 * Devuelve si el número está vacío.
 * @param numero - Número a comprobar.
 * @returns {boolean} - Si el número está vacío.
 * @author Juan Corral
 */
export function numeroVacío(numero: number | null | undefined): boolean {
  return numero === null || numero === undefined || isNaN(numero);
}

/**
 * Devuelve si el valor está vacío.
 * @param valor - El valor a comprobar.
 * @returns {boolean} - Si el valor está vacío.
 * @author Juan Corral
 */
export function valorVacío(valor: number | string | null | undefined): boolean {
  if (typeof valor === 'number') return numeroVacío(valor);
  return stringVacío(valor);
}

/**
 * Devuelve el valor como número o undefined si no se pudo formatear.
 * @param {string | number | undefined} valor - El valor a formatear.
 * @param {'int' | 'float'} tipo - El tipo de número a formatear.
 * @returns {number | undefined} - El string como número o undefined si no se pudo formatear.
 * @author Juan Corral
 */
export function valorNumérico(
  valor: string | number | undefined,
  tipo: 'int' | 'float' = 'int',
): number | undefined {
  if (valor === undefined) return undefined;

  let nuevoNumero: number;
  if (typeof valor === 'string') {
    nuevoNumero = parseInt(valor);
    if (tipo == 'float') nuevoNumero = parseFloat(valor);
  } else {
    nuevoNumero = valor;
  }
  if (isNaN(nuevoNumero)) return undefined;
  return nuevoNumero;
}

/**
 * Devuelve el string con la primera letra de cada palabra en mayúscula.
 * @param {string | undefined} str - El string a capitalizar.
 * @returns {string} - El string capitalizado.
 * @author Juan Corral
 */
export function capitalizar(str: string | undefined): string {
  if (str === undefined) return '';
  str = str.trim();
  return str
    .split(' ')
    .map((palabra) => {
      if (palabra.length === 0) return '';
      return palabra[0].toUpperCase() + palabra.substring(1).toLowerCase();
    })
    .join(' ');
}

/**
 * Formatea la fecha en el formato especificado.
 * @param {string | number | Date | moment.Moment} valor - La fecha a formatear.
 * @param {string} formato - El formato de fecha a formatear.
 * @returns {string} - La fecha formateada en el formato especificado o '' si no se pudo formatear la fecha.
 * @author Juan Corral
 */
export function formatearFecha(
  valor: string | number | Date | moment.Moment,
  formato = 'YYYY-MM-DD',
): string {
  if (moment.isMoment(valor)) return valor.format(formato);
  return moment(valor).format(formato);
}

/**
 * Convierte un string de fecha a un objeto moment.
 * @param {string} fecha - La fecha a convertir.
 * @returns {moment.Moment | undefined} - La fecha convertida o undefined si no se pudo convertir la fecha.
 * @author Juan Corral
 */
export function toMoment(fecha: string): moment.Moment | undefined {
  const obj = moment(fecha);
  return obj;
}

/**
 * Formatea el texto a UTF-8.
 * @param {string} texto - El texto a formatear.
 * @returns {string} - El texto formateado a UTF-8.
 * @author Juan Corral
 */
export function formatearUTF8(texto: string) {
  const decoder = new TextDecoder('utf-8');
  const encoder = new TextEncoder();

  // Convertir string a bytes
  const bytes = encoder.encode(texto);
  // Convert bytes devuelta a string
  let decodificado = decoder.decode(bytes);

  // TODO: Esto es un parche, hay que arreglarlo
  decodificado = decodificado.replace(/Ã¡/g, 'á');
  decodificado = decodificado.replace(/Ã©/g, 'é');
  decodificado = decodificado.replace(/Ã­/g, 'í');
  decodificado = decodificado.replace(/Ã³/g, 'ó');
  decodificado = decodificado.replace(/Ãº/g, 'ú');
  decodificado = decodificado.replace(/Ã‘/g, 'ñ');

  return decodificado;
}

/**
 * Normaliza un string (Minúsculas, sin acentos, y sin caracteres especiales).
 * @param {string} texto - El texto a normalizar.
 * @returns {string} - El texto normalizado.
 * @author Juan Corral
 */
export function normalizarString(texto: string): string {
  return texto
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

/**
 * Devuelve un string aleatorio.
 * @param {number} tamaño - El tamaño del string.
 * @returns {string} - El string aleatorio.
 * @author Juan Corral
 */
export function stringAleatorio(tamaño: number): string {
  const caracteres =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let resultado = '';
  for (let i = 0; i < tamaño; i++) {
    resultado += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length),
    );
  }
  return resultado;
}
