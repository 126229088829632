import { DatosTabla, FilaTabla } from '../../tabla/utilidades/tabla.models';

/* Datos tabla asincrona */
export interface DatosTablaAsincrona {
  datos: DatosTabla;
  total: number;
  objetos: Object[];
}

/* Prototipo de objeto que se puede formatear para una tabla */
export abstract class FormateableTabla {
  /**
   * Devuelve los datos del objeto con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public abstract datosTabla(): FilaTabla;
}

/* Estados de la herramienta de selección de la tabla */
export enum EstadoSeleccion {
  COMPLETA = 'completa',
  SELECCIONANDO = 'seleccionando',
  DESELECCIONANDO = 'deseleccionando',
  VACIA = 'vacia',
}

/* Selección generada en la tabla */
export type SeleccionAsincrona = {
  estado: EstadoSeleccion;
  seleccion: any[]; // El tipe de la selección depende de la columna de selección
  total: number;
};
