import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaSincrona,
  TipoColumnaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { PESO_MINIMO } from 'src/app/paquetes/utilidades/paquete.class';

/* Configuración Ficha Formulario */
export const CONFIGURACION_FICHA_FORMULARIO_AGREGAR_PAQUETE: ConfiguracionFicha =
  {
    header: { titulo: 'Agregar al lote', icono: Icono.PAQUETES },
    contenido: { padding: true },
  };

/* Configuración Formulario */
export const CONFIGURACION_FORMULARIO_AGREGAR_PAQUETE: ConfiguracionFormulario =
  {
    estilo: {
      display: DisplayFormulario.GRID,
      posicionLabel: PosicionLabelFormulario.TOP,
      widthCampo: 130,
      widthLabel: 130,
      // esconderErrores: true,
    },
    campos: [
      {
        nombre: 'Organización',
        slug: 'organizacion',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        opciones: [], // Se llena en el componente
        requerido: true,
      },
      {
        nombre: 'Fecha',
        slug: 'fecha',
        tipo: TipoCampoFormulario.FECHA,
        requerido: true,
        minimo: new Date().toISOString(),
      },
      {
        nombre: 'Origen',
        slug: 'origen',
        tipo: TipoCampoFormulario.TEXTO,
        requerido: true,
      },
      {
        nombre: 'Destino',
        slug: 'destino',
        tipo: TipoCampoFormulario.TEXTO,
        requerido: true,
      },
      {
        nombre: 'Vehículo',
        slug: 'vehiculo',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        opciones: [], // Se llena en el componente
        requerido: true,
      },
      {
        nombre: 'Largo',
        slug: 'largo',
        tipo: TipoCampoFormulario.NUMERO,
        requerido: true,
        minimo: 1,
        sufijo: 'cm',
      },
      {
        nombre: 'Ancho',
        slug: 'ancho',
        tipo: TipoCampoFormulario.NUMERO,
        requerido: true,
        minimo: 1,
        sufijo: 'cm',
      },
      {
        nombre: 'Alto',
        slug: 'alto',
        tipo: TipoCampoFormulario.NUMERO,
        requerido: true,
        minimo: 1,
        sufijo: 'cm',
      },
      {
        nombre: 'Peso',
        slug: 'peso',
        tipo: TipoCampoFormulario.NUMERO,
        requerido: true,
        minimo: PESO_MINIMO,
        sufijo: 'kg',
      },
      {
        nombre: 'Notas',
        slug: 'notas',
        tipo: TipoCampoFormulario.TEXTO,
      },
    ],
  };

/* Configuración Ficha Tabla */
export const CONFIGURACION_FICHA_TABLA_AGREGAR_PAQUETE: ConfiguracionFicha = {
  header: {
    titulo: 'Subir lote',
    icono: Icono.SUBIR,
    botones: [
      // { nombre: 'Subir', slug: 'subir', icono: Icono.SUBIR, primario: true },
      // { nombre: 'Descargar', slug: 'descargar', icono: Icono.DESCARGAR },
    ],
  },
};

/* Configuración Tabla Calcular Costo */
export const CONFIGURACION_TABLA_AGREGAR_PAQUETE: ConfiguracionTablaSincrona = {
  columnas: [
    {
      slug: 'organizacion',
      nombre: 'Organización',
      ordenable: true,
      tipo: TipoColumnaTabla.TEXTO,
    },
    {
      slug: 'fecha',
      nombre: 'Fecha',
      ordenable: true,
      tipo: TipoColumnaTabla.FECHA,
    },
    {
      slug: 'origen',
      nombre: 'Origen',
      ordenable: true,
      tipo: TipoColumnaTabla.TEXTO,
    },
    {
      slug: 'destino',
      nombre: 'Destino',
      ordenable: true,
      tipo: TipoColumnaTabla.TEXTO,
    },
    // { slug: 'largo', nombre: 'Largo', tipo: TipoColumnaTabla.NUMERO },
    // { slug: 'ancho', nombre: 'Ancho', tipo: TipoColumnaTabla.NUMERO },
    // { slug: 'alto', nombre: 'Alto', tipo: TipoColumnaTabla.NUMERO },
    {
      slug: 'dimensiones',
      nombre: 'Dimensiones',
      tipo: TipoColumnaTabla.TEXTO,
    },
    { slug: 'peso', nombre: 'Peso', tipo: TipoColumnaTabla.NUMERO },
    {
      slug: 'pesoVirtual',
      nombre: 'Peso Virtual',
      tipo: TipoColumnaTabla.NUMERO,
    },
    {
      slug: 'precio',
      nombre: 'Precio',
      ordenable: true,
      tipo: TipoColumnaTabla.MONEDA,
    },
  ],
  acciones: [{ slug: 'eliminar', nombre: 'Eliminar', icono: Icono.ELIMINAR }],
};
