<compartido-ficha [configuracion]="configFicha">
  <mat-stepper orientation="horizontal" linear>
    <!-- Paso 1: Formulario -->
    <mat-step [completed]="validezFormulario" [editable]="false">
      <ng-template matStepLabel>Selección</ng-template>

      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="valorFormulario = $event"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        (click)="buscarOrdenes()"
        [disabled]="!validezFormulario"
      >
        Continuar <mat-icon>navigate_next</mat-icon>
      </button>
    </mat-step>

    <!-- Paso 2: Órdenes -->
    <mat-step [editable]="false">
      <ng-template matStepLabel>Aprobación</ng-template>

      <p *ngIf="dataSource.total > 0" class="texto">
        Las siguientes órdenes están pendientes de aprobar:
      </p>

      <p
        *ngIf="
          dataSource.total === 0 &&
          aprobadas.length === 0 &&
          rechazadas.length === 0
        "
        class="texto color-warn"
      >
        ¡No hay órdenes pendientes de aprobar para esta selección!
      </p>

      <p
        *ngIf="
          dataSource.total === 0 &&
          (aprobadas.length > 0 || rechazadas.length > 0)
        "
        class="texto"
      >
        ¡Todas las órdenes han sido revisadas!
      </p>

      <compartido-tabla-asincrona
        #tabla
        class="tabla {{ responsiveService.claseCSS$ | async }}"
        [class.oculta]="dataSource.total === 0"
        [dataSource]="dataSource"
        [configuracion]="configTabla"
        (clickAccion)="aprobarOrden$($event.fila['id'])"
      ></compartido-tabla-asincrona>

      <p *ngIf="aprobadas.length > 0" class="texto aprobadas">
        Órdenes aprobadas: {{ aprobadas.length }}
      </p>

      <p *ngIf="rechazadas.length > 0" class="texto aprobadas">
        Órdenes rechazadas: {{ rechazadas.length }}
      </p>

      <button
        class="boton-stepper"
        mat-flat-button
        matStepperNext
        color="primary"
        routerLink="/"
      >
        Finalizar <mat-icon>done</mat-icon>
      </button>
    </mat-step>
  </mat-stepper>
</compartido-ficha>
