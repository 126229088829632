import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CONFIGURACION_FICHA_DESPACHAR_DOMICILIO } from './utilidades/despachar-domicilio.static';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import { ErrorFrontend } from 'src/app/compartido/utilidades/error.utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { DomiciliosService } from '../../utilidades/domicilios.service';
import { Domicilio } from '../../utilidades/domicilio.class';
import { EstadoDomicilio } from '../../utilidades/domicilios.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

@Component({
  selector: 'domicilio-despachar-domicilio',
  templateUrl: './despachar-domicilio.component.html',
  styleUrls: ['./despachar-domicilio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DespacharDomicilioComponent implements OnInit {
  /* ID del domicilio */
  @Input() public idDomicilio: number | undefined;

  /* Emite si el domicilio se ha despachado */
  @Output() protected readonly despachado = new EventEmitter<boolean | null>();

  /* Domicilio */
  protected domicilio: Domicilio | undefined;

  /* Configuración ficha */
  protected configFicha = deepCopy(CONFIGURACION_FICHA_DESPACHAR_DOMICILIO);

  /* Constantes */
  protected readonly EstadoDomicilio = EstadoDomicilio;
  protected readonly Icono = Icono;

  constructor(
    private readonly _alertasService: AlertasService,
    private readonly _domiciliosService: DomiciliosService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    // Obtener el domicilio
    firstValueFrom<number>(
      this._route.params.pipe(map((params: Params) => parseInt(params['id']))),
    ).then((id: number | undefined) => {
      const idDomicilio = this.idDomicilio ?? id;
      this._domiciliosService
        .obtenerDomicilio$(idDomicilio!)
        .then((domicilio: Domicilio | undefined) => {
          this.domicilio = domicilio;
          if (this.domicilio === undefined)
            throw new ErrorFrontend('No se encontró el domicilio');
          this._cdr.markForCheck();
        });
    });
  }

  /**
   * Despacha el domicilio.
   * @author Juan Corral
   */
  protected despacharDomicilio(): void {
    this._domiciliosService
      .despacharDomicilio$(this.domicilio!.id)
      .then(() => {
        this._alertasService.alertarExito('Domicilio despachado con éxito');
        this.despachado.emit(true);
        if (!this.idDomicilio) this._router.navigate(['domicilios']);
      })
      .catch((error: ErrorFrontend) => {
        this._alertasService.alertarError(error.message);
        this.despachado.emit(null);
        this._cdr.markForCheck();
      });
  }
}
