<compartido-ficha [configuracion]="configFicha" *ngIf="orden">
  <div class="orden {{ responsiveService.claseCSS$ | async }}">
    <div class="top fondo-primario color-primario-contraste">
      <mat-icon color="primary" class="fondo-primario-contraste">
        receipt
      </mat-icon>
      <h2>{{ orden.titulo }}</h2>
    </div>
    <div class="resto mat-app-background">
      <mat-list>
        <ng-container *ngIf="orden.estado === EstadoOrden.CONFIRMADA">
          <!-- Detalles -->
          <mat-list-item>
            <mat-icon matListItemIcon>numbers</mat-icon>
            <h3 matListItemTitle>No. Referencia</h3>
            <p matListItemLine>{{ orden.referencia ?? "-" }}</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <h3 matListItemTitle>Cliente</h3>
            <p matListItemLine>{{ orden.cliente.nombre }}</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>calendar_month</mat-icon>
            <h3 matListItemTitle>Fecha Planeada</h3>
            <p matListItemLine>
              {{ orden.fechaPlaneada | date: "dd/MM/yyyy" }}
            </p>
          </mat-list-item>

          <mat-divider
            *ngIf="
              (orden?.items ?? []).length >= 0 ||
              (orden?.formulas ?? []).length >= 0
            "
          ></mat-divider>

          <!-- Items -->
          <mat-list-item
            *ngFor="let item of orden?.items; let i = index"
            class="item"
          >
            <mat-icon matListItemIcon>medication_liquid</mat-icon>
            <h3 matListItemTitle>{{ item.producto.nombre }}</h3>
            <p matListItemLine>x{{ item.cantidad }}</p>
            <div matListItemMeta class="completado">
              <mat-selection-list [multiple]="false">
                <mat-list-option
                  [selected]="preparado[i] === item.cantidad"
                  (selectedChange)="establecerCantidad(i, item.cantidad)"
                >
                  Completo
                </mat-list-option>
                <mat-list-option
                  [selected]="!!preparado[i] && preparado[i]! < item.cantidad"
                  (selectedChange)="establecerCantidad(i, 0)"
                >
                  Incompleto
                </mat-list-option>
              </mat-selection-list>
              <div
                class="cantidad"
                [class.oculto]="
                  preparado[i] === null || preparado[i] === item.cantidad
                "
              >
                <p>Cantidad:</p>
                <select
                  [ngModel]="preparado[i]"
                  (ngModelChange)="establecerCantidad(i, $event)"
                >
                  <option
                    *ngFor="
                      let _ of [].constructor(item.cantidad);
                      let j = index
                    "
                    [value]="j"
                  >
                    {{ j }}
                  </option>
                </select>
              </div>
            </div>
          </mat-list-item>

          <!-- Fórmulas -->
          <mat-list-item
            *ngFor="let formula of orden?.formulas; let i = index"
            class="formula"
          >
            <mat-icon matListItemIcon>medical_services</mat-icon>
            <h3 matListItemTitle>Fórmula #{{ i + 1 }}</h3>
            <p matListItemLine>
              Ref. {{ formula.referencia }} -
              <a [href]="formula.archivo" target="_blank">Archivo</a>
            </p>
          </mat-list-item>
        </ng-container>

        <!-- Ordenes con otros estados  -->
        <mat-list-item *ngIf="ESTADOS_ORDEN_PREPARADA.includes(orden.estado)">
          <mat-icon matListItemIcon>block</mat-icon>
          <h3 matListItemTitle>Orden Preparada</h3>
          <p matListItemLine>La orden ya ha sido preparada anteriormente</p>
        </mat-list-item>
        <mat-list-item *ngIf="!ESTADOS_ORDEN_CONFIRMADA.includes(orden.estado)">
          <mat-icon matListItemIcon>block</mat-icon>
          <h3 matListItemTitle>Orden sin Confirmar</h3>
          <p matListItemLine>
            La orden no ha sido confirmada aún, no se puede preparar
          </p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <button
    mat-flat-button
    class="boton-preparar"
    color="primary"
    tabindex="-1"
    [disabled]="!validezPreparado || orden.estado !== EstadoOrden.CONFIRMADA"
    (click)="prepararOrden()"
  >
    Preparada <mat-icon>done</mat-icon>
  </button>
</compartido-ficha>
