import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgregarPaquetesComponent } from './paginas/agregar-paquetes/agregar-paquetes.component';
import { TablaPaquetesComponent } from './paginas/tabla-paquetes/tabla-paquetes.component';
import { Permiso } from '../nucleo/autorizacion/autorizacion.models';

export const routes: Routes = [
  {
    path: '',
    component: TablaPaquetesComponent,
    title: 'Paquetes',
    data: {
      objeto: 'paquete',
      permisos: [Permiso.VER_MASIVO],
    },
  },
  {
    path: 'agregar',
    component: AgregarPaquetesComponent,
    title: 'Agregar Paquetes',
    data: {
      objeto: 'paquete',
      permisos: [Permiso.CREAR_MASIVO],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaquetesRoutingModule {}
