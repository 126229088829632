import { RolUsuario } from 'src/app/nucleo/autorizacion/autorizacion.models';
import {
  CampoFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import { ConfiguracionRecurso } from 'src/app/compartido/recurso/utilidades/recurso.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';

/* Campos de la sección: Información */
const CAMPOS_INFORMACION_CLIENTE: CampoFormulario[] = [
  {
    nombre: 'Nombre',
    slug: 'nombre',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Cédula',
    slug: 'cedula',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Teléfono',
    slug: 'telefono',
    tipo: TipoCampoFormulario.NUMERO,
  },
  {
    nombre: 'Correo',
    slug: 'correo',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Organización',
    slug: 'organizacion',
    tipo: TipoCampoFormulario.OPCION_MULTIPLE,
    opciones: [], // Se obtiene del servidor
    roles: [RolUsuario.ELEVA],
  },
];

/* Campos de la sección: Dirección */
export const CAMPOS_DIRECCION_CLIENTE: CampoFormulario[] = [
  // {
  //   nombre: 'ID',
  //   slug: 'id',
  //   tipo: TipoCampoFormulario.NUMERO,
  //   roles: [RolUsuario.ELEVA],
  // },
  {
    nombre: 'Dirección',
    slug: 'direccion',
    tipo: TipoCampoFormulario.AUTOCOMPLETE,
    opciones: [], // Se obtiene del servidor
    requerido: true,
  },
  {
    nombre: 'Formateada',
    slug: 'direccion_formateada',
    tipo: TipoCampoFormulario.TEXTO,
    roles: [RolUsuario.ELEVA],
  },
  {
    nombre: 'Detalles',
    slug: 'detalles',
    tipo: TipoCampoFormulario.TEXTO,
    placeholder: 'Apartamento, casa, torre, etc.',
  },
  {
    nombre: 'Barrio',
    slug: 'barrio',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Localidad',
    slug: 'localidad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Ciudad',
    slug: 'ciudad',
    tipo: TipoCampoFormulario.TEXTO,
  },
  {
    nombre: 'Latitud',
    slug: 'latitud',
    tipo: TipoCampoFormulario.NUMERO,
    roles: [RolUsuario.ELEVA],
  },
  {
    nombre: 'Longitud',
    slug: 'longitud',
    tipo: TipoCampoFormulario.NUMERO,
    roles: [RolUsuario.ELEVA],
  },
  {
    nombre: 'Coordenadas',
    slug: 'coordenadas',
    tipo: TipoCampoFormulario.COORDENADAS,
    placeholder: 'Seleccione una ubicación',
  },
  {
    nombre: 'Creación manual',
    slug: 'creacion_manual',
    tipo: TipoCampoFormulario.CHECKBOX,
    placeholder: 'Sí',
    roles: [RolUsuario.ELEVA],
  },
];

/* Configuración del recurso: Cliente */
export const CONFIGURACION_RECURSO_CLIENTE: ConfiguracionRecurso = {
  nombre: '', // Se obtiene del cliente
  icono: Icono.PERFIL,
  objeto: 'cliente',
  secciones: [
    {
      nombre: 'Información',
      slug: 'informacion',
      editable: true,
      subsecciones: [
        {
          slug: 'informacion',
          formulario: { campos: CAMPOS_INFORMACION_CLIENTE },
          readonly: ['cedula'],
        },
      ],
    },
    {
      nombre: 'Direcciones',
      slug: 'direcciones',
      editable: true,
      subsecciones: [],
    },
  ],
};
