<compartido-ficha [configuracion]="configFicha" *ngIf="domicilio">
  <div class="domicilio {{ responsiveService.claseCSS$ | async }}">
    <div class="top fondo-primario color-primario-contraste">
      <mat-icon color="primary" class="fondo-primario-contraste">
        {{ Icono.DOMICILIOS }}
      </mat-icon>
      <h2>{{ domicilio.titulo }}</h2>
    </div>
    <div class="resto mat-app-background">
      <mat-list>
        <!-- Detalles -->
        <mat-list-item>
          <mat-icon matListItemIcon>numbers</mat-icon>
          <h3 matListItemTitle>No. Referencia</h3>
          <p matListItemLine>{{ domicilio.referencia }}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListItemIcon>receipt</mat-icon>
          <h3 matListItemTitle>Orden Asociada</h3>
          <p matListItemLine>{{ domicilio.orden }}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListItemIcon>person</mat-icon>
          <h3 matListItemTitle>Cliente</h3>
          <p matListItemLine>{{ domicilio.cliente.nombre }}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListItemIcon>calendar_month</mat-icon>
          <h3 matListItemTitle>Fecha Planeada</h3>
          <p matListItemLine>
            {{ domicilio.fechaPlaneada | date: "dd/MM/yyyy" }}
          </p>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <div class="fecha">
    <compartido-formulario
      [configuracion]="configFormulario"
      (valores)="valorFormulario = $event"
      (valido)="validezFormulario = $event"
    ></compartido-formulario>
  </div>

  <div class="botones">
    <button mat-flat-button color="warn" (click)="reprogramado.emit(null)">
      Cancelar <mat-icon>close</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="reprogramarDomicilio()"
      [disabled]="
        domicilio.estado !== EstadoDomicilio.FALLIDO || !validezFormulario
      "
    >
      Re-programar <mat-icon>done</mat-icon>
    </button>
  </div>
</compartido-ficha>
