<mat-stepper orientation="horizontal" linear>
  <!-- Paso 1: Bienvenida -->
  <mat-step [editable]="false">
    <ng-template matStepLabel>Bienvenida</ng-template>
    <div class="paso">
      <h2 class="titulo">¡Bienvenido a Atlas!</h2>
      <p class="descripcion">
        En este portal podrá ordenar sus medicamentos a domicilio y monitorear
        el progreso de sus órdenes y domicilios.
      </p>
      <button mat-raised-button color="primary" matStepperNext>
        Siguiente
      </button>
    </div>
  </mat-step>

  <!-- Paso 2: Explicación de secciones -->
  <mat-step [editable]="false">
    <ng-template matStepLabel>Explore Atlas</ng-template>
    <div class="paso">
      <h2 class="titulo">Explore Atlas</h2>
      <div class="paginas">
        <div class="pagina">
          <img
            class="crear"
            src="assets/icons/crear_orden.png"
            alt="Crear Orden"
          />
          <h3>Crear Orden</h3>
          <p>
            Cree una nueva orden de medicamentos y recíbala en la comodidad de
            su hogar.
          </p>
        </div>
        <div class="pagina">
          <img src="assets/icons/ordenes.png" alt="Órdenes" />
          <h3>Órdenes</h3>
          <p>
            Revise el estado de sus órdenes y reciba notificaciones de su
            progreso.
          </p>
        </div>
        <div class="pagina">
          <img src="assets/icons/clientes.png" alt="Mi Perfil" />
          <h3>Mi Perfil</h3>
          <p>
            Actualice su perfil con su información personal y dirección para
            recibir sus órdenes sin problemas.
          </p>
        </div>
      </div>
      <button mat-raised-button color="primary" matStepperNext>
        Siguiente
      </button>
    </div>
  </mat-step>

  <!-- Paso 3: Formulario de dirección -->
  <mat-step [editable]="false">
    <ng-template matStepLabel>Complete su perfil</ng-template>
    <div class="paso">
      <h2 class="titulo">Complete su perfil</h2>
      <p class="descripcion">Por favor ingrese su dirección:</p>
      <compartido-formulario
        [configuracion]="configFormulario"
        (valores)="valorFormulario = $event"
        (valido)="validezFormulario = $event"
      ></compartido-formulario>
      <button
        mat-raised-button
        color="primary"
        (click)="_crearDireccion$()"
        [disabled]="!validezFormulario"
      >
        Completar
      </button>
    </div>
  </mat-step>
</mat-stepper>
