import {
  DireccionDTO,
  ClienteDTO,
} from 'src/app/clientes/utilidades/clientes.models';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';
import { EstadoOrden, FormulaDTO, ItemOrdenDTO, OrdenDTO } from './ordenes.models';
import { DomicilioDTO } from 'src/app/domicilios/utilidades/domicilios.models';

/* Orden */
export class Orden {
  constructor(
    public id: number,
    public titulo: string,
    public referencia: string | null | undefined,
    public estado: EstadoOrden,
    public estadoDisplay: string,
    public fechaCreacion: string,
    public fechaPlaneada: string,
    public direccion: DireccionDTO,
    public cliente: ClienteDTO,
    public items: ItemOrdenDTO[] | null | undefined,
    public formulas: FormulaDTO[] | null | undefined,
    public notas: string | null | undefined,
    public antecesora?: Orden,
    public domicilio?: DomicilioDTO,
    public fechaAprobacion?: string,
    public autorAprobacion?: string,
    public motivoAprobacion?: string
  ) {}

  /**
   * Devuelve los datos de la orden con el formato para tabla
   * @returns {FilaTabla} - Los datos formateados para tabla
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      titulo: this.titulo,
      referencia: this.referencia,
      estado: this.estadoDisplay,
      fecha_creacion: this.fechaCreacion,
      fecha_planeada: this.fechaPlaneada,
      direccion: this.direccion.direccion_formateada,
      cliente: this.cliente.nombre,
      cedula: this.cliente.cedula,
      items: this.items ? this.items.length : 0,
      formulas: this.formulas ? this.formulas.length : 0,
      notas: this.notas,
      domicilio: this.domicilio?.titulo,
    };
  }

  /**
   * Crea un objeto Orden a partir de los datos del API.
   * @param {OrdenDTO} orden - La orden como llega del API.
   * @returns {Orden} - El objeto Orden creado.
   * @author Juan Corral
   */
  public static fromDTO(orden: OrdenDTO): Orden {
    return new Orden(
      orden.id,
      orden.titulo,
      orden.referencia,
      orden.estado,
      orden.estado_display,
      formatearFecha(orden.fecha_creacion),
      formatearFecha(orden.fecha_planeada),
      orden.direccion,
      orden.cliente,
      orden.items,
      orden.formulas,
      orden.notas,
      orden.antecesora ? Orden.fromDTO(orden.antecesora) : undefined,
      orden.domicilio,
      orden.fecha_aprobacion ? formatearFecha(orden.fecha_aprobacion) : undefined,
      orden.autor_aprobacion,
      orden.motivo_aprobacion
    );
  }
}
