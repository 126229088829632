import { EstadoDomicilio } from './domicilios.models';

/* Opciones del estado del domicilio */
export const OPCIONES_ESTADO_DOMICILIO: {
  nombre: string;
  valor: EstadoDomicilio;
}[] = [
  { nombre: 'En Cola', valor: EstadoDomicilio.COLA },
  { nombre: 'Error', valor: EstadoDomicilio.ERROR },
  { nombre: 'Pendiente', valor: EstadoDomicilio.PENDIENTE },
  { nombre: 'En Ruta', valor: EstadoDomicilio.RUTA },
  { nombre: 'Despachado', valor: EstadoDomicilio.DESPACHADO },
  { nombre: 'Completado', valor: EstadoDomicilio.COMPLETADO },
  { nombre: 'Entrega Fallida', valor: EstadoDomicilio.FALLIDO },
];

/* Máximo número de domicilios a descargar */
export const MAX_DOMICILIOS_DESCARGA = 5000;

/* Máximo número de etiquetas a descargar */
export const MAX_ETIQUETAS_DESCARGA = 1000;
