import { OrganizacionDTO } from 'src/app/clientes/utilidades/clientes.models';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';
import { PaqueteBase, PaqueteDTO } from './paquetes.models';
import { VehiculoDTO } from 'src/app/domicilios/utilidades/domicilios.models';

/* Paquete */
export class Paquete {
  constructor(
    public id: number,
    public organizacion: OrganizacionDTO,
    public fecha: string,
    public origen: string,
    public destino: string,
    public vehiculo: VehiculoDTO | null | undefined,
    public notas: string | null | undefined,
    public peso: number,
    public alto: number,
    public ancho: number,
    public largo: number,
    public precio: number,
  ) {}

  private get _pesoVirtual(): number {
    const volumen = this.largo * this.ancho * this.alto;
    const dimension = Math.ceil(volumen / FACTOR_DIMENSIONAL);
    return Math.max(this.peso, dimension);
  }

  private get _dimensiones(): string {
    return `${this.largo} x ${this.ancho} x ${this.alto}`;
  }

  /**
   * Devuelve los datos del paquete con el formato para tabla.
   * @returns {FilaTabla} - Los datos formateados para tabla.
   * @author Juan Corral
   */
  public datosTabla(): FilaTabla {
    return {
      id: this.id,
      organizacion: this.organizacion.nombre,
      fecha: this.fecha,
      origen: this.origen,
      destino: this.destino,
      vehiculo: this.vehiculo?.nombre,
      notas: this.notas,
      peso: this.peso,
      dimensiones: this._dimensiones,
      pesoVirtual: this._pesoVirtual,
      alto: this.alto,
      ancho: this.ancho,
      largo: this.largo,
      precio: this.precio,
    };
  }

  /**
   * Devuelve los datos del paquete con el formato base.
   * @returns {FilaTabla} - Los datos formateados para tabla.
   * @author Juan Corral
   */
  public aBase(): PaqueteBase {
    return {
      organizacion: this.organizacion.id,
      fecha: this.fecha,
      origen: this.origen,
      destino: this.destino,
      vehiculo: this.vehiculo?.id,
      peso: this.peso,
      alto: this.alto,
      ancho: this.ancho,
      largo: this.largo,
      precio: this.precio,
      notas: this.notas,
    };
  }

  /**
   * Crea un objeto Paquete a partir de los datos del API.
   * @param {PaqueteDTO} paquete - El paquete como llega del API.
   * @returns {Paquete} - El objeto Paquete creado.
   * @author Juan Corral
   */
  public static fromDTO(paquete: PaqueteDTO): Paquete {
    return new Paquete(
      paquete.id,
      paquete.organizacion,
      formatearFecha(paquete.fecha),
      paquete.origen,
      paquete.destino,
      paquete.vehiculo,
      paquete.notas,
      Number(paquete.peso),
      Number(paquete.alto),
      Number(paquete.ancho),
      Number(paquete.largo),
      Number(paquete.precio),
    );
  }

  /**
   * Devuelve el precio de un paquete a partir de sus dimensiones y peso.
   * @param {number} largo - El largo del paquete.
   * @param {number} ancho - El ancho del paquete.
   * @param {number} alto - El alto del paquete.
   * @param {number} peso - El peso del paquete.
   * @returns {number} - El precio del paquete.
   */
  public static calcularPrecio(
    largo: number,
    ancho: number,
    alto: number,
    peso: number,
  ): number {
    let precio = 0;
    const volumen = largo * ancho * alto;
    const dimension = Math.ceil(volumen / FACTOR_DIMENSIONAL);
    const pesoVirtual = Math.max(peso, dimension);

    if (pesoVirtual <= MAXIMO_PESO_TIERS) {
      precio = COSTOS_PAQUETES[Math.ceil(pesoVirtual)] || COSTO_MINIMO;
    } else if (pesoVirtual <= MAXIMO_PESO_25_KG) {
      precio = COSTOS_PAQUETES[MAXIMO_PESO_TIERS];
    } else {
      const pesoAdicional = pesoVirtual - MAXIMO_PESO_25_KG;
      precio =
        pesoAdicional * INCREMENTO_BASE_POR_KG +
        COSTOS_PAQUETES[MAXIMO_PESO_TIERS];
    }

    return Math.round(precio / 10) * 10;
  }
}

/* Constantes cálculo de precio */
export const VALOR_MINIMO_DECLARADO = 10000;
export const PESO_MINIMO = 1;
export const UMBRAL_PESO = 5;
export const INCREMENTO_BASE_POR_KG = 540;
export const FACTOR_DIMENSIONAL = 2700;
export const COSTO_MINIMO = 7500;
export const MAXIMO_PESO_25_KG = 25;
export const MAXIMO_PESO_TIERS = 7;
export const COSTOS_PAQUETES = [
  0, 7500, 8650, 11150, 13300, 15200, 18150, 18500,
];
