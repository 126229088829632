/* Prototipo para columnas */
export interface PrototipoColumna {
  nombre: string;
  slug: string;
  palabras: string[];
  requerida: boolean;
}

/* Estado de fila orden */
export enum EstadoFilaOrden {
  INCOMPLETA = 'incompleta',
  SIN_FORMATEAR = 'sin-formatear',
  FORMATEADA = 'formateada',
  SUBIDA = 'subida',
  FALLIDA = 'fallida',
}

/* Resultado de la subida de un archivo */
export interface ResultadoSubida {
  exitosos: number;
  fallidos: number;
  total: number;
}
