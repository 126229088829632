import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root', deps: [MatSnackBarModule] })
export class AlertasService {
  constructor(private readonly _barraAlertas: MatSnackBar) {}

  /**
   * Muestra una barra de alerta
   * @param {string} mensaje - El mensaje a mostrar
   * @author Juan Corral
   */
  public alertar(mensaje: string): void {
    this._barraAlertas.open(mensaje, 'Entendido', {
      duration: 5000,
    });
  }

  /**
   * Muestra una barra de alerta de error
   * @param {string} mensaje - El mensaje a mostrar
   * @author Juan Corral
   */
  public alertarError(mensaje: string): void {
    this._barraAlertas.open(mensaje, 'Entendido', {
      duration: 5000,
      panelClass: ['alerta-error'],
    });
  }

  /**
   * Muestra una barra de alerta de éxito
   * @param {string} mensaje - El mensaje a mostrar
   * @author Juan Corral
   */
  public alertarExito(mensaje: string): void {
    this._barraAlertas.open(mensaje, 'Entendido', {
      duration: 5000,
      panelClass: ['alerta-exito'],
    });
  }
}
