import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

@Component({
  selector: 'nucleo-esqueleto',
  templateUrl: './esqueleto.component.html',
  styleUrls: ['./esqueleto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsqueletoComponent {
  constructor(private readonly cdr: ChangeDetectorRef) {}

  /**
   * Alterna el estado del sidenav (abre/cierra)
   * @param {any} sidenav - La referencia del sidenav
   * @author Juan Corral
   */
  protected alternarSideNav(sidenav: any): void {
    sidenav.toggle();
    this.cdr.markForCheck();
  }
}
