import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  CampoFormulario,
  ConfiguracionFormulario,
  DisplayFormulario,
  OrientacionFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
  ValoresFormulario,
} from './utilidades/formulario.models';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { formatearFecha, toMoment } from '../utilidades/valores.utils';
import * as moment from 'moment';
import { AutorizacionService } from 'src/app/nucleo/autorizacion/autorizacion.service';
import {
  CENTRO_BOGOTA,
  CONFIGURACION_MAPA_FORMULARIO,
} from './utilidades/formulario.static';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'compartido-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss'],
})
export class FormularioComponent implements OnInit, OnChanges, OnDestroy {
  /* Suscripción */
  private _suscripcion: Subscription = new Subscription();

  /* Configuración del formulario */
  @Input({ required: true }) configuracion!: ConfiguracionFormulario;

  /* Formulario */
  protected formulario = new FormGroup({
    campos: new FormArray<FormControl>([]),
  });
  get campos() {
    return this.formulario.controls.campos as FormArray;
  }

  /* Tipos de campos */
  readonly TipoCampoFormulario = TipoCampoFormulario;

  /* Orientación de los campos */
  readonly OrientacionFormulario = OrientacionFormulario;

  /* Posiciones del label */
  readonly PosicionLabelFormulario = PosicionLabelFormulario;

  /* Configuración de los mapas */
  readonly CONFIGURACION_MAPA_FORMULARIO = CONFIGURACION_MAPA_FORMULARIO;

  /* Centro del mapa */
  readonly CENTRO_MAPA = CENTRO_BOGOTA;

  /* Output de Valores */
  @Output() valores: EventEmitter<ValoresFormulario> =
    new EventEmitter<ValoresFormulario>();

  /* Output de validez */
  @Output() valido: EventEmitter<boolean> = new EventEmitter<boolean>();

  /* Referencias para Mapas */
  @ViewChildren('mapa') private _mapas!: GoogleMap[];

  constructor(
    public readonly cdr: ChangeDetectorRef,
    protected readonly autorizacionService: AutorizacionService,
  ) {}

  ngOnInit() {
    const escucharCambios = this.formulario.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        map((valores) => this._formatearValores(valores)),
      )
      .subscribe((valores: ValoresFormulario) => {
        this.valido.next(this.formulario.valid);
        this.valores.next(valores);
      });
    this._suscripcion.add(escucharCambios);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.campos.clear();
    for (const campo of this.configuracion.campos) {
      this.campos.push(this._crearFormControl(campo));
    }
    this.valido.next(false);
    this.valores.next(this._formatearValores(this.formulario.value));
  }

  ngOnDestroy() {
    this._suscripcion.unsubscribe();
  }

  /**
   * Formatea los valores del formulario
   * @param {any} valores - Los valores del formulario
   * @returns {ValoresFormulario} - Los valores del formulario calculados
   * @author Juan Corral
   */
  private _formatearValores(valores: any): ValoresFormulario {
    const valoresFormulario: ValoresFormulario = {};
    for (const [index, valor] of valores.campos?.entries() ?? []) {
      const slug = this.configuracion.campos[index].slug;
      if (moment.isMoment(valor)) {
        valoresFormulario[slug] = formatearFecha(valor);
      } else {
        valoresFormulario[slug] = valor;
      }
    }
    return valoresFormulario;
  }

  /**
   * Limpia los campos del formulario
   * @author Juan Corral
   */
  public limpiarCampos(): void {
    this.campos.reset();
  }

  /**
   * Devuelve un FormControl con la configuración del campo
   * @param {CampoFormulario} campo - Configuración del campo
   * @returns {FormControl} - FormControl con la configuración del campo
   * @author Juan Corral
   */
  private _crearFormControl(campo: CampoFormulario): FormControl {
    // Crear el control
    let control: FormControl;
    switch (campo.tipo) {
      case TipoCampoFormulario.TEXTO:
        control = new FormControl<string | undefined>(undefined);
        if (campo.correo === true) control.addValidators(Validators.email);
        break;
      case TipoCampoFormulario.NUMERO:
        control = new FormControl<number | undefined>(undefined);
        break;
      case TipoCampoFormulario.FECHA:
        control = new FormControl<string | undefined>(undefined);
        break;
      case TipoCampoFormulario.ARCHIVO:
        control = new FormControl<File | undefined>(undefined);
        break;
      case TipoCampoFormulario.OPCION_MULTIPLE:
        control = new FormControl<string | number | undefined>(undefined);
        break;
      case TipoCampoFormulario.AUTOCOMPLETE:
        control = new FormControl<string | number | undefined>(undefined);
        // Suscribirse a la función de auto-completar, si existe
        if (campo.opcionesIn)
          this._suscripcion.add(
            campo.opcionesIn.subscribe((opciones) => {
              campo.opciones = opciones;
              this.cdr.markForCheck();
            }),
          );
        break;
      case TipoCampoFormulario.CHECKBOX:
        control = new FormControl<boolean | undefined>(undefined);
        break;
      case TipoCampoFormulario.COORDENADAS:
        control = new FormControl<
          { latitud: string; longitud: string } | undefined
        >(undefined);
        break;
      default:
        control = new FormControl(undefined);
    }

    // Asignar valor al control, si existe
    if (campo.valor !== undefined) {
      if (campo.tipo === TipoCampoFormulario.FECHA) {
        control.setValue(toMoment(campo.valor));
      } else {
        control.setValue(campo.valor);
      }
    }

    // Deshabilitar el control, si es requerido
    if (campo.disabled === true) control.disable();

    // Asignar validadores al control, si existen
    if (campo.requerido === true) control.addValidators(Validators.required);
    if (campo.validadores !== undefined)
      control.addValidators(
        campo.validadores.map((validador) => validador.validador),
      );

    // Emitir los valores del control, si es requerido
    if (campo.valorOut) {
      this._suscripcion.add(
        control.valueChanges.subscribe((valor) => {
          campo.valorOut!.next(valor as never);
        }),
      );
    }

    // Suscribirse a los valores del control, si es requerido
    if (campo.valorIn) {
      if (campo.tipo === TipoCampoFormulario.COORDENADAS) {
        this._suscripcion.add(
          campo.valorIn.subscribe((valor) => {
            this.ponerMarcadorMapa(campo, {
              lat: valor.latitud,
              lng: valor.longitud,
            });
          }),
        );
      } else
        this._suscripcion.add(
          campo.valorIn.subscribe((valor) => control.setValue(valor)),
        );
    }

    // Ocultar el campo, si es requerido por el rol
    if (campo.roles)
      this.autorizacionService.tieneRol$(campo.roles).then((tieneRol) => {
        campo.oculto = !tieneRol;
        this.cdr.markForCheck();
      });

    return control;
  }

  /**
   * Devuelve si el label debe ser mostrado en la posición proveída
   * @param {PosicionLabelFormulario} posicion - La posición a verificar
   * @returns {boolean} - Si el label debe ser mostrado en la posición proveída
   * @author Juan Corral
   */
  protected mostrarLabel(posicion: PosicionLabelFormulario): boolean {
    if (
      this.configuracion.estilo?.posicionLabel === undefined &&
      posicion === PosicionLabelFormulario.DENTRO
    )
      return true;
    return this.configuracion.estilo?.posicionLabel === posicion;
  }

  /**
   * Devuelve las clases para el tipo elemento proveído
   * @param {string} posicion - El tipo de elemento
   * @returns {string[]} - Las clases para el tipo elemento proveído
   * @author Juan Corral
   */
  protected obtenerClases(elemento: string): string[] {
    const clases: string[] = [];

    if (elemento === 'campos') {
      const orientacion = this.configuracion.estilo?.orientacion;
      switch (orientacion) {
        case OrientacionFormulario.VERTICAL:
          clases.push('vertical');
          break;
        case OrientacionFormulario.HORIZONTAL:
          clases.push('horizontal');
          break;
        default:
          clases.push('horizontal');
      }
      const display = this.configuracion.estilo?.display;
      switch (display) {
        case DisplayFormulario.FLEX:
          clases.push('flex');
          break;
        case DisplayFormulario.GRID:
          clases.push('grid');
          break;
        default:
          clases.push('flex');
      }
    } else if (elemento === 'campo') {
      const posicionLabel = this.configuracion.estilo?.posicionLabel;
      switch (posicionLabel) {
        case PosicionLabelFormulario.TOP:
          clases.push('label-top');
          break;
        case PosicionLabelFormulario.IZQUIERDA:
          clases.push('label-izquierda');
          break;
      }
    }

    return clases;
  }

  /**
   * Devuelve el mensaje de error del control proveído
   * @param {FormControl} control - El control a extraer el error
   * @returns {string} - El mensaje de error del control
   * @author Juan Corral
   */
  protected obtenerErrorControl(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'Campo requerido';
    } else if (control.hasError('min')) {
      return 'Valor mínimo: ' + control.getError('min').min;
    } else if (control.hasError('max')) {
      return 'Valor máximo: ' + control.getError('max').max;
    } else if (control.errors && Object.keys(control.errors).length > 0) {
      // TODO: Terminar esto
      return Object.entries(control.errors)[0][0];
    } else {
      return '';
    }
  }

  /**
   * Pone el marcador en el mapa del campo proveído
   * @param {CampoFormulario} campo - El campo con las coordenadas
   * @param {google.maps.LatLngLiteral} coordenadas - Las coordenadas del marcador
   * @author Juan Corral
   */
  protected ponerMarcadorMapa(
    campo: CampoFormulario,
    coordenadas: google.maps.LatLngLiteral,
  ): void {
    if (campo.readonly === true) return;
    const idxCampo = this.configuracion.campos.findIndex(
      (c) => c.slug === campo.slug,
    );
    if (idxCampo === -1) return;
    const control = this.campos.at(idxCampo) as FormControl;
    const mapa = this._mapas.find((m) => m.mapId === campo.slug);
    if (!mapa) return;
    const valor = coordenadas
      ? { latitud: coordenadas.lat, longitud: coordenadas.lng }
      : null;
    control.setValue(valor);
    mapa.panTo(coordenadas);
    this.cdr.markForCheck();
  }
}
