import { ConfiguracionFicha } from 'src/app/compartido/ficha/utilidades/ficha.models';
import {
  ConfiguracionFormulario,
  DisplayFormulario,
  PosicionLabelFormulario,
  TipoCampoFormulario,
} from 'src/app/compartido/formulario/utilidades/formulario.models';
import {
  ConfiguracionTablaAsincrona,
  TipoColumnaTabla,
} from 'src/app/compartido/tabla/utilidades/tabla.models';
import { Icono } from 'src/app/compartido/utilidades/compartido.static';
import { formatearFecha } from 'src/app/compartido/utilidades/valores.utils';

/* Configuración de la ficha de aprobar órdenes */
export const CONFIGURACION_FICHA_APROBAR_ORDENES: ConfiguracionFicha = {
  header: { titulo: 'Aprobar Órdenes', icono: Icono.APROBACION },
  maxWidth: 800,
};

/* Configuración del formulario de aprobar órdenes */
export const CONFIGURACION_FORMULARIO_APROBAR_ORDENES: ConfiguracionFormulario =
  {
    campos: [
      {
        nombre: 'Organización',
        slug: 'organizacion',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        requerido: true,
        opciones: [], // opciones se define dinámicamente en el componente
      },
      {
        nombre: 'Fecha planeada',
        slug: 'fecha',
        tipo: TipoCampoFormulario.FECHA,
        requerido: true,
        minimo: formatearFecha(Date.now()),
      },
      {
        nombre: 'Farmacia',
        slug: 'farmacia',
        tipo: TipoCampoFormulario.OPCION_MULTIPLE,
        requerido: false,
        opciones: [], // opciones se define dinámicamente en el componente
        oculto: true,
      },
    ],
    estilo: {
      display: DisplayFormulario.GRID,
      posicionLabel: PosicionLabelFormulario.TOP,
      widthCampo: 250,
      widthLabel: 250,
      esconderErrores: true,
    },
  };

/* Configuración de la tabla de aprobar órdenes */
export const CONFIGURACION_TABLA_APROBAR_ORDENES: ConfiguracionTablaAsincrona =
  {
    columnas: [
      { slug: 'id', nombre: 'ID', ordenable: true },
      { slug: 'referencia', nombre: 'N° Referencia' },
      { slug: 'cliente', nombre: 'Cliente' },
      // { slug: 'items', nombre: 'Items', tipo: TipoColumnaTabla.NUMERO },
      { slug: 'formulas', nombre: 'Fórmulas', tipo: TipoColumnaTabla.NUMERO },
    ],
    filtros: undefined,
    botones: undefined,
    acciones: [
      {
        nombre: 'Aprobar',
        slug: 'aprobar',
        icono: Icono.APROBACION,
      },
    ],
  };
