<div
  class="top"
  *ngIf="configFormularioFiltros.campos.length > 0 || configuracion.botones"
>
  <!-- Filtros -->
  <div class="filtros">
    <compartido-formulario
      *ngIf="configFormularioFiltros"
      [configuracion]="configFormularioFiltros"
      (valores)="aplicarFiltros($event)"
    ></compartido-formulario>
  </div>

  <!-- Botones -->
  <div class="botones" *ngIf="configuracion.botones">
    <ng-container *ngFor="let boton of configuracion.botones">
      <ng-container
        *permisos="
          boton.autorizacion?.permisos ?? null;
          objeto: boton.autorizacion?.objeto ?? null
        "
      >
        <button
          *ngIf="boton.primario"
          class="boton"
          mat-flat-button
          (click)="clickBoton.next(boton.slug)"
          color="primary"
        >
          {{ boton.nombre }} <mat-icon>{{ boton.icono }}</mat-icon>
        </button>
        <button
          *ngIf="!boton.primario"
          class="boton"
          mat-icon-button
          (click)="clickBoton.next(boton.slug)"
          color="primary"
          [matTooltip]="boton.nombre"
        >
          <mat-icon>{{ boton.icono }}</mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<mat-table [dataSource]="dataSource" matSort>
  <!-- Columna Selección -->
  <ng-container *ngIf="configuracion.seleccion" matColumnDef="seleccion">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="toggleSeleccionCompleta()"
        [checked]="seleccion.selected.length === dataSource.data.length"
        [indeterminate]="
          seleccion.selected.length > 0 &&
          seleccion.selected.length < dataSource.data.length
        "
      >
      </mat-checkbox>
      <span class="mat-small seleccionados">
        {{ seleccion.selected.length }} / {{ dataSource.data.length }}
      </span>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? seleccion.toggle(row) : null"
        [checked]="seleccion.isSelected(row)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- Columna Acciones -->
  <ng-container *ngIf="configuracion.acciones" matColumnDef="acciones">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngFor="let accion of configuracion.acciones">
        <!-- TODO: accion.ocultar() se llama muchísimas veces -->
        <ng-container
          *ngIf="accion.ocultar === undefined || !accion.ocultar(row)"
        >
          <button
            *permisos="
              accion.autorizacion?.permisos ?? null;
              objeto: accion.autorizacion?.objeto ?? null
            "
            color="primary"
            mat-icon-button
            class="accion"
            (click)="clickAccion.next({ fila: row, accion: accion.slug })"
            [matTooltip]="accion.nombre"
          >
            <mat-icon>{{ accion.icono }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Columnas -->
  <ng-container
    *ngFor="let columna of configuracion.columnas"
    [matColumnDef]="columna.slug"
  >
    <ng-container *ngIf="columna.ordenable">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ columna.nombre }}
      </mat-header-cell>
    </ng-container>
    <ng-container *ngIf="!columna.ordenable">
      <mat-header-cell *matHeaderCellDef>
        {{ columna.nombre }}
      </mat-header-cell>
    </ng-container>
    <ng-container [ngSwitch]="columna.tipo">
      <!-- Tipo: Fecha -->
      <ng-container *ngSwitchCase="TipoColumnaTabla.FECHA">
        <mat-cell *matCellDef="let fila" (click)="clickFila.next(fila)">
          {{ fila[columna.slug] | date: "YYYY-MM-dd" }}
        </mat-cell>
      </ng-container>
      <!-- Tipo: Booleano -->
      <ng-container *ngSwitchCase="TipoColumnaTabla.BOOLEANO">
        <mat-cell *matCellDef="let fila" (click)="clickFila.next(fila)">
          <mat-icon *ngIf="fila[columna.slug]">check</mat-icon>
          <mat-icon *ngIf="!fila[columna.slug]">close</mat-icon>
        </mat-cell>
      </ng-container>
      <!-- Tipo: Número -->
      <ng-container *ngSwitchCase="TipoColumnaTabla.NUMERO">
        <mat-cell *matCellDef="let fila" (click)="clickFila.next(fila)">
          {{ fila[columna.slug] | number }}
        </mat-cell>
      </ng-container>
      <!-- Tipo: Moneda -->
      <ng-container *ngSwitchCase="TipoColumnaTabla.MONEDA">
        <mat-cell *matCellDef="let fila" (click)="clickFila.next(fila)">
          {{ fila[columna.slug] | currency: "COP" : "symbol-narrow" : "1.0-0" }}
        </mat-cell>
      </ng-container>
      <!-- Tipo: Texto -->
      <ng-container *ngSwitchDefault>
        <mat-cell *matCellDef="let fila" (click)="clickFila.next(fila)">
          {{ fila[columna.slug] ?? "-" }}
        </mat-cell>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Rows -->
  <mat-header-row mat-header-row *matHeaderRowDef="columnas"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: columnas"></mat-row>

  <!-- No Data -->
  <div *matNoDataRow class="sin-datos mat-small">
    <span *ngIf="!cargando">No hay datos que coincidan con la búsqueda</span>
    <mat-spinner *ngIf="cargando" class="cargando" diameter="50"></mat-spinner>
  </div>
</mat-table>

<!-- Paginator -->
<mat-paginator
  [length]="dataSource.data.length"
  [pageSize]="5"
  [pageSizeOptions]="[5, 10, 20, 50]"
></mat-paginator>
