import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilaTabla } from 'src/app/compartido/tabla/utilidades/tabla.models';
import { DomiciliosService } from '../../utilidades/domicilios.service';
import {
  CONFIGURACION_TABLA_DOMICILIOS,
  DETALLES_ACCIONES_TABLA_DOMICILIOS,
} from './utilidades/tabla-domicilios.static';
import { TipoCampoFormulario } from 'src/app/compartido/formulario/utilidades/formulario.models';
import { deepCopy } from 'src/app/compartido/utilidades/objetos.utils';
import { TablaAsincronaComponent } from 'src/app/compartido/tabla-asincrona/tabla-asincrona.component';
import { AlertasService } from 'src/app/nucleo/utilidades/alertas.service';
import {
  ErrorDesarrollo,
  ErrorFrontend,
} from 'src/app/compartido/utilidades/error.utils';
import { SourceTablaDomicilios } from './utilidades/source-tabla-domicilios.class';
import { ResponsiveService } from 'src/app/nucleo/utilidades/responsive.service';
import { OrganizacionService } from 'src/app/nucleo/utilidades/organizacion.service';
import { obtenerFiltro } from 'src/app/compartido/tabla/utilidades/tabla.utils.models';
import { DialogosService } from 'src/app/nucleo/utilidades/dialogos.service';
import {
  MAX_DOMICILIOS_DESCARGA,
  MAX_ETIQUETAS_DESCARGA,
} from '../../utilidades/domiclios.static';
import { ConfiguracionDialogoVistaPrevia } from 'src/app/compartido/dialogos/dialogo-vista-previa/utilidades/dialogo-vista-previa.static';

@Component({
  templateUrl: './tabla-domicilios.component.html',
  styleUrls: ['./tabla-domicilios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablaDomiciliosComponent implements OnInit {
  constructor(
    private readonly _domiciliosService: DomiciliosService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _alertasService: AlertasService,
    private readonly _dialogosService: DialogosService,
    private readonly _organizacionService: OrganizacionService,
    protected readonly responsiveService: ResponsiveService,
  ) {
    this.dataSource = new SourceTablaDomicilios(_domiciliosService);
  }

  /* Configuración de la tabla */
  protected configTabla = deepCopy(CONFIGURACION_TABLA_DOMICILIOS);

  /* Fuente de datos de la tabla */
  protected dataSource: SourceTablaDomicilios;

  /* Referencia a la tabla */
  @ViewChild('tabla') tabla!: TablaAsincronaComponent;

  ngOnInit(): void {
    // Poblar opciones de vehículo
    const campoVehiculo = obtenerFiltro(this.configTabla, 'vehiculo')!;
    if (campoVehiculo.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo vehículo debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService.obtenerVehiculos$().then((vehiculos) => {
      campoVehiculo.opciones = [
        { nombre: 'Todos', valor: '' },
        ...vehiculos.map((vehiculo) => ({
          nombre: vehiculo.nombre,
          valor: vehiculo.nombre,
        })),
      ];
      this._cdr.markForCheck();
    });

    // Poblar opciones de organización
    const campoOrg = obtenerFiltro(this.configTabla, 'organizacion')!;
    if (campoOrg.tipo !== TipoCampoFormulario.OPCION_MULTIPLE)
      throw new ErrorDesarrollo(
        'El campo organización debe ser de tipo OPCION_MULTIPLE',
      );
    this._organizacionService
      .obtenerOrganizaciones$()
      .then((organizaciones) => {
        campoOrg.opciones = [
          { nombre: 'Todos', valor: '' },
          ...organizaciones.map((org) => ({
            nombre: org.nombre,
            valor: org.nombre,
          })),
        ];
        this._cdr.markForCheck();
      });
  }

  /**
   * Ejecuta una acción en la fila.
   * @param {FilaTabla} fila - La fila seleccionada.
   * @param {string} accion - La acción a ejecutar.
   * @autor Juan Corral
   */
  protected clickAccion(fila: FilaTabla, accion: string): void {
    if (accion === 'etiqueta') {
      this._descargarEtiquetas(fila['id']);
      return;
    }

    const detalles = DETALLES_ACCIONES_TABLA_DOMICILIOS[accion];
    if (detalles === undefined)
      throw new ErrorDesarrollo('Acción no soportada');
    const config: ConfiguracionDialogoVistaPrevia = {
      componente: detalles.componente,
      inputs: { idDomicilio: fila['id'] },
      output: detalles.output,
      url: this._router.serializeUrl(
        this._router.createUrlTree([...detalles.segmentosUrl, fila['id']]),
      ),
      botonesSuperpuestos: true,
      botonCerrar: true,
    };
    this._dialogosService
      .abrirVistaPrevia$(config, {
        maxWidth: '90vw',
        width: detalles.width + 'px',
      })
      .then(() => this.tabla.cargarPagina());
  }

  /**
   * Realiza la acción de un botón.
   * @param {string} boton - El botón seleccionado.
   * @autor Juan Corral
   */
  protected clickBoton(boton: string): void {
    if (boton === 'descargar') this._descargarDomicilios();
    else if (boton === 'etiquetas') this._descargarEtiquetas();
    else throw new ErrorDesarrollo('Botón no soportado');
  }

  /**
   * Descarga los domicilios en un archivo CSV.
   * @author Juan Corral
   */
  private _descargarDomicilios(): void {
    const cantidad = this.dataSource.total;
    const s = cantidad > 1 ? 's' : '';
    const los = cantidad > 1 ? 'los' : 'el';

    if (cantidad === 0) {
      this._alertasService.alertarError('No hay domicilios para descargar');
      return;
    } else if (cantidad > MAX_DOMICILIOS_DESCARGA) {
      this._alertasService.alertarError(
        `No puedes descargar más de ${MAX_DOMICILIOS_DESCARGA} domicilios a la vez`,
      );
      return;
    }

    this._dialogosService
      .abrirDialogo$(
        `¿Descargar ${cantidad} domicilio${s}?`,
        `Enviaremos un archivo CSV con ${los} domicilio${s} al correo registrado en tu perfil.`,
        'Confirmar',
        'Cancelar',
      )
      .then((confirmado: boolean) => {
        if (!confirmado) return;

        this._alertasService.alertar(
          `Enviando ${los} domicilio${s}, esto puede tardar unos segundos...`,
        );
        this._domiciliosService
          .descargarDomicilios$(this.tabla.valoresFiltros)
          .then(() =>
            this._alertasService.alertarExito(
              'El archivo ha sido enviado a tu correo',
            ),
          )
          .catch((error: ErrorFrontend) =>
            this._alertasService.alertarError('Error: ' + error.message),
          );
      });
  }

  /**
   * Descarga las etiquetas de los domicilios en un archivo PDF.
   * @param {number} id [Opcional] - El id del domicilio. Si no se especifica, se aplican los filtros de la tabla.
   * @author Juan Corral
   */
  private _descargarEtiquetas(id?: number): void {
    const cantidad = id === undefined ? this.dataSource.total : 1;
    const s = cantidad > 1 ? 's' : '';

    if (cantidad === 0) {
      this._alertasService.alertarError('No hay etiquetas para descargar');
      return;
    } else if (cantidad > MAX_ETIQUETAS_DESCARGA) {
      this._alertasService.alertarError(
        `No puedes descargar más de ${MAX_ETIQUETAS_DESCARGA} etiquetas a la vez`,
      );
      return;
    }

    this._dialogosService
      .abrirDialogo$(
        `¿Descargar ${cantidad} etiqueta${s}?`,
        `Enviaremos un archivo PDF con la${s} etiqueta${s} al correo registrado en tu perfil.`,
        'Confirmar',
        'Cancelar',
      )
      .then((confirmado: boolean) => {
        if (!confirmado) return;

        this._alertasService.alertar(
          `Enviando la${s} etiqueta${s}, esto puede tardar unos segundos...`,
        );
        const filtros =
          id === undefined ? this.tabla.valoresFiltros : { ids: [id] };
        this._domiciliosService
          .descargarEtiquetas$(filtros)
          .then(() =>
            this._alertasService.alertarExito(
              'El archivo ha sido enviado a tu correo',
            ),
          )
          .catch((error: ErrorFrontend) =>
            this._alertasService.alertarError('Error: ' + error.message),
          );
      });
  }
}
