import { routes as domiciliosRoutes } from '../../domicilios/domicilios-routing.module';
import { routes as paquetesRoutes } from '../../paquetes/paquetes-routing.module';
import { routes as clientesRoutes } from '../../clientes/clientes-routing.module';
import { routes as comunicacionesRoutes } from '../../comunicaciones/comunicaciones-routing.module';
import { routes as ordenesRoutes } from '../../ordenes/ordenes-routing.module';
import {
  Permiso,
  RolUsuario,
} from 'src/app/nucleo/autorizacion/autorizacion.models';
import {
  Icono,
  Imagenes,
} from 'src/app/compartido/utilidades/compartido.static';

/* Pagina de la navegación */
export type PaginaNavegacion = {
  nombre: string;
  url: string;
  icono: Icono;
  imagen: string;
  objeto: string;
  permisos: Permiso[];
  roles?: RolUsuario[];
};

/* Navegación de la plataforma */
// TODO: Cambiar indexing de routes para que no dependa del orden
export const NAVEGACION: PaginaNavegacion[] = [
  // Órdenes
  {
    nombre: 'Crear Orden',
    url: '/ordenes/crear',
    icono: Icono.CREAR_ORDEN,
    imagen: Imagenes.CREAR_ORDEN,
    objeto: ordenesRoutes[2].data!['objeto'],
    permisos: ordenesRoutes[2].data!['permisos'],
    roles: [RolUsuario.CLIENTE],
  },
  {
    nombre: 'Órdenes',
    url: '/ordenes',
    icono: Icono.ORDENES,
    imagen: Imagenes.ORDENES,
    objeto: ordenesRoutes[0].data!['objeto'],
    permisos: ordenesRoutes[0].data!['permisos'],
  },
  {
    nombre: 'Aprobación',
    url: '/ordenes/aprobar',
    icono: Icono.APROBACION,
    imagen: Imagenes.APROBACION,
    objeto: ordenesRoutes[3].data!['objeto'],
    permisos: ordenesRoutes[3].data!['permisos'],
  },
  {
    nombre: 'Preparación',
    url: '/ordenes/preparar',
    icono: Icono.PREPARACION,
    imagen: Imagenes.PREPARACION,
    objeto: ordenesRoutes[5].data!['objeto'],
    permisos: ordenesRoutes[5].data!['permisos'],
  },

  // Domicilios
  {
    nombre: 'Domicilios',
    url: '/domicilios',
    icono: Icono.DOMICILIOS,
    imagen: Imagenes.DOMICILIOS,
    objeto: domiciliosRoutes[0].data!['objeto'],
    permisos: domiciliosRoutes[0].data!['permisos'],
  },
  {
    nombre: 'Despacho',
    url: '/domicilios/despachar',
    icono: Icono.DESPACHO,
    imagen: Imagenes.DESPACHO,
    objeto: domiciliosRoutes[2].data!['objeto'],
    permisos: domiciliosRoutes[2].data!['permisos'],
  },
  {
    nombre: 'Re-programar',
    url: '/domicilios/reprogramar',
    icono: Icono.REPROGRAMAR,
    imagen: Imagenes.REPROGRAMAR,
    objeto: domiciliosRoutes[4].data!['objeto'],
    permisos: domiciliosRoutes[4].data!['permisos'],
  },

  // Paquetes
  {
    nombre: 'Paquetes',
    url: '/paquetes',
    icono: Icono.PAQUETES,
    imagen: Imagenes.PAQUETES,
    objeto: paquetesRoutes[0].data!['objeto'],
    permisos: paquetesRoutes[0].data!['permisos'],
  },

  // Comunicaciones
  {
    nombre: 'Mensajes SMS',
    url: '/comunicaciones/sms',
    icono: Icono.SMS,
    imagen: Imagenes.SMS,
    objeto: comunicacionesRoutes[0].data!['objeto'],
    permisos: comunicacionesRoutes[0].data!['permisos'],
  },

  // Clientes
  {
    nombre: 'Clientes',
    url: '/clientes',
    icono: Icono.CLIENTES,
    imagen: Imagenes.CLIENTES,
    objeto: clientesRoutes[0].data!['objeto'],
    permisos: clientesRoutes[0].data!['permisos'],
  },
  {
    nombre: 'Verificaciones de Identidad',
    url: '/clientes/verificaciones',
    icono: Icono.VERIFICACIONES,
    imagen: Imagenes.VERIFICACIONES,
    objeto: clientesRoutes[1].data!['objeto'],
    permisos: clientesRoutes[1].data!['permisos'],
  },
  {
    nombre: 'Mi Perfil',
    url: '/clientes/perfil',
    icono: Icono.PERFIL,
    imagen: Imagenes.PERFIL,
    objeto: clientesRoutes[3].data!['objeto'],
    permisos: clientesRoutes[3].data!['permisos'],
    roles: [RolUsuario.CLIENTE],
  },
];
