import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject } from 'rxjs';
import { DatosTabla, FilaTabla } from '../../tabla/utilidades/tabla.models';
import {
  DatosTablaAsincrona,
  FormateableTabla,
} from './tabla-asincrona.models';
import { ListaDTO, Parametros } from '../../utilidades/compartido.models';

/* Data source para una tabla asíncrona */
export abstract class SourceTablaAsincrona<T extends FormateableTabla>
  implements DataSource<FilaTabla>
{
  /* Datos */
  private _datosSubject = new BehaviorSubject<DatosTabla>([]);
  public datos$ = this._datosSubject.asObservable();

  /* Estado de carga */
  private _cargandoSubject = new BehaviorSubject<boolean>(false);
  public cargando$ = this._cargandoSubject.asObservable();

  /* Datos totales */
  public total: number = 0;

  /**
   * Constructor
   * @param _convertidor - Función que convierte los datos del API en objetos de la clase T.
   */
  constructor(private _convertidor: (datos: any) => T) {}

  /**
   * Devuelve un observable que contiene los datos de la tabla
   * @param {CollectionViewer} collectionViewer - El viewer para los datos
   * @returns {Observable<DatosTabla>} - Observable que contiene los datos de la tabla
   * @author Juan Corral
   */
  public connect(collectionViewer: CollectionViewer): Observable<DatosTabla> {
    return this._datosSubject.asObservable();
  }

  /**
   * Desactiva el observable que contenía los datos de la tabla
   * @param {CollectionViewer} collectionViewer - El viewer para los datos
   * @author Juan Corral
   */
  public disconnect(collectionViewer: CollectionViewer): void {
    this._datosSubject.complete();
    this._cargandoSubject.complete();
  }

  /**
   * Formatea los datos proveídos por el API
   * @param {ListaDTO<any>} respuesta - La respuesta como llego desde el API
   * @returns {DatosTablaAsincrona} - Los datos formateados para la tabla
   * @author Juan Corral
   */
  protected _formatearDatosTabla(
    respuesta: ListaDTO<any>,
  ): DatosTablaAsincrona {
    const total = respuesta.count;
    const datos: DatosTabla = [];
    const objetos: T[] = [];
    for (const objeto of respuesta.results) {
      const procesado = this._convertidor(objeto);
      datos.push(procesado.datosTabla());
      objetos.push(procesado);
    }
    const ret: DatosTablaAsincrona = {
      datos: datos,
      total: total,
      objetos: objetos,
    };
    return ret;
  }

  /**
   * Le pide al data service que obtenga los datos con las especificaciones proveídas
   * y los guarda en el data source.
   * @param {string} orden - Columna por la que se ordenaran los resultados.
   * @param {number} inicio - Index del record por el cual empezar.
   * @param {number} limite - Número de records a devolver.
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @author Juan Corral
   */
  public obtenerDatos(
    orden: string = '',
    inicio: number = 0,
    limite: number = 15,
    filtros?: Parametros,
  ): void {
    this._cargandoSubject.next(true);

    this._obtenerDatos$(orden, inicio, limite, filtros)
      .catch(() => ({ datos: [], total: 0, objetos: [] }))
      .then((datos: DatosTablaAsincrona) => {
        this._cargandoSubject.next(false);
        this._datosSubject.next(datos.datos);
        this.total = datos.total;
      });
  }

  /**
   * Obtiene los datos de la tabla
   * @param {string} orden - Columna por la que se ordenaran los resultados
   * @param {number} inicio - Index del record por el cual empezar
   * @param {number} limite - Número de records a devolver
   * @param {Parametros} filtros [Opcional] - Los filtros a aplicar.
   * @returns {Promise<DatosTablaAsincrona>} - Los datos de la tabla
   * @author Juan Corral
   */
  protected abstract _obtenerDatos$(
    orden: string,
    inicio: number,
    limite: number,
    filtros?: Parametros,
  ): Promise<DatosTablaAsincrona>;
}
